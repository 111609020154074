import React from 'react';
import icons from "../../../../../public/assets/icons/messageModal";

const renderIcon = (iconName) => {
  const iconPath = icons[iconName];
  return <img src={iconPath} alt={iconName} className="popover_field_icon" />;
};

const IconGrid = ({ handleSetSelectedIcon, closePopover, setIsButtonDisabled }) => {
  const iconNames = Object.keys(icons);

  return (
    <div className="icon-grid">
      {iconNames.map((iconName, index) => (
        <div key={index} onClick={() => { handleSetSelectedIcon(iconName); closePopover(false);  }} className="icon-item">
        {renderIcon(iconName)}
        </div>
      ))}
    </div>
  );
};

export default IconGrid;

