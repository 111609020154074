// NewUserFromAuth0.js

import React, { useState, useEffect } from 'react';
import {   Modal,TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Avatar,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth0 } from '@auth0/auth0-react';
import addUser from 'src/public/assets/addUser.png';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'



// Modal for creating an initial user from new Auth0 user signup
const NewUserFromAuth0 = ({ isOpen, onClose, onSave, auth0User, locationData }) => {
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  const [isFormValid, setIsFormValid] = useState(false);
  const [pinError, setPinError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Check if all fields are filled and valid
    setIsFormValid(
      newFirstName.trim() !== '' &&
        newLastName.trim() !== '' &&
        newUsername.trim() !== '' &&
        newPin.trim() !== '' &&
        confirmNewPin.trim() !== '' &&
        newPin === confirmNewPin &&
        /^[a-zA-Z]+$/.test(newFirstName) &&
        /^[a-zA-Z]+$/.test(newLastName) &&
        /^\d{4,6}$/.test(newPin)
    );

    // Check if PINs match
    setPinError(confirmNewPin !== '' && newPin !== confirmNewPin ? 'Pins must match' : '');
  }, [newFirstName, newLastName, newUsername, newPin, confirmNewPin]);

  

  const theme = createTheme({
    palette: {
      primary: {
        main: '#48768C', // Set the main color to white
      },
    },
  });

   // Modified handleNewUserFromAuth0 function to call the createNotificationsForNewUser function
   const handleNewUserFromAuth0 = async () => {
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        // Create a new user with the provided username and PIN
        const response = await fetch(`${apiUrl}/api/users/multi-location`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
          },
          body: JSON.stringify({
            firstName: newFirstName,
            lastName: newLastName,
            userName: newUsername,
            pin: newPin,
            auth0UserId: user.sub,
            title: 'Account Administrator',
            //activeLocation: locationData.id, // Use the locationData id
            //is_admin: true, // Include the is_admin property set to true
          }),
        });
  
        const responseData = await response.json();
        if (response.ok) {
          onSave(true); // Trigger the onSave function with success flag
          onClose(); // Close the modal
  
          // Call the function to create notifications for the new user
          await NewUserNotification(user.sub, getAccessTokenSilently); // Pass the user.sub as userId and use await
        } else {
          console.error('Failed to create a new user:', responseData.error);
        }
      } else {
        console.error('Entered PINs do not match');
      }
    } catch (error) {
      console.error('Error creating a new user:', error);
    }
  };
  

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'28%',
padding:3,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '60px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

<Typography
          sx={{
            mt:1,
            fontWeight: 600,
            fontSize: 21,
            textAlign: 'center',
            mb: 1,
          }}
        >
          Create Your Account Administrator
        </Typography>
   

        <img src={addUser} alt="Welcome" style={{ display: 'block', margin: 'auto', marginBottom: '20px', maxWidth: '55%', maxHeight:'55%' }} />


        <Typography
          sx={{
            fontSize: 14,
            textAlign: 'center',
            mb: 4,
            fontWeight: 600,
          }}
        >
          An account administrator can make important changes to your account, such as adding or removing users, and managing locations
        </Typography>
       
       
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', mt:1 }}>


        <ThemeProvider theme={theme}>

        <TextField
          label="First Name"
          variant="outlined"
          size="small"

          fullWidth
          value={newFirstName}
          onChange={(e) => setNewFirstName(e.target.value)}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C', fontSize:14 } }}
          sx={{ mb: 2, mr:2.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />
                </ThemeProvider> 
                <ThemeProvider theme={theme}>


        <TextField
          label="Last Name"
          variant="outlined"
          size="small"

          fullWidth
          value={newLastName}
          onChange={(e) => setNewLastName(e.target.value)}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C',borderRadius:'8px', fontSize:14 } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />      
        </ThemeProvider>
        </Box>               

   
                <ThemeProvider theme={theme}>

        <TextField
          label="Username"
          variant="outlined"
          size="small"

          fullWidth
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px', fontSize:14 } }}
          InputLabelProps={{ style: { color: '#48768C',fontSize:14 } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />                        </ThemeProvider>

<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', }}>


                <ThemeProvider theme={theme}>

        <TextField
          label="New PIN"
          size="small"

          variant="outlined"
          type="password"
          fullWidth
          value={newPin}
          onChange={(e) => setNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C', fontSize:14 } }}
          sx={{ mb: 2, mr: 2.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
        />                      </ThemeProvider>

                <ThemeProvider theme={theme}>

         <TextField
          label="Confirm New PIN"
          size="small"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmNewPin}
          onChange={(e) => setConfirmNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C', fontSize:14,  } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
          error={!!pinError}
          helperText={pinError}
        
        />                     </ThemeProvider>
        </Box>
        <Box sx={{ marginTop: 1.5, display: 'flex',  width:'100%',justifyContent: 'center',mb:2}}>

          <Button 
                                disabled={!isFormValid}

          onClick={handleNewUserFromAuth0} variant="contained" 
          sx={{
            justifyContent: 'center',
            backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
            padding: 1,
            borderRadius: '15px',
            mb:1,
            width: '400px',
            fontSize: 17,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
            },
            '&:active': {
              backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
            },
          }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewUserFromAuth0;
