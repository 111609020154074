import React, { useEffect, useState } from 'react';
import { Modal, Typography, Button, Box,Fade } from '@mui/material';
import complete from 'src/public/assets/complete.png';
import { useSingleUser } from 'src/context/SingleUserContext';
import { useAccountContext } from 'src/context/AccountContext';
import { useAuth } from 'src/context/AuthContext';

const NewAccountSuccessSingleUser = ({ onClose, onNext }) => {
  const { accountData, isLoading } = useAccountContext();
  const { fetchLatestSingleUserData } = useSingleUser();
  const { username } = useAuth();

  useEffect(() => {
    // Function to clear local storage keys
    const clearLocalStorage = () => {
      localStorage.removeItem('subUserData');
      // Add other keys you want to clear here
    };

    // Call clearLocalStorage before executing other logic
    clearLocalStorage();

    if (accountData && !isLoading) {
      if (accountData.single_user && !username) {
        fetchLatestSingleUserData();
      } else {
        // Handle other cases
      }
    }
  }, [accountData, isLoading, fetchLatestSingleUserData, username]);

  useEffect(() => {
    // Clear all items from local storage when component mounts
    localStorage.clear();
  }, []);


  const handleFinishButtonClick = () => {
    onNext();
    window.location.reload();
  };

  return (
    <Modal
      open={true}
      slotProps={{
        backdrop: {
          onClick: null,
        },
      }}
      disableEscapeKeyDown
    >

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

<Typography
          sx={{
            mt:3,
            fontSize: 21,
            textAlign: 'center',
            mb: 1,
            fontWeight: 600,
          }}
        >
          You're all set!
        </Typography>

        <img
          src={complete}
          alt="Welcome"
          style={{ display: 'block', margin: 'auto', marginBottom: '20px', maxWidth: '55%', maxHeight:'55%' }} />
          


          <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
            fontSize:14.5,
            fontWeight: 600,
          }}
        >
          Your account has been successfully created!
        </Typography>

        <Typography
          sx={{
            px:3,
            textAlign: 'center',
            mb: 3,
            fontSize:14,
            fontWeight: 600,
          }}
        >
          Update your locations hours or make any other changes to your account in the Account Management Section.
        </Typography>

        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
        sx={{
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          padding: 1,
          borderRadius: '15px',
          mb:3,
          mt:1  ,
          width: '350px',
          fontSize: 17,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        }}
            onClick={handleFinishButtonClick}
            variant="contained"
            color="primary"
          >
            Finish
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewAccountSuccessSingleUser;
