import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade
} from '@mui/material';
import moment from 'moment-timezone'; // Import moment-timezone
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import isUrl from 'validator/lib/isURL'; // Import the isURL function from the validator library
import companyImage from 'src/public/assets/companyImage.png';



import { getTime } from 'date-fns';


const theme = createTheme({
  palette: {
    primary: {
      main: '#48768C', // Set the main color to white
    },
  },
});

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};



const NewAccountModal = ({ open, onClose, onSave }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    company_name: '',
    company_email: '',
    company_city: '',
    company_state: '',
    company_website: '',
  });



  

  const [timeZone, setTimeZone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true); // Added state for email validation
  const [isEmailFieldDirty, setIsEmailFieldDirty] = useState(false); // Added state to track if email field is dirty
  const [isWebsiteValid, setIsWebsiteValid] = useState(true); // Added state for website validation
  const [loading, setLoading] = useState(false); // State to track loading state

  const [isWebsiteFieldDirty, setIsWebsiteFieldDirty] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  





  const handleSave = async () => {
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimeZone(browserTimeZone);
  
      const timestamp = moment().tz(browserTimeZone).format('X');
  
      const response = await fetch(`${apiUrl}/api/accounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
        },
        body: JSON.stringify({ ...formData, time_zone: browserTimeZone, timestamp, auth0_user: user.sub }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save account');
      }
  
      onSave();
      setFormData({
        company_name: '',
        company_email: '',
        company_city: '',
        company_state: '',
        company_website: '',
      });
      onClose();
    } catch (error) {
      console.error('Error saving account:', error);
    } finally {
      setLoading(false); // Set loading state to false when saving is complete
    }
  };
  



  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData({ ...formData, company_email: emailValue });
    setIsEmailValid(validateEmail(emailValue));
    setIsEmailFieldDirty(true); // Set email field as dirty when text is entered
  };

  const handleWebsiteChange = (e) => {
    const websiteValue = e.target.value;
    setFormData({ ...formData, company_website: websiteValue });
    setIsWebsiteValid(isUrl(websiteValue)); // Validate the website using isURL from validator
    setIsWebsiteFieldDirty(true); // Set website field as dirty when text is entered
  };

  useEffect(() => {
    // Check the validity of the form and update the state
    const isValid =
      formData.company_name &&
      formData.company_email &&
      formData.company_city &&
      formData.company_state &&
      formData.company_website &&
      validateEmail(formData.company_email) &&
      isUrl(formData.company_website);

    setIsFormValid(isValid);
  }, [formData]);

  


      


  // List of all US states
  const usStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];


  const handleKeyDown = (event) => {
    const firstLetter = event.key.toLowerCase();
    const optionIndex = usStates.findIndex((state) => state.toLowerCase().startsWith(firstLetter));

    if (optionIndex !== -1) {
      setFormData({ ...formData, company_state: usStates[optionIndex] });
    }
  };

  return (
<Modal
  open={open}
  onClose={onClose}
  slotProps={{
    backdrop: {
      onClick: null, // Disable closing the modal by clicking on the backdrop
    },
  }}
  disableEscapeKeyDown // Disable closing the modal by pressing the escape key
>
<Fade in={open} timeout={300}>

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
padding:2,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '60px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

        <Typography 

sx={{
  mt:3,
  fontSize: 22,
  textAlign: 'center',
  mb: 1,
  fontWeight: 600,
}}
>
          Tell Us A Little About Your Company</Typography>
  <Box>


        <img src={companyImage}
         alt="Welcome" style={{ display: 'block', margin: 'auto', maxWidth: '50%', maxHeight:'50%' }} />
        <Typography
          sx={{
            color: '#48768C',
            fontSize: 15,
            fontWeight: 600,
            textAlign: 'center',
            mb: 7,
          }}
        >
          Important stuff like your companies website, email and your locations phone number are automatically included in your notifications for your customers to see.
        </Typography>
        
        <ThemeProvider theme={theme}>

  <TextField
                label="Company Name"
                variant="outlined"
                size="small"
                value={formData.company_name}
                onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                fullWidth
                InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
                InputLabelProps={{ style: { color: '#48768C' } }}
                sx={{ mt: -4, mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}

              />
              </ThemeProvider>

          <Grid 
          container spacing={2}>

            <Grid item xs={6}>
            <ThemeProvider theme={theme}>

                    <TextField 
                label="Company City"
                variant="outlined"
                size="small"
                value={formData.company_city}
                onChange={(e) => setFormData({ ...formData, company_city: e.target.value })}
                fullWidth
                InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
                InputLabelProps={{ style: { color: '#48768C' } }}
                sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}

              />
              </ThemeProvider>

            </Grid>
            <Grid item xs={6}>
            <ThemeProvider theme={theme}>
          
            <FormControl 

            fullWidth variant="outlined"                 size="small"
            sx={{ color: '#48768C', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' } }} onKeyDown={handleKeyDown}  >
  <InputLabel

        sx={{
textAlign: 'center',
justifyContent: 'center',
mt:-.35,
          color: '#48768C',
          transform: formData.company_state ? 'translate(154px, 4px) scale(0.75)' : 'translate(14px, 12px) scale(1)',
          pointerEvents: 'none',
          position: 'absolute',
          display: formData.company_state ? 'none' : 'block', // Hide label if company_state is selected
        }}
      >
        Company State
      </InputLabel>
  <Select
placeholder="Company State"
    value={formData.company_state}
    onKeyDown={handleKeyDown}  

    onChange={(e) => setFormData({ ...formData, company_state: e.target.value })}

    sx={{ color: '#48768C',  borderRadius:'8px' }}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    }}
  >
    {usStates.map((state) => (
      <MenuItem

      sx={{backgroundColor:'#FFFFFF', color:'#48768C', borderRadius:'8px'}}
      key={state} value={state}>
        {state}
      </MenuItem>
    ))}
  </Select>
</FormControl>

</ThemeProvider>

            </Grid>
            <Grid item xs={6}>
            <ThemeProvider theme={theme}>

            <TextField
  label="Company Email"
  size="small"
  variant="outlined"
  value={formData.company_email}
  onChange={handleEmailChange}
  fullWidth
  InputProps={{
    style: { color: '#48768C', borderRadius:'8px' },
    inputProps: {
      type: 'email',
    },
  }}
  InputLabelProps={{ style: { color: '#48768C' } }}
  sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' 
}}
error={!isEmailValid} // Set error prop based on email validity
helperText={!isEmailValid ? 'Valid email is required' : ''}
/>


  
                       </ThemeProvider>

            </Grid>
        
            <Grid item xs={6}>
            <ThemeProvider theme={theme}>
          <TextField
            label="Company Website"
            variant="outlined"
            size="small"            value={formData.company_website}
            onChange={handleWebsiteChange}
            fullWidth
            InputProps={{
              style: { color: '#48768C', borderRadius:'8px' },
              inputProps: {
                type: 'email',
              },
            }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' 
          }}
            error={!isWebsiteValid && isWebsiteFieldDirty}
            helperText={!isWebsiteValid && isWebsiteFieldDirty && 'Enter a valid website URL'}
          />
        </ThemeProvider>


            </Grid>
          </Grid>
        </Box>
        <Box
        sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Button onClick={handleSave} 
          variant="contained"
          disabled={!isFormValid}
            
          
          sx={{
            backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
            padding: 1,
            borderRadius: '15px',
            mt:2,
            mb:3,
            width: '90%',
            fontSize: 17,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
            },
            '&:active': {
              backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
            },
          }}>
              
              Continue</Button>
        </Box>
      </Box>
      </Fade>
    </Modal>
  );
};

export default NewAccountModal;