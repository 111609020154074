import React, { createContext, useState, useEffect, useContext } from 'react';
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from "src/context/AppContext";

// Create the context
const ReportsContext = createContext();

// Create a provider component
export const ReportsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [notificationTotals, setNotificationTotals] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [totalsByLocation, setTotalsByLocation] = useState({});
  const [userReportsData, setUserReportsData] = useState({});
  const { selectedNotifications } = useUserSelectedNotifications();
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const {handleReloadComponents} = useAppContext(); 
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch notification totals
  useEffect(() => {
    const fetchStatusTotals = async () => {
      if (!user || !user.sub || !selectedNotifications.length) return; 
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
  
        if (Array.isArray(selectedNotifications) && selectedNotifications.length > 0 && typeof selectedNotifications[0] === 'object') {
          const notificationTypes = Object.keys(selectedNotifications[0].selected_data);
  
          const endpoint = `${apiUrl}/api/reports/monthlytotals/${user.sub}`;
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ notificationTypes }),
          });
  
          if (!response.ok) throw new Error('Failed to fetch status totals');
          
          const data = await response.json();
          const totals = {};
  
          Object.keys(data).forEach(notificationType => {
            const originalType = notificationTypes.find(type => type.toUpperCase() === notificationType.toUpperCase());
            if (originalType) {
              totals[originalType] = data[notificationType];
            }
          });
  
          setNotificationTotals(totals);
        }
      } catch (error) {
        console.error('Failed to fetch status totals:', error);
        // Set some fallback value or message to avoid undefined state
        setNotificationTotals({});
      } finally {
        setLoading(false);
      }
    };
  
    if (isAuthenticated && !isLoading) {
      fetchStatusTotals();
    }
  }, [getAccessTokenSilently, apiUrl, selectedNotifications, user, isAuthenticated, isLoading, handleReloadComponents]);


  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user || !user.sub) return; // Ensure user is defined

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();

        const endpoint = `${apiUrl}/api/users/${user.sub}`;
        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const userData = await response.json();

        setTotalUsers(userData.rowCount || 0);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchLocationData = async () => {
      if (!user || !user.sub) return; // Ensure user is defined

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const locationData = await response.json();

        setTotalLocations(locationData.rowCount || 0);
      } catch (error) {
        console.error('Failed to fetch location data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && !isLoading) {
      fetchUserData();
      fetchLocationData();
    }
  }, [getAccessTokenSilently, apiUrl, user, isAuthenticated, isLoading, handleReloadComponents]);

  // Fetch totals by location
  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.sub) return; // Ensure user is defined

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/reports/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        setTotalsByLocation(data.totalsByLocation);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && !isLoading) {
      fetchData();
    }
  }, [apiUrl, getAccessTokenSilently, user, isAuthenticated, isLoading, handleReloadComponents]);

  // Fetch user reports data
  useEffect(() => {
    const fetchUserReportsData = async () => {
      if (!user || !user.sub) return; // Ensure user is defined

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/user-reports/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        setUserReportsData(data.userReportsData);
      } catch (error) {
        console.error('Failed to fetch user reports data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && !isLoading) {
      fetchUserReportsData();
    }
  }, [apiUrl, getAccessTokenSilently, user, isAuthenticated, isLoading, handleReloadComponents]);

  return (
    <ReportsContext.Provider
      value={{
        loading,
        notificationTotals,
        totalUsers,
        totalLocations,
        totalsByLocation,
        userReportsData,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useReportsContext = () => useContext(ReportsContext);


