import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useAuth0 } from '@auth0/auth0-react';

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: blueGrey[400],
  background: `linear-gradient(to bottom right, #4dabf5, #303F9F)`,
});

export default function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
const { getAccessTokenSilently } = useAuth0();
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');


  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };


  const handleSignup = async () => {
    try {
      // Perform client-side validation before sending the request
      if (!firstName || !lastName || !email || !password || !companyName || !companyWebsite || !companyEmail || !companyPhone) {
        // Handle validation error, display a message to the user, etc.
        return;
      }
  
      // Get access token
      const accessToken = await getAccessTokenSilently();
  
      // Prepare data for the signup request
      const signupData = {
        client_id: 'Username-Password-Authentication', // Replace with your Auth0 connection name
        email,
        password,
        user_metadata: {
          firstName,
          lastName,
          companyName,
          companyWebsite,
          companyEmail,
          companyPhone,
        },
      };
  
      // Send a POST request to Auth0's signup endpoint
      const response = await fetch('https://dev-4m53d4wv8p6pmod8.us.auth0.com/dbconnections/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(signupData),
      });
  
      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        // Handle successful signup, e.g., redirect to login page, display success message, etc.
      } else {
        // Handle unsuccessful signup
        const errorData = await response.json();
        console.error('Signup failed:', errorData);
        // You can display an error message to the user based on the errorData received from Auth0
      }
    } catch (error) {
      // Handle other errors (e.g., network error)
      console.error('An error occurred during signup:', error);
    }
  };
  
  


  return (
    <>
      <Helmet>
        <title>SignUp | NanoNotify.io</title>
      </Helmet>
      <StyledRoot>
        <Grid
          container
          spacing={0}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                margin: 'auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1.5px solid #000',
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                background: '#f5f5f5',
                zIndex: 2,
              }}
            >
              {/* Left Card */}
              <img src="/assets/illustrations/logo512.png" alt="NanoNotify Logo" style={{ width: '125px', marginBottom: '16px' }} />
              <Typography variant="h5" gutterBottom>
                You've been invited!
              </Typography>
              <Typography variant="body1" gutterBottom style={{ marginBottom: '45px' }}>
                Your company has been invited to try NanoNotify. Create an account to get started.
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <Card sx={{ padding: '16px', width: '55%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginRight: '16px' }}>
                  <img src="/assets/fhlogo.png" alt="Company Logo" style={{ width: '80px', height: '80px', marginBottom: '16px' }} />
                  <div>
                    <Typography variant="body1" color="textPrimary" align="center">{companyName || "Your Company Name"}</Typography>
                    <Typography variant="body2" color="textSecondary" align="center">{companyEmail || "Your Company Email"}</Typography>
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ marginBottom: '24px' }}>{companyWebsite || "Your Company Website"}</Typography>
                    <Typography variant="body2" color="textSecondary" align="center">{companyPhone || "Your Company Phone"}</Typography>
                  </div>
                </Card>
                <Card sx={{ padding: '16px', width: '85%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: '25px' }}>
                  {/* Right Card */}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <TextField
                        required
                        id="First Name"
                        label="First Name"
                        variant="outlined"
                        sx={{ marginBottom: '16px', width: '80%' }}
                        placeholder="Your First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <TextField
                        required
                        id="Last Name"
                        label="Last Name"
                        variant="outlined"
                        sx={{ marginBottom: '16px', width: '82%', marginLeft: '18px' }}
                        placeholder="Your Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Box>
                    <TextField
                      required
                      id="Password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      sx={{ marginBottom: '16px', width: '100%' }}
                      placeholder="Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                      required
                      id="companyName"
                      label="Company Name"
                      variant="outlined"
                      sx={{ marginBottom: '16px', width: '100%' }}
                      placeholder="Your Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <TextField
                      required
                      id="companyWebsite"
                      label="Company Website"
                      variant="outlined"
                      sx={{ marginBottom: '16px', width: '100%' }}
                      placeholder="Your Company Website"
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                    />
                    <TextField
                      required
                      id="companyEmail"
                      label="Company Email"
                      variant="outlined"
                      sx={{ marginBottom: '16px', width: '100%' }}
                      placeholder="Your Company Email"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                    />
                    <TextField
                      required
                      id="companyPhone"
                      label="Company Phone"
                      variant="outlined"
                      sx={{ marginBottom: '16px', width: '100%' }}
                      placeholder="Your Company Phone"
                      value={companyPhone}
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    />

                    <Button
                      variant="contained"
                      sx={{
                        background: 'linear-gradient(45deg, #4dabf5, #1976D2)',
                        color: 'white',
                        width: '100%',
                        padding: '16px 0',
                        borderRadius: '10px',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                        transition: 'background 0.3s ease',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #1976D2, #4dabf5)',
                        },
                      }}
                      onClick={handleSignup}
                    >
                      Sign Up
                    </Button>
                  </Box>
                </Card>
              </div>
            </Card>
          </Grid>
        </Grid>
      </StyledRoot>
    </>
  );
}