import React, { useEffect, useState } from 'react';
import {
  Modal,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
  Dialog,
  DialogContent, 
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../context/AuthContext';
import CloseIcon from '@mui/icons-material/Close';
import bellIcon from "../../public/assets/icons/messageModal/bell.png";
import openSign from "../../public/assets/icons/icons/openSign.png";
import closedSign from "../../public/assets/icons/icons/closedSign.png";
import hoursIcon from "../../public/assets/icons/icons/hours.png";
import { useAccountContext } from "../../context/AccountContext"; // Import AppProvider from AccountContext
import { useAppContext } from 'src/context/AppContext'; 
import { sub } from 'date-fns';
import { useUserSelectedNotifications } from 'src/context/UserNotificationsContext';








const apiUrl = process.env.REACT_APP_API_URL;


const WhiteTimePicker = styled(TimePicker)({
  '& input': {
    color: '#48768C',fontWeight: 600,fontSize:13,
  },
  '& .MuiSvgIcon-root': {
    color: '#48768C',
  },
  '& .MuiIconButton-root': {
    color: '#48768C',
  },
  '& .MuiPickersClockNumber-clockNumberSelected': {
    backgroundColor: '#48768C',
  },
});

const SingleUserEditHoursModal = ({ isOpen, onClose, onUpdateHours, locationName }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username,subUserData } = useAuth();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to manage button disabled state
  const [isLoading, setIsLoading] = useState(false);  
  const {handleReloadComponents, showSnackBar} = useAppContext(); 
  const [loadingHours, setLoadingHours] = useState(false);
  const { fetchSingleUserHours } = useUserSelectedNotifications();
  const {businessHours, startTimeValues, endTimeValues, setBusinessHours} = useUserSelectedNotifications();







  
  const { accountData } = useAccountContext(); // Use the accountData from the context


  const OpenIconSwitch = styled(Switch)(({ theme }) => ({
    width: 59,
    height: 32,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          
          backgroundImage: `url(${closedSign})`,
          backgroundSize: 'contain', // Adjust icon size
          width: '22px', // Adjust icon width
          height: '22px', // Adjust icon height
          content: "''",
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)', // Center the background image
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#aab4be' : '#aab4be',
        },
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#647D9E' : '#647D9E',
        '& .MuiSwitch-thumb:before': {},
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#236381 ' : '#236381',
      width: 28,
      height: 28,
      '&::before': {
        backgroundImage: `url(${openSign})`,
          backgroundSize: 'contain', // Adjust icon size
          width: '24px', // Adjust icon width
          height: '24px', // Adjust icon height
          content: "''",
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)', // Center the background image
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#AAC5E7' : '#AAC5E7',
      borderRadius: 20 / 2,
    },
  }));






  const handleTimeChange = (dayOfWeek, field, value) => {
    setBusinessHours((prev) => ({
      ...prev,
      [dayOfWeek]: {
        ...prev[dayOfWeek],
        [field]: value,
      },
    }));
  };

  const formatTime = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':');
      return dayjs().set('hour', parseInt(hours)).set('minute', parseInt(minutes));
    }
    // If time is not available, use a default value
    return dayjs().set('hour', 0).set('minute', 0);
  };
  
  const formatTime12Hour = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':');
      const parsedTime = dayjs().set('hour', parseInt(hours)).set('minute', parseInt(minutes));
  
      return parsedTime.format('h:mm A'); // Format in 12-hour format with AM/PM
    }
    // If time is not available, use a default value
    return dayjs().set('hour', 0).set('minute', 0).format('h:mm A');
  };

  useEffect(() => {
    // Check if any of the time values are partially entered or null
    const isInvalidTime = Object.values(businessHours).some(({ start_time, end_time }) => {
      // Check if start_time or end_time is null or not in HH:mm format
      return !start_time || !end_time || !isValidTimeFormat(start_time) || !isValidTimeFormat(end_time);
    });
    setIsButtonDisabled(isInvalidTime);
  }, [businessHours]);
  
  // Function to check if the time is in HH:mm format
  const isValidTimeFormat = (time) => {
    const regex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/; // HH:mm format regex
    return regex.test(time);
  };





  useEffect(() => {
    const logEditedHours = () => {
      let hasNull = false; // Initialize a flag for null values
      Object.entries(businessHours).forEach(([day, data]) => {
        console.log(`${day}:`, data);
        // Check if either start_time or end_time is null or 'Invalid Date'
        if (!data.start_time || !data.end_time || data.start_time === 'Invalid Date' || data.end_time === 'Invalid Date') {
          hasNull = true; // Set the flag to true if any null or 'Invalid Date' value is found
        }
      });
  
      // Update the isButtonDisabled state based on whether any null or 'Invalid Date' value is found
      setIsButtonDisabled(hasNull);
    };

  
    // Call the function when the component renders and whenever businessHours changes
    logEditedHours();
  }, [businessHours]);
  
  
  
  const handleSave = async (e) => {
    e.preventDefault();
  
    if (isAuthenticated) {
      setLoadingHours(true);

      try {
        const accessToken = await getAccessTokenSilently();
  
        const saveRequests = daysOfWeek.map(async (dayOfWeek) => {
          const isClosed = businessHours[dayOfWeek]?.is_closed || false;
          const dataToSend = {
            userId: user.sub,
            businessHours: {
              dayOfWeek,
              startTime: businessHours[dayOfWeek]?.start_time,
              endTime: businessHours[dayOfWeek]?.end_time,
              isClosed,
            },
          };
  
          const response = await fetch(`${apiUrl}/api/updateSingleUserTimes`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
          });
  
          if (!response.ok) {
            setLoadingHours(false);
            showSnackBar('Failed to update hours. Please try again.', false);
            onClose();
              throw new Error('Failed to save user times');
          }
  
          return await response.json();
        });
  
        await Promise.all(saveRequests); // Wait for all requests to complete
        setIsConfirmationOpen(true);
        setLoadingHours(false);
        showSnackBar('Hours updated successfully!', true);
        await fetchSingleUserHours();
        onClose();

      } catch (error) {
        setLoadingHours(false);
        showSnackBar('Failed to update hours. Please try again.', false);
          console.error('Error saving user times:', error);
          onClose();      }
    }
  };
  
  

  const handleConfirmationClose = () => {
    // Close confirmation dialog and modal
    setIsConfirmationOpen(false);
    onClose();
  };
  
  

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  if (businessHours && businessHours.error) {
    return (
      <div>
        Error: {businessHours.error}
      </div>
    );
  }


  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      color: '#647D9E', // Unchecked thumb (circle) color
      '&.Mui-checked': {
        color: '#4C5F7D', // Checked thumb color
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#B0BFD1', // Unchecked track (background) color
        '&.Mui-checked': {
          backgroundColor: '#768AA6', // Checked track color
        },
      },
    },

  }));





    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog open={isOpen} onClose={onClose}
        PaperProps={{ sx: { borderRadius: "20px", } }}
      >
  
  <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon
          sx={{fontSize: "28px",}}
          
          />
        </IconButton>
        <DialogTitle
        sx={{
          alignItems: "center",
          height: "50px",
          backgroundColor: "#FFFFFF",
          pt: 2,
          color: "#374247",
        }}
      >
        <img
          src={hoursIcon}
          alt="bell"
          style={{
            border: '1px solid rgba(173, 216, 230, 0.7)',
            width: "auto",
            height: "30px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
            borderRadius: "6px",
          }}
        />
  
            
        </DialogTitle>
        
        <DialogContent
          sx={{
            width: '100%',
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
            zIndex: 0,
          }}
        
        >
  
  <Typography   sx={{ display:'flex',color: "#48768C", fontSize:20, mb:2,mt:.5 , fontWeight:600, alignContent:'left'}}>
          {accountData.company_name}'s Hours of Operation {" "}
          </Typography>
  
          <DialogContent
  sx={{
    ml:'-2px',  
    backgroundColor: 'rgba(191, 218, 231, 0.08)',
    border: '1px solid rgba(173, 216, 230, 0.7)',
    borderRadius: '10px',
    width: '100%',
  }}
>


  {daysOfWeek.map((dayOfWeek) => (
   
   <Box
      key={dayOfWeek}
      sx={{ display: 'flex', alignItems: 'center', width: '100%', mb:'5px', mt:'5px' }}
    >

      <Typography
        sx={{
          fontSize: 15,
          display: 'flex',
          color: '#48768C',
          flex: '0 0 125px', // Fixed width for the day label
          ml:1,
          mr:'-20px',
          fontWeight: 600, // Increased font weight for emphasis
        }}
      >
        {dayOfWeek}
      </Typography>
      {businessHours[dayOfWeek]?.is_closed  && subUserData && subUserData.is_admin  && (
      
      <Typography
      sx={{display:'flex', width: '100%', color: '#B0B0B0', fontSize:15, fontWeight:600,  justifyContent:'center',  py:1, 
        
        mr: businessHours[dayOfWeek]?.is_closed && subUserData.is_admin ? 0 : 13, // Conditional mr value
      }}
      >Closed</Typography>
      
      )} 

      { !businessHours[dayOfWeek]?.is_closed  && (
<>
      <WhiteTimePicker
  value={startTimeValues[dayOfWeek] || null} // Use null initially
  onChange={(time) => handleTimeChange(dayOfWeek, 'start_time', time && time.format('HH:mm'))}
        ampm={true}
        disabled={subUserData && !subUserData.is_admin || businessHours[dayOfWeek]?.is_closed}
        sx={{ flex: '0 0 125px', marginRight: '1rem',    mb:'3px',borderRadius: '8px', 
        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
      }} 
      slotProps={{
        popper: {
          sx: {
            

            "& .MuiList-root": {
              backgroundColor: "#FFFFFF",color:'#48768C', border: '1px solid rgba(173, 216, 230, 0.3)',

              
            },
            "& .MuiMenuItem-root": {
              "&.Mui-selected": {
                 backgroundColor: "#DBEAF0",
                 color: "#48768C",
                 border: '1px solid rgba(173, 216, 230, 0.7)',
                 borderRadius: "8px",
                 fontWeight: 600,
              },
              color: "#48768C",
              
              "&:hover": { // Add hover styles here
                backgroundColor: "#DBEAF0", // Change to the desired hover background color
                color: "#48768C", // Change to the desired hover text color
                borderRadius: "8px",
              }

            },
              "& .MuiDialogActions-root": {
                display: "none" // Hide the dialog actions container              
            },
          },
        },
      }}
    
    />    

 
      <Typography sx={{ color: '#B0B0B0', mr:2, fontWeight:600, fontSize:15 }}>-</Typography>
      
      <WhiteTimePicker
        value={endTimeValues[dayOfWeek] || null || dayjs()}
        onChange={(time) => handleTimeChange(dayOfWeek, 'end_time', time && time.format('HH:mm'))}
        ampm={true}
        disabled={subUserData && !subUserData.is_admin || businessHours[dayOfWeek]?.is_closed}
        sx={{ flex: '0 0 125px', borderRadius: '8px', 
        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)", }} 
      
        slotProps={{
          popper: {
            sx: {
              "& .MuiList-root": {
                backgroundColor: "#E9EDF2",color:'#48768C'
                
              },
              "& .MuiMenuItem-root": {
                "&.Mui-selected": {
                   backgroundColor: "#DBEAF0",
                   color: "#48768C",
                   
                   border: "1px solid #B0BEC5",
                   borderRadius: "8px",
                   fontWeight: 600,
                },
                color: "#48768C",
                "&:hover": { // Add hover styles here
                  backgroundColor: "#DBEAF0", // Change to the desired hover background color
                  color: "#48768C", // Change to the desired hover text color
                  borderRadius: "8px",
                }
  
              },
                "& .MuiDialogActions-root": {
                  display: "none" // Hide the dialog actions container              
              },
            },
          },
        }}
      
      />   
</>
      )}
         
<>

{subUserData && subUserData.is_admin && (

      <FormControlLabel
        control={

          <OpenIconSwitch
            checked={businessHours[dayOfWeek]?.is_closed || false}
            onChange={(e) => handleTimeChange(dayOfWeek, 'is_closed', e.target.checked)}
sx={{ml:5   }}
            
          />
        }
      />
)}
      </>

    </Box>

  ))}



</DialogContent>



{subUserData && subUserData.is_admin && (

<DialogActions
sx={{mt:1, alignItems:'center', justifyContent:'center'}}
> 


            <Button
              onClick={handleSave}
              disabled={loadingHours || isButtonDisabled } // Disable the button when loadingHours is true

              sx={{
                borderRadius: '10px',
                letterSpacing: '0.1px',
                width: '100%',
                height: '41px',
                fontSize: '18px',
                fontWeight: 600,
                backgroundColor: '#48768C',
            
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Add shadow
            
                color: 'rgba(233, 242, 255, 0.99)',
                '&:hover': {
                  backgroundColor: '#3B6B7D', // Adjust the background color for contrast
                  color: 'rgba(233, 242, 255, 0.95)', // Keep text color consistent
                },
              }}          >
              {loadingHours ? 'Loading...' : 'Save'}
              </Button>
  
          </DialogActions>
)}
          </DialogContent>
        </Dialog>


    </LocalizationProvider>
  );
};


export default SingleUserEditHoursModal;