import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,Box } from '@mui/material';

const DeleteLocationDialog = ({ open, onClose, onConfirm, itemName }) => {
   return (
    <Dialog 
    PaperProps={{ sx: { borderRadius: "10px", border: '1.5px solid rgba(173, 216, 230, 0.8)',  } }}
    
    
    open={open} onClose={onClose}>
             <Box sx={{


backgroundColor: 'rgba(191, 218, 231, 0.49)',
    border: '1px solid rgba(173, 216, 230, 0.7)', color: '#48768C',padding:'5px',borderRadius: "10px",
                                    }}>


      <DialogContent 

      >
     
     <Typography variant='body3' sx={{display:'flex', justifyContent:'center', alignSelf:'center',  
fontWeight: 600,color: '#48768C', whiteSpace:'noWrap'}} >
               Are you sure you want to delete your {itemName} location? This action cannot be undone.
               </Typography>
      </DialogContent>
      <DialogActions
     sx={{ display:'flex',justifyContent:'center', gap:3  }}
      >
        <Button onClick={onClose} 
        sx={{        color:'#48768C', borderRadius: '8px', border: '1px solid #B0BEC5', fontSize: '.9rem',
      }}
        
        
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained"
         sx={{
          fontSize: '.9rem',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease',
          width: '15%',
          backgroundColor: "#48768C",
          color: 'rgba(233, 242, 255, 0.99)',
      
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#3B6B7D",
          },
          "&:active": {
            backgroundColor: "#2E7D32", // Adjust active state color if needed
          },
        }}
        >
          Confirm
        </Button>
      </DialogActions>
      </Box>

    </Dialog>
   );
};

export default DeleteLocationDialog;
