import React, {useState, useEffect} from 'react';
import {Card, Typography, Divider, Button, Box} from '@mui/material';
import {common, blueGrey, lightBlue} from '@mui/material/colors';
import {styled} from '@mui/system';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Routes, Route, NavLink, useMatch} from 'react-router-dom';
import Profile from '../components/AccountPage/Sections/Profile';
import AccountSecurity from '../components/AccountPage/Sections/AccountSecurity';
import Notifications from '../components/AccountPage/Sections/InAppNotifications/Notifications';
import Billing from '../components/AccountPage/Sections/Billing';
import ExportData from '../components/AccountPage/Sections/ExportData';
import AccountManagement from '../components/AccountPage/Sections/AccountManagement';
import {useAuth0} from '@auth0/auth0-react';
import {useAuth} from '../context/AuthContext';
import MobileWarningPopup from '../components/modal/MobileWarningPopup';
import UserNotifications from 'src/components/AccountPage/Sections/UserNotifications/UserNotifications';
import { useAccountContext } from 'src/context/AccountContext.js';




const FullPageCard = styled(Card)({
	position: 'relative', // Add this line
	width: '100%',
	borderRadius: '10px',
	background: 'white',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	overflow: 'auto',
	padding: '2%', // Use a relative unit, such as a percentage
	
	
});

const StyledNavLink = styled(NavLink)(({theme}) => ({
	textDecoration: 'none',
}));

const Header = styled(Typography)({
	position: 'absolute', // Add this line
	top: '0', // Add this line
	left: '0', // Add this line
	width: '95%',
	textAlign: 'left',
	mb: 1,
});

const ContentContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	paddingLeft: '2%',
	width: '100%',
	height: '100%',
	flexGrow: 1,
	overflowY: 'auto', // Enable content scrolling
});

const Sidebar = styled('div')({
	flexDirection: 'column',
	alignItems: 'left',
	alignText: 'left',
	height: '95%',
	minHeight: '100%', // Add this line
	marginRight: '1%',
	
	marginTop: 80,
	width: 340,
});

const NavButton = styled(Button)(({ theme, active }) => ({
    whiteSpace: 'nowrap',
    marginBottom: '0.5rem',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.02em',
    width: '100%',
    padding: '5px 10px',
	marginRight: '90px',
    transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
    justifyContent: 'flex-start',
    background: active ? 'rgba(173, 216, 230, 0.4)' : 'transparent',
    color: active ? '#48768C' : '#48768C',
    boxShadow: active ? '0px 4px 8px rgba(72, 118, 140, 0.3)' : 'none',
	border: active ? '1.25px solid rgba(173, 216, 230, 0.5)' : 'none',
    '&:hover': {
        background: active ? 'rgba(173, 216, 230, 0.4)' : 'rgba(77, 96, 139, 0.1)',
        color: active ? '#48768C' : '#48768C',
    },
}));


function AccountPage() {
	const [showPopup, setShowPopup] = useState(false);
	const apiUrl = process.env.REACT_APP_API_URL;
	const basePath = '/dashboard/Account';
	const {user, isAuthenticated} = useAuth0();
	const [isAccountSecurityModalOpen, setIsAccountSecurityModalOpen] = useState(false);
	const isProfileActive = useMatch(`${basePath}/Profile`);
	const isAccountManagementActive = useMatch(`${basePath}/AccountManagement`);
	const isUserNotificationsActive = useMatch(`${basePath}/UserNotifications`);	
	const isNotificationsActive = useMatch(`${basePath}/Notifications`);
	const isBillingActive = useMatch(`${basePath}/Billing`);
	const isExportDataActive = useMatch(`${basePath}/Export-Data`);
	const {username, subUserData} = useAuth();
	const { accountData } = useAccountContext();




	useEffect(() => {
		// Access userData and subUserData wherever you need in your component

		// ... (rest of the code)
	}, [subUserData]);




	useEffect(() => {
		const handleResize = () => {
		  if (window.innerWidth <= 768) { // Adjust the width as needed for your definition of "mobile"
			setShowPopup(true);
		  } else {
			setShowPopup(false);
		  }
		};
	
		window.addEventListener('resize', handleResize);
		handleResize(); // Call it once on mount to check initial width
		return () => window.removeEventListener('resize', handleResize);
	  }, []);

	useEffect(() => {
		document.body.style.overflow = 'hidden'; // Disable scrollbar
		return () => {
			document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
		};
	}, []);

	return (
		<Box sx={{width: '100%', px: '4px',mt:-.5}}>
			<FullPageCard sx={{width: '100%',boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'}}>
				<Header
					sx={{
						padding: '15px',
						color: '#48768C',
						fontWeight: 700,
						fontSize: 17,
					}}>
					Account Management
				</Header>
				<Header
					sx={{padding: '15px', pt: 5, color: '#48768C', fontSize: '.9rem'}}>
					Your company's profile and settings{' '}
				</Header>

				<Sidebar>
					<StyledNavLink to={`${basePath}/Profile`}>
						<NavButton
							variant="contained"
							active={isProfileActive}>
							Profile
						</NavButton>
					</StyledNavLink>
						<NavButton
							variant="contained"
							onClick={() => setIsAccountSecurityModalOpen(true)}>
							Account Security
						</NavButton>
						<StyledNavLink to={`${basePath}/UserNotifications`}>
						<NavButton
						variant="contained"
						active={isUserNotificationsActive}>
						{subUserData.is_admin ? 'Manage Your Notifications' : 'View Your Notifications'}
						</NavButton>
					</StyledNavLink>

						<StyledNavLink to={`${basePath}/AccountManagement`}>
							<NavButton
								variant="contained"
								active={isAccountManagementActive}>
						{subUserData.is_admin ? 'Users and Locations' : 'Your Team and Locations'}
						</NavButton>
						</StyledNavLink>
					<StyledNavLink to={`${basePath}/Notifications`}>
					{subUserData.is_admin && (	
						<NavButton
							variant="contained"
							active={isNotificationsActive}>
							In App Notifications
						</NavButton>
					)}
					</StyledNavLink>
					<StyledNavLink to={`${basePath}/Billing`}>
					{subUserData.is_admin && (	

						<NavButton
							variant="contained"
							active={isBillingActive}>
							Billing
						</NavButton>
					)}
					</StyledNavLink>
					<StyledNavLink></StyledNavLink>
				</Sidebar>
				<Divider
					orientation="vertical"
					flexItem
					sx={{mt: 6, mb: 2, border: '1px solid', color: '#C0CAD1'}}
				/>
					{showPopup && <MobileWarningPopup />}

				<ContentContainer>
					<Routes>
						<Route
							path="Profile"
							element={<Profile />}
						/>
						<Route
							path="AccountManagement"
							element={<AccountManagement />}
						/>
												<Route
							path="UserNotifications"
							element={<UserNotifications />}
						/>
						<Route
							path="Notifications"
							element={<Notifications />}
						/>
						<Route
							path="Billing"
							element={<Billing />}
						/>
					</Routes>
				</ContentContainer>

				{isAccountSecurityModalOpen && (
					<AccountSecurity
						closeModal={() => setIsAccountSecurityModalOpen(false)}
					/>
				)}
			</FullPageCard>
		</Box>
	);
}

const ProtectedRoute = withAuthenticationRequired(AccountPage, {
	onRedirecting: () => <div>Redirecting you to the login page...</div>,
});

export default AccountPage;
