// A utility function for saving a customer

import React, { useEffect } from 'react';
import { useAppContext } from 'src/context/AppContext';

const SaveCustomerData = ({ phoneNumber, firstName, lastName, userSub, location_name, getAccessTokenSilently,  
}) => {
  const {handleReloadComponents} = useAppContext();

  useEffect(() => {
    const saveData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const data = {
        phoneNumber,
        firstName,
        lastName,
        userSub,
        location_name
      };

      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(`${apiUrl}/api/customers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          await handleReloadComponents();  

          return true;
        } else {
          // Handle error (e.g., display an error message)
          return false;
        }
      } catch (error) {
        console.error('Failed to save customer:', error);
        return false;
      }
    };

    saveData();
    
  }, [phoneNumber, firstName, lastName, userSub, location_name, getAccessTokenSilently,handleReloadComponents]);

  return null; // You can return null or some other JSX if needed
};

export default SaveCustomerData;
