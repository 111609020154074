import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useAppContext } from "../../context/AppContext";
import { useAuth0 } from '@auth0/auth0-react'; // Import the useAuth0 hook




const GlobalNotifications = ({ setParentIsModalOpen }) => {
  const { handleReloadComponents } = useAppContext();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0(); 
  const [loading, setLoading] = useState(false);


  const [formData, setFormData] = useState({
    title: '',
    description: '',
    tag: '', // Updated this to store the selected tag
    content: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(true);

  const categoryIcons = {
    'Bugfix': 'Bugfix',
    'Feature Update': 'Feature Update',
    'Minor Update': 'Minor Update',
    'Developer Message': 'Developer Message',
    'Version Upgrade': 'Version Upgrade', // Added the new category
    'New User' : 'New User', // Added the new category
    'Help' : 'Help' // Added the new category

  };

  const handleCreateNotification = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      if (isAuthenticated) {
        setLoading(true); // Set loading state to true when starting data fetch
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/create-global-notification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
          },
          body: JSON.stringify(formData),
        });
  
        // Handle the response as needed
        if (response.ok) {
          // Notification created successfully
          handleReloadComponents(); // Reload the components after creating a new notification
          setIsModalOpen(false);
          // You may want to fetch and update the notifications list after creating a new one
        } else {
          // Handle error
          console.error('Failed to create notification');
        }
      }
    } catch (error) {
      console.error('Error creating notification', error);
    }
  };
  

  return (
    <Dialog open={isModalOpen} onClose={() => setParentIsModalOpen(false)}>
      <DialogTitle>Create Global Notification</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="tag-select-label">Tag</InputLabel>
            <Select
              labelId="tag-select-label"
              id="tag-select"
              value={formData.tag}
              onChange={(e) => setFormData({ ...formData, tag: e.target.value })}
            >
              {Object.keys(categoryIcons).map((key) => (
                <MenuItem key={key} value={key}>
                  {categoryIcons[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
  label="Content"
  value={formData.content}
  onChange={(e) => setFormData({ ...formData, content: e.target.value })}
  fullWidth
  multiline
  rows={100} 
  margin="normal"
/>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
        <Button onClick={handleCreateNotification} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalNotifications;
