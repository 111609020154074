import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Avatar, IconButton, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import newCustomerIcon from "../../public/assets/icons/icons/newCustomer.png";
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';
import { set } from 'lodash';

const CreateCustomerModal = ({ isOpen, onClose, selectedPhoneNumber, location_name }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!isOpen) {
      setFirstName('');
      setLastName('');
    }
  }, [isOpen]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const getInitials = () => {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
    onClose();
  };

  const handleSaveCustomerData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      const data = {
        phoneNumber: selectedPhoneNumber,
        firstName,
        lastName,
        userSub: user.sub, // Assuming you have the userSub
        location_name
      };
  
      const response = await fetch(`${apiUrl}/api/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        showSnackBar('Customer saved successfully!', true);
        handleReloadComponents(); // Reload components from context
        setIsLoading(false);
        onClose();


      } else {
        console.error('Failed to save customer:', response.statusText);
        showSnackBar('Failed to save customer. Please try again', false);
        setIsLoading(false);
        onClose();

      }
    } catch (error) {
      console.error('Failed to save customer:', error);
      showSnackBar('Failed to save customer. Please try again', false); 
      setIsLoading(false);
      onClose();

    }
  };
  


  return (
    <Dialog open={isOpen} onClose={onClose}
    fullWidth={true} // Make the dialog take up the full width
    PaperProps={{ sx: { borderRadius: "18px", border: '1.25px solid rgba(173, 216, 230, 0.7)', } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={newCustomerIcon}
          alt="newCustomerIcon"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
            marginLeft:'5px',
            width: "auto",
            height: "31px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />

      </DialogTitle>
	
      <DialogContent
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          padding: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >

          <Typography  sx={{  color: "#48768C" , fontSize:'21px', mb:2, fontWeight:600}}>
          Create Customer{" "}
        </Typography>
       

      



<Box sx={{ mb:2,                   border: '1.25px solid rgba(173, 216, 230, 0.7)',
 borderRadius: "15px",backgroundColor: 'rgba(191, 218, 231, 0.1)', boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px",
 }}>
   <Avatar
    sx={{
      ml: 2,
      backgroundColor: 'rgba(8, 19, 42, 0.7)',
      width: 50,
      height: 50,
      mr: 2,
      mt:2,
      mb:1,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
{firstName || lastName ? (
      // Render initials when at least one field has content
      <Typography sx={{ fontSize: '24px' }}>  {getInitials()}</Typography>
    ) : (
      // Render Person Add Icon when both fields are empty
      <PersonAddOutlinedIcon sx={{ width: '24px', height: '24px' }} />
    )}
</Avatar>

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left',              px:2,
 }}>     
 
 
  <TextField
 placeholder="First Name"
 variant="standard"  
 helperText="First Name"
        sx={{
          width: '100%',
          ml:1,
          color: '#48768C',
          pb: 2,
          mr: 2,
          '& input': {
            textAlign: 'left',
            color: '#48768C',
          },

        }}
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <TextField
            placeholder="Last Name"
            variant="standard"  
            helperText="Last Name"
        sx={{
          width: '100%',

          color: '#48768C',
          pb: 2,
          '& input': {
            textAlign: 'left',
            color: '#48768C',
          },

        }}
        value={lastName}
        onChange={handleLastNameChange}
      />
</Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, px:2 }}>
  <TextField
    value={selectedPhoneNumber}
    disabled
    placeholder="Phone Number"
    variant="standard"  
    helperText="Phone Number"    

    sx={{
      ml:1,

width:'100%',
      color: '#48768C',
      textAlign: 'left',
      pb: 4,
      '& input': {
        width: '100%', // Make the input take up the entire width
        textAlign: 'left', // Center the text within the input
        color: '#48768C',

      },
    }}
  />
</Box>

</Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
  variant="contained"
  sx={{
    color: '#F0F0F0',
    backgroundColor: '#46A793',
    mt:1,
    mb: 3,  
    fontSize: '1rem', // Increase font size
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.)', // Add box shadow
    transition: 'background-color 0.3s ease',
    width: '75%',
    '&:hover': {
      backgroundColor: '#079E80',
    },
    '&:active': {
    backgroundColor: '#2E7D32', // Adjust active state color if needed
    },
    }}
    
    onClick={handleSaveCustomerData } disabled={!firstName || !lastName || isLoading}>   
    
  {isLoading ? 'Saving...' : 'Save'}
</Button>


        </Box>
      </DialogContent>
      <Dialog open={isConfirmationOpen} onClose={handleConfirmationClose} PaperProps={{ sx: { borderRadius: "20px", } }}>
          <DialogContent          sx={{ justifyContent:'center', textAlign:'center', display:'flex', width:'100%', alignItems:'center', flexDirection:'column'}}

>

  <Typography sx={{color:'#48768C', fontSize: 15, fontWeight: 600, alignText:'center', mt:.5}} >Customer saved successfully!</Typography>
        </DialogContent>
        <DialogActions>
        <Box
  sx={{
    justifyContent: 'center', // Center the button horizontally
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }}
>

        <Button 
        sx={{display:'flex', border:'1px solid #48768C', borderRadius:'10px', padding:'5px', color:'#48768C', backgroundColor:'#FFFFFF', mb:1, mt:-1,         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#DBEAF0",
        },
        "&:active": {
          backgroundColor: "#DBEAF0", // Adjust active state color if needed
        },
      }}
           onClick={handleConfirmationClose}>OK</Button>
           </Box>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CreateCustomerModal;
