import React from 'react';
import { Modal, Typography, Button, Box } from '@mui/material';
import addNewEmployee from 'src/public/assets/addNewEmployee.png';


const SingleLocationMultiUserModal = ({ isOpen, onNext }) => {
  return (
    <Modal open={isOpen}
    slotProps={{
      backdrop: {
        onClick: null, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
padding:1,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >         <Typography variant="h5" gutterBottom
        
    sx={{
      mt:2,
      color: '#48768C',
      textAlign: 'center',
      fontSize: 17,
      fontWeight: 600,
      pb: 2,
    }}
  >          Let's Create An Account Administrator
        </Typography>
        <Typography paragraph
        
        
        
        sx={{
          color: '#48768C',
          fontSize: 14,
          fontWeight: 500,
          textAlign: 'center',
        }}
      >          NanoNotify allows all of your employees to send notifications by sharing a single login with the info you used to create your account.  </Typography>
       
       <img src={addNewEmployee} alt="Welcome" style={{ display: 'block', margin: 'auto', marginBottom: '20px', maxWidth: '60%', maxHeight:'60%' }} />

          <Typography
          
        
          sx={{
            color: '#48768C',
            fontSize: 15,
            fontWeight: 500,
            textAlign: 'center',
            mb:2
          }}
        >            
            
            An account administrator assigns each employee is their own unique username and pin.
        </Typography>
        <Typography paragraph
        
        
        sx={{
          color: '#48768C',
          fontSize: 14,
          fontWeight: 600,
          textAlign: 'center',
        }}
      >        Let's start by creating an account administrator. Then you can add more users later in the Account Management panel.  
          </Typography>

        <Box 
        sx={{ marginTop: 3, display: 'flex', justifyContent: 'center', width:'100%', mb:3 }}>
          <Button         sx={{
            display:'flex', 
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          borderRadius: '15px',
          width: '90%',
          fontSize: 15,
          fontWeight: 600,

          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        }} onClick={onNext} variant="contained" color="primary">
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SingleLocationMultiUserModal;
