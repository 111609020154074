import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Modal, Box, Avatar, Dialog, DialogTitle,DialogContent,DialogActions, Stack, Paper } from '@mui/material';
import { SharedStyledModal } from './SharedStyledModal';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import editLocationIcon from "../../public/assets/icons/icons/editLocation.png";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useAuth0 } from "@auth0/auth0-react";  
import dayjs from 'dayjs';  
import phoneIcon from "../../public/assets/icons/messageModal/phone.png";
import emailIcon from "../../public/assets/icons/icons/email.png";
import locationIcon from "../../public/assets/icons/icons/location.png";











const ViewLocationModal = ({ isOpen, onClose, onSave, viewLocationData,  }) => {
  const [locationHours, setLocationHours] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();


const location_id = viewLocationData.id;

const formatTime12Hour = (time) => {
  if (time) {
    const [hours, minutes] = time.split(':');
    const parsedTime = dayjs().set('hour', parseInt(hours)).set('minute', parseInt(minutes));

    return parsedTime.format('h:mm A'); // Format in 12-hour format with AM/PM
  }
  // If time is not available, use a default value
  return dayjs().set('hour', 0).set('minute', 0).format('h:mm A');
};

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};




useEffect(() => {
  if (isOpen) { // Check if the modal is open
    const fetchLocationHours = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();
        const userId = user.sub; // Assuming user.sub contains the user's ID
        const response = await fetch(`${apiUrl}/api/view-location-hours/${viewLocationData.location_id}/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });  
        const data = await response.json();
        setLocationHours(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching location hours:', error);
        setLoading(false);
      }
    };
    

    fetchLocationHours();
  }
}, [isOpen, viewLocationData.location_id]); // Fetch data whenever viewLocationData.location_id or isOpen changes

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// Sort the locationHours array based on the index of the day of the week in the daysOfWeek array
locationHours.sort((a, b) => daysOfWeek.indexOf(a.day_of_week) - daysOfWeek.indexOf(b.day_of_week));



 
  return (
    <Dialog open={isOpen} onClose={onClose}
    PaperProps={{ sx: { borderRadius: "15px",backgroundColor: 'none',
    border: '1px solid rgba(173, 216, 230, 0.7)', } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 4,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>


     
     
      <DialogContent
        sx={{
          width:'320px',
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
          pb:1,
          backgroundColor: 'rgba(191, 218, 231, 0.15)',
  border: '1px solid rgba(173, 216, 230, 0.7)',
  borderRadius: "15px"
        }}
      >

<Box sx={{ display: 'flex', alignItems: 'center',}}>
  <Typography variant="h6" sx={{ color: "#48768C", fontWeight: 600, letterSpacing: '-2%',ml:.25 }}>
    {viewLocationData.name}
  </Typography>
</Box>

<Box sx={{ display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <img src={phoneIcon} alt="phone" style={{ width: 'auto', height: 23, marginRight: 5 }} />
    <Typography color="textSecondary" variant="body1" sx={{  letterSpacing: '-2%',  }}>
      {formatPhoneNumber(viewLocationData.phone)}
    </Typography>
  </Box>
  
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <img src={emailIcon} alt="email" style={{ width: 'auto', height: 23, marginRight: 5 }} />
    <Typography color="textSecondary" variant="body1" sx={{  letterSpacing: '-2%',  }}>
      {viewLocationData.email}
    </Typography>
  </Box>
</Box>

    <Paper elevation={2} sx={{ backgroundColor: 'rgba(191, 218, 231, 0.1)',marginBottom: 1,borderRadius: '10px',}}> 
        <Box sx={{ borderRadius: '10px',  flexDirection: 'column', backgroundColor: 'rgba(191, 218, 231, 0.1)',
  border: '1px solid rgba(173, 216, 230, 0.7)',padding:1 }}>
  <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 600,color:"#48768C", letterSpacing:'-2%', lineHeight:'-2%', }}>
    {viewLocationData.name}'s Hours Of Operation
  </Typography>
  
  {locationHours.map(hour => (
  <Typography variant="subtitle1" key={hour.id} sx={{ textAlign: 'center' }}>
    <Stack direction="row" alignItems="center" sx={{ textAlign: 'center', display: 'flex' }}>
      <Typography component="span"  variant="body1" sx={{ fontWeight: 600, marginRight: 1, color: '#48768C', letterSpacing: '-2%', textAlign: 'center', lineHeight: '1.5', }}>
        {hour.day_of_week}:
      </Typography>
      {!hour.is_closed ? (
        <Typography variant="subtitle1" color="textSecondary" component="span" sx={{ fontWeight: 600, letterSpacing: '-2%', textAlign: 'center', lineHeight: '1.5' }}>
          {formatTime12Hour(hour.start_time)} - {formatTime12Hour(hour.end_time)}
        </Typography>
      ) : (
        <Typography variant="subtitle1" color="textSecondary" component="span" sx={{ fontWeight: 600, letterSpacing: '-2%', textAlign: 'center', lineHeight: '1.5' }}>
          Closed
        </Typography>
      )}
    </Stack>
  </Typography>
))}


  
</Box>
</Paper>   




</DialogContent>
    </Dialog>
  );
};

export default ViewLocationModal;
