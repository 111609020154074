import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import location from 'src/public/assets/location.png';
import isUrl from 'validator/lib/isURL'; // Import the isURL function from the validator library

const apiUrl = process.env.REACT_APP_API_URL;

const AddFirstLocationModal = ({ isOpen, onClose, onAddLocation, onNext }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1C2A40',
      },
    },
  });

  useEffect(() => {
    const validateForm = () => {
      const isValidName = name.trim() !== '';
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);
      setIsFormValid(isValidName && isValidEmail && isValidPhone);
    };

    validateForm();
  }, [name, email, phone]);

  const validateEmail = (email) => {
    // Use a simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    // Use a regex to match the phone number format (xxx) xxx-xxxx
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };
  

  const formatPhoneNumber = (value) => {
    // Filter non-digit characters from the input value
    const cleaned = value.replace(/\D/g, '');
    // Apply formatting based on the number of digits
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      // Format as (xxx) xxx-xxxx
      return `(${match[1]})${match[2] ? ' ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    // Filter non-digit characters and limit length to 10 digits
    let value = e.target.value.replace(/\D/g, '').slice(0, 10);
    // Format the input value as the user types
    setPhone(formatPhoneNumber(value));
  };

  const handleAddLocationClick = async () => {
    const locationData = {
      name,
      email,
      phone,
      auth0UserId: user.sub,
    };

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/locations/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(locationData),
      });
  
      if (!response.ok) {
        throw new Error('Error adding location');
      }
  
      const data = await response.json();
  
      // Only call onAddLocation if it exists
      if (onAddLocation) {
        onAddLocation(data); // Pass the newly added location information to the parent
      }
  
      onNext(); // Move to the next step after adding the location
    } catch (error) {
      console.error('Error adding location:', error);
    }
  };


  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: {
          onClick: null,
        },
      }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'30%',
padding:2,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >
        <Typography
          sx={{
            color: '#48768C',
            textAlign: 'center',
            fontSize: 21,
            fontWeight: 600,
            mb: -2,
          }}
        >
          Add Your First Location
        </Typography>

        <img
          src={location}
          alt="Welcome"
          style={{ display: 'block', margin: 'auto', marginBottom: '-20px', maxWidth: '60%', maxHeight: '60%'}}
        />

        <Typography
          sx={{
            mt: 1,
            color: '#48768C',
            fontSize: 14,
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
          }}
        >
          Important stuff like your company's website, email, and location information are automatically added to
          your notifications.
        </Typography>

        <ThemeProvider theme={theme}>
          <TextField
            label="Location Nickname (Example: Main St.)"
            variant="outlined"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            label="Location Email"
            variant="outlined"
            size="small"

            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
            error={email.trim() !== '' && !validateEmail(email)}
            helperText={email.trim() !== '' && !validateEmail(email) ? 'Invalid email' : ''}
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            label="Location Phone Number"
            variant="outlined"
            size="small"

            mb={2}
            value={phone}
            onChange={handlePhoneChange}
            fullWidth
            InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
            error={phone.trim() !== '' && !validatePhoneNumber(phone)}
            helperText={phone.trim() !== '' && !validatePhoneNumber(phone) ? 'Invalid phone number' : ''}
          />
        </ThemeProvider>
        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddLocationClick}
          disabled={!isFormValid}
        sx={{
          backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
          padding: 1,
          borderRadius: '15px',
          mb:3,
          mt:1,
          width: '95%',
          fontSize: 17,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
          },
          '&:active': {
            backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
          },
        }}>
          Continue
        </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFirstLocationModal;
