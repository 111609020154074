import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const LinkWithIcon = ({ iconSrc, text, to, onClick }) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;


  return (
    <NavLink
      to={to}
      style={{
        textDecoration: 'none',
        color: 'inherit', // Ensures that the link color matches the parent's color
        
      }}
    >
      <Box
        sx={{
height:'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          py: '10px',
          px: '10px',
          "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.02)',
            borderRadius: '10px'
          },
          backgroundColor: isActive ? 'rgba(255, 255, 255, 0.05)' : 'transparent', // Apply background color based on active state
          borderRadius: '10px',
        }}
      >
        <img src={iconSrc} alt="Icon" style={{marginLeft: '13px', width: 24, height: 24, marginRight: '8px', }} />
        <Typography   component="div"
 sx={{ color: '#F0F0F0', fontSize: 14, fontWeight: isActive ? 600 : 'inherit', pl:.75,         letterSpacing: '0.02rem',
 }}>{text}</Typography>
      </Box>
    </NavLink>
  );
};

LinkWithIcon.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkWithIcon;
