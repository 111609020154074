// EmployeeChoiceModal.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import multipleEmployees from 'src/public/assets/multipleEmployees.png';


const EmployeeChoiceModal = ({ isOpen, onClose, handleEmployeeChoice }) => {
  return (
    <Modal open={isOpen} onClose={onClose}
    slotProps={{
      backdrop: {
        onClick: null, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >

<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'35%',
padding:1,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '40px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      > 
        <Typography
        sx={{
          mt:2,
          fontSize: 18.5,
          textAlign: 'center',
          mb: -3,
          fontWeight: 600,
        }}
      >


            Lets get some information about your team
          </Typography>

          <img src={multipleEmployees} alt="Welcome" style={{ display: 'block', margin: 'auto', marginBottom: '20px', maxWidth: '60%', maxHeight:'60%' }} />


          <Typography
                   sx={{
                    color: '#48768C',
                    fontSize: 17,
                    fontWeight: 600,
                    textAlign: 'center',
                    mb: 3,
                    mt:-6  
                  }}
                >


            Does your location have multiple employees?
          </Typography>
          <Box sx={{ display:'flex', flexDirection:'row', gap:'60px', alignItems:'center', width:'100%',    justifyContent: 'center', mb:2
 }}>
            <Button onClick={() => handleEmployeeChoice('no')} 

sx={{
  display:'flex',
    backgroundColor: '#628197',
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: 14,
    height: '40px', 
    fontWeight: 600,
    width: '40%',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',

    '&:hover': {
      backgroundColor: '#1C2A40',
    },
  }}
>

         
              It's just me
            </Button>
            <Button onClick={() => handleEmployeeChoice('yes')} 
            sx={{
              display:'flex',

                backgroundColor: '#28354F',
                color: '#FFFFFF',
                borderRadius: '10px',
                fontSize: 14,
                height: '40px', 
                fontWeight: 600,
                width: '43%',

                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',

                letterSpacing: '0.02em',
                '&:hover': {
                  backgroundColor: '#7589A2',
                },
              }}
            >

              We have multiple employees
            </Button>
          </Box>
        </Box>
    </Modal>
  );
};

export default EmployeeChoiceModal;
