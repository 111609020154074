import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button,Typography, TextField, FormControl, InputLabel, Select, MenuItem, styled, IconButton,DialogContentText, Stack } from '@mui/material';
import bugIcon from '../../../public/assets/icons/navbar/bugSmall.png';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';
import shadows from 'src/theme/shadows';
import { set } from 'lodash';


const apiUrl = process.env.REACT_APP_API_URL;


const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '22px',
    padding: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    border: 'transparent', // Add white border
    boxShadow: '5px', // Remove black border
  },
}));


const StyledFormControl = styled(FormControl)(({ theme }) => ({
width:'100%',backgroundColor:'#E9EDF2',
  marginBottom: theme.spacing(2),
  position: 'relative',
  '& .MuiInputLabel-shrink': {
    transform: 'translate(0, -1.5px) scale(0.75)',paddingLeft:'7px', // Adjust the translateY value as needed
  },
}));

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 400,
  marginBottom: theme.spacing(2),
  position: 'relative',
  '& .MuiInputLabel-shrink': {
    transform: 'translate(0, -3.5px) scale(0.79)',paddingLeft:'10px', paddingTop:'5px' // Adjust the translateY value as needed
  },
}));


const BugReportDialog = ({ isOpen, onClose, onSubmit }) => {
const { user, getAccessTokenSilently } = useAuth0();
const { handleReloadComponents , showSnackBar} = useAppContext();
const [isLoading, setIsLoading] = useState(false);  




  const [bugDetails, setBugDetails] = useState({
    bugType:'',
    description: '',
    severity: '',
    stepsToReproduce: '',
    UserId: user.sub, 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBugDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

// Function to submit a bug report
const handleSubmit = async (e) => {
  e.preventDefault();
setIsLoading(true);
  try {
    const accessToken = await getAccessTokenSilently(); // Get access token silently

    const report = {
      bugType: bugDetails.bugType,
      description: bugDetails.description,
      severity: bugDetails.severity,
      stepsToReproduce: bugDetails.stepsToReproduce,
      userId: user.sub,
    };

    const response = await fetch(`${apiUrl}/api/bug-report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
      },
      body: JSON.stringify(report),
    });

    if (!response.ok) {
      showSnackBar('Failed to submit bug report. Please try again', false);
      handleReloadComponents();
      setIsLoading(false);
      onClose();

      throw new Error('Failed to submit bug report');
    }

    const data = await response.json();
    setBugDetails({
      bugType: '',
      description: '',
      severity: '',
      stepsToReproduce: '',
    });
showSnackBar('Bug report submitted successfully', true);
handleReloadComponents();
setIsLoading(false);
onClose();
  } catch (error) {
    showSnackBar('Failed to submit bug report. Please try again', false);
    console.error('Error submitting bug report:', error);
    handleReloadComponents();
    setIsLoading(false);
    onClose();  
  }
};









  return (
    <div>

<StyledDialog
  open={isOpen}
  onClose={onClose}
>
    
<IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 6,
            color: '#7589A2',
            backgroundColor: 'transparent',
            zIndex: 1,
            borderRadius: '12px',
            width: '35px',
            height: '35px',
            padding: '3px',
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', height: '90px',backgroundColor:'#FFFFFF',pt:4, pb:4.45, color:'#374247'   }}>
        <Stack direction="column" sx={{display:'flex',alignItems: 'center', height: '90px', mr:'auto', mb:1, mt:3, gap:1.5}}>
  <img src={bugIcon} alt="bug" style={{ width: 'auto', height: '32px', backgroundColor:'#FFFFFF',border: '1.25px solid rgba(173, 216, 230, 0.8)',padding:'4px',boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.09)',borderRadius:'6px', marginRight:'auto' }} />
  <Typography color="textSecondary"  sx={{   fontSize:19.5, fontWeight:600, marginRight:'auto'   
 }}>Report a Bug</Typography>
 </Stack>
</DialogTitle>


      <DialogContent
          sx={{backgroundColor:'#FFFFFF', width:'545px',height:'428px', }}
          >
      
      
      <form onSubmit={handleSubmit}>
      <Typography  sx={{color:'#48768C', fontSize: '17px', fontWeight:600,pl:'2px',pb:'5px', mt:'10px'
 }}>Affected Areas</Typography>

      <StyledFormControl
      sx={{backgroundColor:'#FFFFFF'}}
      >
            {!bugDetails.bugType && ( // Conditionally render the input label
              <InputLabel
              shrink={false}    
              sx={{marginTop: '-8px', backgroundColor:'#FFFFFF'}}>

              
                Choose a category that best describes the issue
              </InputLabel>
            )}
            <Select
              value={bugDetails.bugType}
              onChange={handleChange}
              name="bugType"
              required
              size="small"
              sx={{backgroundColor:'#FFFFFF',padding:0, }}
            >
              <MenuItem                sx={{backgroundColor:'#FFFFFF', marginTop:'-8px'}} value="Problems sending notifications">Problems sending notifications</MenuItem>
              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Certain items are not loading">Certain items are not loading</MenuItem>
              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Problems signing in">Problems signing in</MenuItem>
              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Problems with switching location">Problems with switching location</MenuItem>
              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Problems sending notifications">Unable to update my locations hours</MenuItem>

              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Some things are slow to load">Some things are slow to load</MenuItem>
              <MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Fonts and text are unreadable">Fonts and text are unreadable</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Errors in notification contents">Errors in notification contents (e.g., incorrect hours, location name)</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Issues with notification templates not loading">Issues with message templates not loading or being inaccessible</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Delays in message delivery to customers">Delays in message delivery to customers</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Difficulties in managing multiple users or locations">Difficulties in managing multiple users or locations within the application</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Failure to save contacts to the address book">Failure to save contacts to the address book</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Errors in sending 'Quick Notify' status to customers">Errors in sending "Quick Notify" status to customers</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Compatibility issues with certain browsers or devices">Compatibility issues with certain browsers or devices</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="App freezes or crashes during heavy usage or specific actions">App freezes or crashes during heavy usage or specific actions</MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF'}} value="Incorrect calculation of message dates and times ">Incorrect calculation of message dates and times </MenuItem>
<MenuItem               sx={{backgroundColor:'#FFFFFF', marginBottom:'-10px'}} value="Other">Other (please specify)</MenuItem>


            </Select>
          </StyledFormControl>
          <Typography sx={{ fontSize: '17px', fontWeight:600,pl:'2px', pb:'5px', color:'#48768C' }}>Bug Description</Typography>

          <TextField
  sx={{ mb:2,
    '& textarea': {
      minHeight: '50px' // Set the minimum height of the textarea
    }
  }}            placeholder="Describe the problem in detail"
            name="description"
            value={bugDetails.description}
            onChange={handleChange}
            multiline
            fullWidth
            required
          />
                    <Typography sx={{ fontSize: '17px', fontWeight:600,pl:'2px', pb:'3px', color:'#48768C' }}>Severity</Typography>

          <SeverityFormControl
          sx={{width:'100%', backgroundColor:'#FFFFFF'}}
          >
  {bugDetails.severity === '' && (
    <InputLabel 
    shrink={false}
    sx={{marginTop: '-8px', pl:'2px'}}>How bad is the problem?</InputLabel>
  )}
            <Select
              value={bugDetails.severity}
              onChange={handleChange}
              name="severity"
              size='small'
              required
            >
                            <MenuItem                sx={{backgroundColor:'#FFFFFF', marginTop:'-8px'}} value="Low">Causes issues, but is still functional</MenuItem>
                            <MenuItem                sx={{backgroundColor:'#FFFFFF'}}value="Medium">Requires workaround, but still works  </MenuItem>
                            <MenuItem                sx={{backgroundColor:'#FFFFFF', marginBottom:'-8px'}} value="High">Does not work at all</MenuItem>
            </Select>
          </SeverityFormControl>
          <Typography sx={{ fontSize: '17px', fontWeight:600, color:'#48768C',pb:'3px' }}>Steps To Reproduce</Typography>

          <TextField
  sx={{
    
    '& textarea': {
      minHeight: '50px' // Set the minimum height of the textarea
    }
  }}
  placeholder="How can we make the problem happen again? (Tell us step by step)"
  name="stepsToReproduce"
  value={bugDetails.stepsToReproduce}
  onChange={handleChange}
  multiline
  fullWidth
  required
/>

        </form>
      </DialogContent>
      <DialogActions
      
      sx={{backgroundColor:'#FFFFFF',width:'94%',mx:2, my:'15px'}}>
      
              <Button 
              sx={{borderRadius: '10px',mr:'8px',fontSize: '16px',mb:1, color:'#48768C', fontWeight:500,height:'51px',width:'25%', backgroundColor:'#F2F9FF',    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',letterSpacing: '0.2px',
              '&:hover': {
                backgroundColor: '#A5CADB', // Adjust the background color for contrast
                color: '#F0F0F0', // Keep text color consistent
              },
            }}
              onClick={onClose}>Cancel</Button>
<Button
  sx={{
    borderRadius: '10px',
    letterSpacing: '0.1px',
    width: '100%',
    height: '51px',
    fontSize: '18px',
    mb:1,
    fontWeight: 500,
    backgroundColor: '#48768C',

    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Add shadow

    color: 'rgba(233, 242, 255, 0.99)',
    '&:hover': {
      backgroundColor: '#3B6B7D', // Adjust the background color for contrast
      color: 'rgba(233, 242, 255, 0.95)', // Keep text color consistent
    },
  }}
  onClick={handleSubmit}
  variant="contained"
  disabled={!bugDetails.bugType || !bugDetails.description || !bugDetails.severity || !bugDetails.stepsToReproduce || isLoading}
>
{isLoading ? 'Submitting...' : 'Submit'}
</Button>
      </DialogActions>

      </StyledDialog>
    </div>

  );
};

export default BugReportDialog;
