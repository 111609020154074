import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './context/AuthContext'; // Import the AuthProvider
import { AppProvider } from './context/AppContext'; 
import { SingleUserProvider } from './context/SingleUserContext';
import { LogoProvider } from './context/LogoContext'; // Import the LogoProvider context
import { WidgetProvider } from './context/WidgetContext';
import { MessageProvider } from './context/MessagesContext';
import { ReportsProvider } from './context/ReportsContext';
import { UserSelectedNotificationsProvider } from './context/UserNotificationsContext';
import {CustomersProvider} from './context/CustomersContext'; 
import {GlobalNotificationsProvider} from './context/GlobalNotificationsContext'; 

import '@fortawesome/fontawesome-free/css/all.css';



import { AccountProvider } from './context/AccountContext'; // Import the AccountProvider
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

export default function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: 'secure/endpoints',
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid profile email"
    >
      
      <AuthProvider> 
        <AccountProvider>
          <SingleUserProvider>
            <AppProvider>

              <LogoProvider> 
              <GlobalNotificationsProvider> 
              <WidgetProvider>



                  <MessageProvider>
                    <UserSelectedNotificationsProvider>
                    <ReportsProvider>
                    <CustomersProvider>
                    <HelmetProvider>
                      <BrowserRouter>
                        <ThemeProvider>
                          <ScrollToTop />
                          <StyledChart />
                          <Router />
                        </ThemeProvider>
                      </BrowserRouter>
                    </HelmetProvider>
                    </CustomersProvider>
                    </ReportsProvider>
                    </UserSelectedNotificationsProvider>
                    </MessageProvider>
                    </WidgetProvider>

                </GlobalNotificationsProvider>

              </LogoProvider> 

            </AppProvider>
          </SingleUserProvider>
        </AccountProvider>
      </AuthProvider>
    </Auth0Provider>
  );
}
