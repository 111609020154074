import React, {useState, useEffect} from 'react';
import {
	Card,
	Typography,
	Divider,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	CardContent,
	CardHeader,
	TableHead,
	TableCell,
	Paper,
	TableContainer,
	Table,
	TableRow,
	Box,
	TableBody,
	Rating,
	
	
	
} from '@mui/material';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import GlobalNotifications from '../components/modal/globalNotifications'; // Adjust the path accordingly
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import  fetchGlobalNotifications  from '../utils/notificationUtils'; 




const StyledTableCell = styled(TableCell)(({ theme }) => ({
	'&.MuiTableCell-head': {
	  backgroundColor:'#3B3B3D',
	  color: '#ffffff',
	  textAlign: 'center',
	},
	'&.MuiTableCell-body': {
	  fontSize: 14,
	  textAlign: 'center',

	},
  }));

  const StyledTableBody = styled(TableCell)(({ theme }) => ({
	'&.MuiTableCell-head': {
	  backgroundColor:'#3B3B3D',
	  color: '#ffffff',
	  textAlign: 'center',
	},
	'&.MuiTableCell-body': {
	  fontSize: 14,
	  textAlign: 'center',

	},
  }));


const dividerWidth = '9%';

const PageContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	height: '100vh',
	backgroundColor: '#f5f5f5',
	paddingTop: '2.5%',
});

const FullPageCard = styled(Card)({
	width: '95%',
	borderRadius: 16,
	background: 'white',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	padding: '2%',
	overflow: 'auto',
});

const Header = styled(Typography)({
	fontWeight: 'bold',
	fontSize: '1.25rem',
	width: '95%',
	textAlign: 'left',
	marginBottom: '1rem',
	marginLeft: '.5rem',
});

const VerticalDivider = styled(Divider)({
	height: '90%',
	marginTop: '3%',
	marginBottom: '1%',
	marginRight: '2.5%',
	width: dividerWidth,
});

const Sidebar = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	width: dividerWidth,
	height: '65%',
	marginRight: '1rem',
	paddingTop: '2rem',
});

const NavButton = styled(Button)(({theme, isActive}) => ({
	whiteSpace: 'nowrap',
	textAlign: 'left',
	marginBottom: '0.5rem',
	borderRadius: '25px',
	textTransform: 'none',
	fontWeight: 'bold',
	fontSize: '0.87rem',
	padding: '0.5rem 1.5rem',
	backgroundColor: isActive ? 'rgba(100, 150, 255, 0.6)' : 'transparent',
	color: isActive ? 'white' : '#a0a0a0',
	boxShadow: isActive ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
	'&:hover': {
		color: 'white',
		backgroundColor: isActive ? 'rgba(80, 130, 235, 0.7)' : 'transparent',
	},
}));

const AdminPanel = () => {
	const apiUrl = process.env.REACT_APP_API_URL;

	const [submenu, setSubmenu] = useState('bug_reports');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const setParentIsModalOpen = (value) => {
		setIsModalOpen(value);
	};

	const [title] = useState('Your Monthly Cost');
	const [totalValue, setTotalValue] = useState(0);
	const [formatAsCurrency] = useState(true);

	function fShortenNumber(num) {
		if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
		}
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
		}
		if (num >= 1000) {
			return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
		}
		return num;
	}


		const [bugReports, setBugReports] = useState([]);
	  
		useEffect(() => {
			async function fetchBugReports() {
			  try {
				const accessToken = await getAccessTokenSilently();
				const response = await fetch(`${apiUrl}/api/bug-reports`, {
				  headers: {
					Authorization: `Bearer ${accessToken}`
				  }
				});
		  
				if (!response.ok) {
				  throw new Error('Failed to fetch bug reports');
				}
		  
				const bugReportsData = await response.json();
		  
				setBugReports(bugReportsData);
			  } catch (error) {
				console.error('Error fetching bug reports:', error);
			  }
			}
		  
			fetchBugReports();
		  }, []);

		  const [feedback, setFeedback] = useState([]);

		  useEffect(() => {
			async function fetchFeedback() {	
							try{
								const accessToken = await getAccessTokenSilently();
								const response = await fetch(`${apiUrl}/api/feedback`, {
									headers: {
										Authorization: `Bearer ${accessToken}`
									}
								});
								if (!response.ok) {
									throw new Error('Failed to fetch feedback');
								}
								const feedbackData = await response.json();
								setFeedback(feedbackData);
							} catch (error) {
								console.error('Error fetching feedback:', error);
							}
						}
						fetchFeedback();
					}, [submenu === 'feedback']);


					console.log('feedback data', feedback)
								
						
		  

		  useEffect(() => {
			const fetchTotalValue = async () => {
			  setIsLoading(true); // Set loading state to true while fetching
		  
			  try {
				const accessToken = await getAccessTokenSilently();
				let endpoint = '';
				switch (title) {
				  case 'Messages Sent This Week':
					endpoint = `${apiUrl}/api/communications/this-week`;
					break;
				  case 'Your Monthly Cost':
					endpoint = `${apiUrl}/api/communications/your-monthly-cost`;
					break;
				  default:
					break;
				}
		  
				const response = await fetch(endpoint, {
				  headers: {
					Authorization: `Bearer ${accessToken}`
				  }
				});
				const data = await response.json();
		  
				switch (title) {
				  case 'Messages Sent This Week':
					setTotalValue(data.totalMessages);
					break;
				  case 'Your Monthly Cost':
					setTotalValue(data.totalCost);
					break;
				  default:
					break;
				}
			  } catch (error) {
				console.error(`Failed to fetch ${title}:`, error);
			  } finally {
				setIsLoading(false); // Set loading state to false when data fetching is complete
			  }
			};
		  
			fetchTotalValue();
		  }, [title]);
		  

	

	const displayTotal = formatAsCurrency
		? `$${fShortenNumber(totalValue)}`
		: fShortenNumber(totalValue);

	const [isLoading, setIsLoading] = useState(true);

	const formatTimestamp = (timestamp) => {
		const date = new Date(timestamp);
		const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;
		const hours = date.getHours() % 12 || 12; // Convert 24-hour time to 12-hour time
		const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
		const formattedTime = `${hours.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${ampm}`;
		return `${formattedDate} ${formattedTime}`;
	  };


		const [email, setEmail] = useState('');
		const [inviteStatus, setInviteStatus] = useState('');
		const { getAccessTokenSilently } = useAuth0(); // Destructure the getAccessTokenSilently function from the useAuth0 hook

	  

		
		const handleSendInvite = async () => {
			try {
			  setIsLoading(true); // Set loading to true before making the API call
		  
			  const accessToken = await getAccessTokenSilently(); // Obtain the access token silently from Auth0
			  // Send a request to your backend API to send the invitation
			  await axios.post(
				`${apiUrl}/auth/send-invite`,
				{ email },
				{
				  headers: {
					Authorization: `Bearer ${accessToken}`, // Pass the access token in the Authorization header
				  },
				}
			  );
			  setInviteStatus('Invite sent successfully');
			} catch (error) {
			  console.error('Error sending invite:', error);
			  setInviteStatus('An error occurred while sending the invite');
			} finally {
			  setIsLoading(false); // Reset loading state regardless of success or error
			}
		  };
		  

	return (
		<div style={{width: '100%'}}>
			<PageContainer
			sx={{backgroundColor: 'rgba(233, 242, 255, 0.9)'}}
			
			>
				<FullPageCard>


					<Sidebar
					sx={{width:'125px'}}>

<Header
sx={{mt:-2, mb:2}}
				>Admin Panel</Header>

<NavButton
              isActive={submenu === 'notifications'}
              onClick={() => setSubmenu('notifications')}
            >
              Post A New Global Notification
            </NavButton>
						<NavButton
							isActive={submenu === 'bug_reports'}
							onClick={() => setSubmenu('bug_reports')}>
Bug Reports
						</NavButton>
						<NavButton
							isActive={submenu === 'monthly_cost'}
							onClick={() => setSubmenu('monthly_cost')}>
							Billing And Costs						
						</NavButton>
						<NavButton
							isActive={submenu === 'feedback'}
							onClick={() => setSubmenu('feedback')}>	
							Feedback
						</NavButton>
						<NavButton
							isActive={submenu === 'invite_users'}
							onClick={() => setSubmenu('invite_users')}>
							Invite Users
						</NavButton>
						<NavButton
							isActive={false}
							disabled>
							User Metrics
						</NavButton>
						<NavButton
							isActive={false}
							disabled>
							Health and Status
						</NavButton>
	
					</Sidebar>
					<VerticalDivider
						orientation="vertical"
						flexItem
					/>
					
					{submenu === 'notifications' && (
						<GlobalNotifications setParentIsModalOpen={setParentIsModalOpen} />
					)}
										{submenu === 'monthly_cost' && (
					<Card>
<CardHeader title="Your Current Monthly Cost" />
					<CardContent>
						{isLoading ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: 200,
								}}>
								<CircularProgress
									size={100}
									style={{color: '#4CAF50'}}
								/>{' '}
								{/* Loading spinner with custom color */}
							</div>
						) : (
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<div
									style={{
										width: 200,
										height: 200,
										borderRadius: '50%',
										border: '3.5px solid #4CAF50',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: '#4CAF50',
										fontSize: 40,
									}}>
									{formatAsCurrency
										? `$${totalValue.toFixed(2)}`
										: totalValue.toFixed(2)}
								</div>
							</div>
						)}
					</CardContent>{' '}

					</Card>
					
					
					
					)}


{submenu === 'bug_reports' && (
													<Card
													sx={{width:'100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',	}}
													>

							<Paper sx={{ width: '100%', overflow: 'hidden', borderRadius:'2px' }}>
							<Typography sx={{ color: '#48768C', fontWeight: 700, fontSize: '1.2rem', width:'100%', ml:1, pt:2, mb:-2 }}>
													Manage Bug Reports          </Typography>
												<TableHead
							sx={{width:'100%',  
							justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',
							}}
							>


							<TableCell align="center" colSpan={5} sx={{ textAlign:'center',backgroundColor: 'rgba(255, 255, 255, 1.9)', borderBottom: 'none',  }}>

									</TableCell>
									<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date Submitted</TableCell>
            <TableCell>Severity</TableCell>
            <TableCell>Description</TableCell>
			<TableCell>Steps To Reproduce</TableCell>

            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bugReports.map((report, index) => (
            <TableRow key={index}>
              <TableCell>{formatTimestamp(report.timestamp)}</TableCell>
              <TableCell>{report.severity}</TableCell>
              <TableCell>{report.description}</TableCell>
			  <TableCell>{report.steps_to_reproduce}</TableCell>
              <TableCell>{report.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
							</TableHead>
							</Paper>
							</Card>
							
							
							)}
							

							{submenu === 'feedback' && (
													<Card
													sx={{width:'100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',	}}
													>

							<Paper sx={{ width: '100%', overflow: 'hidden', borderRadius:'2px' }}>
							<Typography sx={{ color: '#48768C', fontWeight: 700, fontSize: '1.2rem', width:'100%', ml:1, pt:2, mb:-2 }}>
													User Feedback         </Typography>
												<TableHead
							sx={{width:'100%',  
							justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',
							}}
							>


							<TableCell align="center" colSpan={5} sx={{ textAlign:'center',backgroundColor: 'rgba(255, 255, 255, 1.9)', borderBottom: 'none',  }}>

									</TableCell>
									<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date Submitted</TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Strengths</TableCell>
			<TableCell>Areas of Improvement</TableCell>

            <TableCell>Additional Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {feedback.map((feedback, index) => (
            <TableRow key={index}>
              <TableCell>{formatTimestamp(feedback.timestamp)}</TableCell>
              <TableCell>     <Rating value={feedback.rating} readOnly
        sx={{ fontSize: '32px', fontWeight:600,color:'#68CFBA  ',pb:'5px', }}> </Rating>  </TableCell>
              <TableCell>{feedback.feedback_text}</TableCell>
			  <TableCell>{feedback.improvement_areas}</TableCell>
              <TableCell>{feedback.additional_comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
							</TableHead>
							</Paper>
							</Card>
							
							
							)}
{submenu === 'invite_users' && (
					 <Card>
					 <CardHeader title="Send Beta Invite To Users" />
					 <CardContent sx={{ height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%'}}>
					   <TextField
						 id="email"
						 label="Email Address"
						 variant="outlined"
						 value={email}
						 onChange={(e) => setEmail(e.target.value)}
						 sx={{ width: '100%', mb: 2 }}
					   />
					   <Button
						 variant="contained"
						 color="primary"
						 onClick={handleSendInvite}
						 sx={{ width: '100%', mb: 2 }}
					   >
						 {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Send Invite'} {/* Render button text or loading spinner based on isLoading state */}
            </Button>
            {inviteStatus && <Typography>{inviteStatus}</Typography>}



{submenu === 'feeback' && (

	<Box> test</Box>

)}
          </CardContent>
        </Card>
					
					
					
					)}
				</FullPageCard>
			</PageContainer>
		</div>
	);
};

const ProtectedAdminPanel = withAuthenticationRequired(AdminPanel);

export default ProtectedAdminPanel;
