import React, { useState } from 'react';
import SingleUserSingleLocationModal from './SingleUserSingleLocationModal';
import SingleLocationMultiUserModal from './SingleLocationMultiUserModal';
import CreateSingleUserModal from './CreateSingleUserModal';
import NewSingleUserFromAuth0 from './NewSingleUserFromAuth0';
import NewAccountSuccess from './NewAccountSuccess';
import NewAccountSuccessSingleUser from './NewAccountSuccessSingleUser';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Modal, Box, Typography, Paper } from '@mui/material';
import EmployeeChoiceModal from './EmployeeChoiceModal';


// ... (your imports)

const NewUserSingleLocation = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasMultipleEmployees, setHasMultipleEmployees] = useState(null);

  const handleNext = () => {
    setIsModalOpen(false);
    setStep(2); // Move to the next step after adding the location
  };

  const handleEmployeeChoice = (choice) => {
    setHasMultipleEmployees(choice);
    setStep(choice === 'no' ? 3 : 4);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SingleUserSingleLocationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onAddLocation={handleNext}
          />
        );
        case 2:
          return (
            <EmployeeChoiceModal
            isOpen={true}
            onClose={() => setIsModalOpen(false)}
            handleEmployeeChoice={handleEmployeeChoice}
          />
        );
        case 3:
        return (
          <CreateSingleUserModal
            isOpen={hasMultipleEmployees === 'no'}  // Use hasMultipleEmployees to control modal visibility
            onClose={onClose} 
            onNext={() => setStep(8)} 
          />
        );
        case 4:
          return (
            <SingleLocationMultiUserModal
              isOpen={hasMultipleEmployees === 'yes'}
              onNext={() => setStep(5)}
            />
          );
        case 5:
          return (
            <NewSingleUserFromAuth0
              isOpen={hasMultipleEmployees === 'yes'} // Use hasMultipleEmployees to control modal visibility
              onClose={() => setStep(7)}
              onSave={handleNext}
            />
          );
      case 6:
        return <NewAccountSuccessSingleUser onClose={onClose} onNext={() => setStep(8)} />;
      case 7:
        return <NewAccountSuccess onClose={onClose} onNext={() => setStep(8)} />; 
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

export default NewUserSingleLocation;





