import React from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import mcLogo from "../../../public/assets/billing/mclogo.png";






const PaymentHistoryContainer = styled("div")({
  padding: "1rem",
  paddingTop: "2rem", // Add top padding to the payment history card
});

function Billing() {
  

  return (
    <Box sx={{ width: "100%", mt: 5, }}>
      <Box
        sx={{
          width: "98%",
            overflow: 'auto',height: '74vh',


        }}
      >


        <Box
          sx={{
            fontSize: ".9rem",
            fontWeight: 600,
            letterSpacing: "0.02em",
            lineHeight: 1.5,
            color: "#48768C",
          }}
        >
          <Card
          sx={{            
          width:'55%', padding:1.5, backgroundColor: 'rgba(191, 218, 231, 0.1)',
          border: '1px solid rgba(173, 216, 230, 0.7)', boxShadow: "0 4px 8px rgba(0,0,0,0.1)",}}
          >
        <Typography sx={{ mb: 1, mt: 0.5,color:'#576369', fontSize:16, fontWeight:600, color: "#48768C", }} gutterBottom>
          Your Current Plan and Billing Information
        </Typography>

          <Grid container spacing={4}>
            <Grid item xs={5}>
              <Typography
              color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 1,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                Account Balance
              </Typography>
              <Typography
                            color="textSecondary"

                sx={{
                  mb: 1,
                  mt: 1,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                Your Current Plan
              </Typography>
              <Typography
                            color="textSecondary"

                sx={{
                  mb: 1,
                  mt: 1,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                Payment Recurrence
              </Typography>
              <Typography
                            color="textSecondary"

                sx={{
                  mb: 1,
                  mt: 1,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                Payment Method
              </Typography>
            </Grid>

            <Grid sx={{ }} item xs={5}>
              <Typography
                align="left"
                sx={{
                  color:'#48768C', 
                  mb: 1,
                  mt: 1,
                  ml:16,
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                $0.00
              </Typography>
              <Typography
                align="left"
                sx={{
                  mb: 1,
                  color:'#48768C', 

                  mt: 1,
                  ml:16,
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                Unlimited
              </Typography>
              <Typography
                align="left"
                sx={{
                  mb: 1,
                  mt: 1,
                  ml:16,
                  whiteSpace: "nowrap",
                  color:'#48768C', 

                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                N/A
              </Typography>
              <Typography
                align="left"
                sx={{
                  mb: 1,
                  mt: 1,
                  ml:16,
                  whiteSpace: "nowrap",
                  color:'#48768C', 

                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  fontSize: ".9rem",
                  textTransform: "none",
                }}
              >
                N/A
              </Typography>
            </Grid>
          </Grid>
          </Card>
        </Box>
        <Typography sx={{ mb: 1.5, mt: 3.5, color:'#48768C', fontSize:16.5, fontWeight:600,ml:.75}} gutterBottom>
          Recent Payment History
        </Typography>
        <PaymentHistoryContainer sx={{ margin: 0, padding: 0,              ml:.75,
 }}>
          <TableContainer
            component={Paper}
            sx={{
              margin: 0,
              padding: 0,
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              border: '1.25px solid rgba(173, 216, 230, 0.5)',
              borderRadius: "6px",
              
            }}
          >
            <Table
              size="small" sx={{minHeight:'42vh'}}>
              <TableHead>
                <TableRow>
                  <TableCell
                                    align= 'left'

                    sx={{
                      backgroundColor: "#FFFFFF",
                      pl: 9,
                      color: "#48768C",
                    }}
                  >
                    {" "}
                    Month
                  </TableCell>
                  <TableCell
                  align= 'center'
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#48768C",
                    }}
                  >
                    {" "}
                    Amount
                  </TableCell>
                  <TableCell
                                    align= 'right'

                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#48768C",
                      pr:9
                    }}
                  >
                    {" "}
                    Card
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell colSpan={3} sx={{backgroundColor: 'rgba(191, 218, 231, 0.1)'}} >
                  <Typography
                    align="center"
                    sx={{
                      mt:5,
                      width: '100%',
                      padding: '20px',
                      color: '#48768C',
                      fontSize: 16,
                      ml:2,
                      justifyContent: 'center',
                      height:'15vh',  
                      borderLeft:'none',
                      borderRight:'none', 
                      fontWeight:600,   
                    }}
                  >
                    No Recent Payment History...
                  </Typography>
                </TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </PaymentHistoryContainer>
      </Box>
    </Box>
  );
}

export default Billing;
