import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Button, Card } from '@mui/material';
import { styled } from '@mui/system';
import DetailsIcon from '../public/assets/icons/icons/details.png';
import SendIcon from '../public/assets/icons/icons/help-center/send.png';
import DashboardIcon from '../public/assets/icons/icons/help-center/home.png';
import LogoIcon from '../public/assets/icons/icons/help-center/logo.png';
import HoursIcon from '../public/assets/icons/icons/help-center/hours.png';
import SettingsIcon from '../public/assets/icons/icons/help-center/settings.png';
import ProfileIcon from '../public/assets/icons/icons/help-center/user.png';
import EditUser from '../public/assets/icons/icons/help-center/editUser.png';
import EditHoursIcon from '../public/assets/icons/icons/help-center/editHours.png';
import DraftIcon from '../public/assets/icons/icons/help-center/draft.png';
import NotifyIcon from '../public/assets/icons/icons/help-center/notify.png';
import ReviewIcon from '../public/assets/icons/icons/help-center/review.png';
import TicketIcon from '../public/assets/icons/icons/help-center/ticket.png';
import ContentIcon from '../public/assets/icons/icons/help-center/contents.png';
import CheckIcon from '../public/assets/icons/icons/help-center/check.png';
import LogIcon from '../public/assets/icons/icons/help-center/log.png';
import LocationIcon from '../public/assets/icons/icons/help-center/location.png';
import LockIcon from '../public/assets/icons/icons/help-center/lock.png';
import EditIcon from '../public/assets/icons/icons/help-center/edit.png';
import AddCustomer from '../public/assets/icons/icons/help-center/newCustomer.png';
import BillIcon from '../public/assets/icons/icons/help-center/bill.png';
import CardIcon from '../public/assets/icons/icons/help-center/card.png';
import InvoiceIcon from '../public/assets/icons/icons/help-center/invoice.png';
import  fetchGlobalNotifications  from '../utils/notificationUtils'; 
import { useAuth0 } from '@auth0/auth0-react';












const FullPageCard = styled(Box)({
  position: 'relative',
  width: '100%',
  borderRadius: 16,
  background: 'white',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  overflow: 'auto',
  padding: '1%',
  height:'600px'
});

const Header = styled(Typography)({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '95%',
  textAlign: 'left',
  mb: 5,
});

const Sidebar = styled(Box)({
  flexDirection: 'column',
  alignItems: 'left',
  alignText: 'left',
  height: '95%',
  minHeight: '100%',
  marginRight: '1%',
  marginTop: 70,
  width: 285,
});

const NavButton = styled(Button)(({ theme, isActive }) => ({
  whiteSpace: 'nowrap',
  marginBottom: '0.5rem',
  textTransform: 'none',
  fontSize: 13.5,
  fontWeight: 500,
  letterSpacing: '0.02em',
  lineHeight: 1.5,
  width: '100%',
  padding: '10px 15px',
  transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
  justifyContent: 'flex-start',
  background: isActive ? 'rgba(173, 216, 230, 0.4)' : 'transparent',
  color: isActive ? '#48768C' : '#48768C',
  boxShadow: isActive ? '0px 4px 8px rgba(72, 118, 140, 0.3)' : 'none',
  '&:hover': {
    background: isActive ? 'rgba(173, 216, 230, 0.4)' : 'rgba(77, 96, 139, 0.1)',
    color: isActive ? '#48768C' : '#48768C',
  },
}));



const HelpCenter = () => {
  const commonTopics = ['Getting Started', 'Account Setup', 'Sending Notifications', 'Managing Users and Locations', 'Your Notification History', 'Managing Your Contacts', 'Billing and Subscriptions'];
	const {user, isAuthenticated} = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;






  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scrollbar
    return () => {
      document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
    };
  }, []);

  // Define content for each topic
  const topicContent = {
    'Getting Started': (
      <>
      <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",overflowY: 'auto',
            marginBottom: "1rem", height:'465px'}}>
<Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369', mb:2 }}>Getting Started</Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={HoursIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.5rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Setup Your Location's Hours</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1, mb:2 }}>NanoNotifiy automatically includes your locations hours for the day in each notification you send. You can setup your location's hours or configure which days your location is open or closed on by clicking the “View and Edit Hours” text in the location information panel, found in your profile page.</Typography>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={SendIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px" }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369', marginLeft: '0.5rem' }}>Sending A Notification</Typography>
  </div>
</Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>NanoNotify makes sending notifications to your customers quickly and with ease. To send a notification, simply click the notification button in the navigation bar enter your customers details and click send!</Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={DashboardIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.5rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Account Dashboard</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>The account dashboard contains a brief overview of your recent and activity, in addition to any notifications that are pending approval from your customers. If your account is set up to handle multiple locations or multiple users, it will display recent activity from your entire company. You can easily switch between recent activity, monthly activity or notifications pending approval simply by selecting the corresponding widget on the top of the dashboard.</Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={LogoIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.5rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Personalize your account by uploading your company's logo</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>To add your company's logo so that it is shown on the dashboard, select the “Upload your logo” button in the Profile section. Once uploaded, the default NanoNotify logo will be removed, your company's logo will be automatically resized to fit and will be displayed in the navigation panel.</Typography>

</Card>
      </>
    ),
    'Account Setup': (
      <>
      <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",
            marginBottom: "1rem",overflowY: 'auto', height:'475px' }}>
              
<Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Setting Up Your Account</Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={SettingsIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.5rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Managing Your Account</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>The account panel contains multiple different personalization and configuration options for your account and your company. </Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={ProfileIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Your Profile</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>The profile page displays your user information including your name, company information and your locations information. If your account is set up to for multiple locations, this will show the location information you are actively assigned to. </Typography>
<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditUser} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Adding and editing your employees and locations</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>For companies that have multiple users and locations, you can edit, add or remove employees or locations as you need. You may also edit your locations contact information here such as the contact email or phone number. </Typography>
<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#576369', mt: 1, mb:2 }}>Upgrading your account from a single location or a single user to a multi user or multi location account is currently not supported in the closed beta program. If you need to upgrade your account, please send us an email and we can process the account change on our end. </Typography>

<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditHoursIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Editing your location's hours</Typography>
  </Box>
<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: 1 }}>To edit your location's hours or to configure which days your location is open or closed on, simply click the “View and Edit Hours” text in the Location Information panel found in your profile page.</Typography>

</Card>
      </>
    ),
    'Sending Notifications': (
      <>
          <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",overflowY: 'auto',height:'800px',
             }}>  
 <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Sending Notifications</Typography>
 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={NotifyIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Schedule Your Notification For Furture Delivery
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt:.5, ml:1.25}}> You can schedule a notification to be delivered to your customer at just the right time. Notifications must by scheduled at least 30 minutes before the intended delivery time. </Typography>


  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
    <img src={ReviewIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "1px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Review Notification Before Sending</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Once all of the required fields have been entered in to your notification, the preview button will become enabled. This allows you to see exactly what your customer will receive upon delivery.  </Typography>

  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
    <img src={TicketIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Including A Ticket Number</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> A ticket number is required when sending a notificaion to your customers. This can be whatever number your company uses to track orders, such as an invoice number or a transaction ID. This is also included in your notificaion sent to your customer</Typography>

  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
    <img src={ContentIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Notification Contents</Typography>
  </Box>
  <Typography sx={{ fontSize: 13.5, fontWeight: 500, color: '#576369',mt: .5,  ml:1.25 }}> Each Notification you send will automatically include the following information without any extra effort on your end: </Typography>
  <Typography sx={{ fontSize: 13.5, fontWeight: 600, color: '#576369', ml:1.25 }}> Location phone number </Typography>
  <Typography sx={{ fontSize: 13.5, fontWeight: 600, color: '#576369', ml:1.25 }}> Location email and website URL </Typography>
  <Typography sx={{ fontSize: 13.5, fontWeight: 600, color: '#576369', ml:1.25 }}> Locations hours for the day </Typography>


  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
    <img src={CheckIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Delivery Confirmation</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Once your message has sent sucessfully, a green check mark will appear in the message log. This means that we have verifed that your notification has sent and has been delivered to your customer successfully. A red X or a question mark will be displayed if your message has failed to send, or is pending delivery. </Typography>

  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
    <img src={LogIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Reviewing Your Sent Notification</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> You can always review any and all of your sent notifications directly from the messages page. To view the entire notification contents, including any quotes, balances or custom messages you sent, simply click on the row containing the notification you wish to view.  </Typography>

</Card>
      </>
    ),
    'Managing Users and Locations': (
      <>
 <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",
            marginBottom: "1rem",overflowY: 'auto',height:'800px', }}>  
  <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Managing Users and Locations</Typography>
 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditUser} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Adding and Editing Users
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> For accounts that are setup to use multiple employees, you can simply add, edit or delete any users right from the account management section in the profile page. The account management panel is only visible to users that are account administrators. 
Upgrading your account from a single user to a multi user or multi location account is currently not supported in the closed beta program. If you need to upgrade your account, please send us an email and we can process the account change on our end. </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={LocationIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "2px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Adding and Editing Locations
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> For accounts that are setup to have multiple locations, you can simply add, edit or delete any locations right from the account management section in the profile page. The account management panel is only visible to users that are account administrators. 
Upgrading your account from a single location to a multi location account is currently not supported in the closed beta program. If you need to upgrade your account, please send us an email and we can process the account change on our end. </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={ProfileIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Assinging Employees to Locations
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Account administrators can assign employees to their location directly from the account management panel. If you have employees that work at multiple locations,  they  can easily change their active location on their end by clicking on the profile avatar in the top right hand corner of the page and select “Switch Location”. Your active location will be displayed to the left of the notification bell at all times.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={LockIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Changing Users Pin Numbers
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Account administrators can change employees login pin numbers straight from the account management panel. Pin numbers are numerical only, must contain at least 4 digits and have a maximum length of 6 digits.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Deleting Users and Locations
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Account administrators can delete users or locations from the account management panel. Deleted user names or location names will remain present in your notification history and can not be modified.  </Typography>

</Card>
      </>
    ),
    'Your Notification History': (
      <>
  <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",
            marginBottom: "1rem",height:'460px' }}>  
   <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Your Notification History</Typography>
 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={ReviewIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>View Notification Details
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Every notification your company sends can be viewed at anytime in the messages page. This gives you a general overview of each notification sent. To view the complete details of the entire notification, including any quotes, turnaround times or any custom messages that were included, simply click on the row containing the notification you need and a pop up will be displayed containing the complete history. </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={AddCustomer} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Add To Customers
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> You can add customers to your contact list from in the messages page. Simply find the notification containing the phone number of the customer you wish to add and click the add customer button on the right hand side of the page.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={CheckIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Delivery Statuses
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Once your notification has sent successfully, a green check mark will appear in the message log. This means that we have verified that your notification has sent and has been delivered to your customer successfully. A red X or a question mark will be displayed if your message has failed to send, or is pending delivery. </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Deleting or Editing Sent Notifications
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Once your notification has been sent, it cannot be deleted or edited  </Typography>
  
</Card>
      </>
    ),
    'Managing Your Contacts': (
      <>
  <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",
            marginBottom: "1rem",height:'460px' }}>  
   <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Manage Your Saved Customers</Typography>
 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={AddCustomer} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Adding A Customer
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> You can add customers to your contact list from in the messages page. Simply find the notification containing the phone number of the customer you wish to add and click the add customer button on the right hand side of the page. </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={EditUser} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Editing A Customer
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Once a customer is saved in your contact list, you can edit a customer by clicking the edit customer button. A new pop up will be displayed where you can edit your customers details, including their name and contact number.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={ProfileIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>View Customer Details
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> You can view your saved customers entire notification history by selecting the row containing the customer you wish to view. Once selected, your customers profile will appear that contains their contact information, along with their notification history from any location or user in your company.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={SendIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Sending a Quick Notification to a Customer
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> To send a quick notification to a customer that is already saved, click the new message button on the row containing the customer you wish to notify. The new notification dialog will appear with your customers phone number already automatically populated.  </Typography>

</Card>
      </>
    ),
    'Billing and Subscriptions': (
      <>
  <Card sx={{mt:2, border:'1px solid #576369',		boxShadow:
						'0 2px 4px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)',    border: "1px solid lightgray",   padding: "1rem",
            width: "100%",
            marginBottom: "1rem",height:'355px' }}>  
<Typography sx={{ fontSize: 17, fontWeight: 600, color: '#576369' }}>Billing and Subscription</Typography>
 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={BillIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>Make Changes To Your Plan
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Only billing administrators can make changes to your NanoNotify account. Your account may only contain one billing administrator. You can change your plan, add additional locations or employees straight from the billing section in the profile page.</Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={CardIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>View and Edit Payment Information
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> You can change your payment information or modify your subscription at anytime from a monthly to an annual plan by selecting the ‘Change Subscription Type” button in the billing page.  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
    <img src={InvoiceIcon} alt="detailsIcon" style={{ border: "1px solid #B0BEC5", width: "auto", height: "26px", backgroundColor: "#FFFFFF", borderRadius: "10px", padding: "3px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px", marginRight: '0.25rem' }} />
    <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#576369' }}>View Recent Payment History
</Typography>
  </Box>
  <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#576369', mt: .5, ml:1.25 }}> Your recent payment information will be displayed in the billing section. This includes the date, amount and the last 4 digits of the card used to process your payment.  </Typography>

  
</Card>
      </>
    ),
  };

  const [selectedTopic, setSelectedTopic] = useState('Getting Started');

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  return (
		<Box sx={{width: '100%', px: '14px',}}>
			<FullPageCard sx={{width: '100%', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '30%',   flexGrow: 1 }}>
        <Header
					sx={{
						padding: '15px',
						color: '#48768C',
						fontWeight: 700,
						fontSize: 17,
					}}>Help Center</Header>
				<Header
					sx={{padding: '15px', pt: 5, color: '#48768C', fontSize: '.9rem'}}>
        Get answers to our most common questions
        </Header>
        <Sidebar>
          {commonTopics.map((topic, index) => (
            <Box key={index} sx={{ mt: 0 }}>
              <NavButton variant="contained" onClick={() => handleTopicClick(topic)}>{topic}</NavButton>
            </Box>
          ))}
        </Sidebar>
        </Box>

        <Divider orientation="vertical" flexItem sx={{ mt: 8, mb: 1, border: '1px solid', color: '#C0CAD1', height:'80%' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '2%', width: '100%', height: '100%', flexGrow: 1 }}>
          {topicContent[selectedTopic]}
        </Box>
      </FullPageCard>
    </Box>
  );
};

export default HelpCenter;
