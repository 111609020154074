// NewUserMultiLocation.js

import React, { useState, useEffect } from 'react';
import FirstLocationPrompt from './FirstLocationPrompt';
import AddFirstLocationModal from './AddFirstLocationModal';
import FirstUserPrompt from './FirstUserPrompt';
import NewUserFromAuth0 from './NewUserFromAuth0';
import NewAccountSuccess from './NewAccountSuccess';

const NewUserMultiLocation = ({ onClose, onNext }) => {
  const [step, setStep] = useState(1);
  const [locationData, setLocationData] = useState(null);


  

  const handleAddLocation = (newLocationData) => {
    setLocationData(newLocationData);
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FirstLocationPrompt isOpen={true} onNext={handleNext} />;
      case 2:
        return <AddFirstLocationModal isOpen={true} onClose={() => {}} onAddLocation={handleAddLocation} onNext={handleNext} />;
      case 3:
        return <FirstUserPrompt isOpen={true} onNext={handleNext} />;
      case 4:
        return   <NewUserFromAuth0 isOpen={true} onClose={() => {}}  onSave={handleNext} onNext={handleNext} locationData={locationData}/>;
      case 5:
        return <NewAccountSuccess isOpen={true} onNext={handleNext} />;
      default:
        return null;
    }
  };





  useEffect(() => {
    if (step === 1) {
      renderStep(); // Start the flow when the component mounts
    }
  }, [step]);

  return (
    <>
      {renderStep()}
    </>
  );
};

export default NewUserMultiLocation;
