import React, {useState, useEffect} from 'react';
import { Typography, Button, TextField, Modal, Select, MenuItem, Box, Avatar, Dialog, DialogTitle, DialogContent, DialogActions,  FormControl,
  InputLabel, } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import userAccountDetailsIcon from "../../public/assets/icons/icons/userAccountDetails.png";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useAccountContext } from 'src/context/AccountContext';
import ChangePinModal from "../modal/ChangePinModal";
import { useAuth0 } from "@auth0/auth0-react";






const scaleFactor = 0.75;

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42 * scaleFactor,
    height: 27.5 * scaleFactor,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${16 * scaleFactor}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: `${6 * scaleFactor}px solid #fff`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22 * scaleFactor,
      height: 22 * scaleFactor,
    },
    "& .MuiSwitch-track": {
      borderRadius: (26 * scaleFactor) / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const ViewUsersModal = ({ isOpen, onClose, onSave, viewData, setViewData, locations }) => {
    const [title, setTitle] = useState(''); // Initial role
    const [isAdmin, setIsAdmin] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { accountData } = useAccountContext();
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [selectedUserForPinChange, setSelectedUserForPinChange] = useState(null);
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const { user, getAccessTokenSilently } = useAuth0();




  

  
 


  





  
  

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  
  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px", } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          padding: 2.5,

          width:'550px',
          display: "flex",
          alignItems: "center",
          height: "55px",
          backgroundColor: "#FFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
<i   style={{
            border: '1.25px solid rgba(173, 216, 230, 0.8)',
            fontSize: "18px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "4px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            color: "#7589A2",
          }} class="fa-regular fa-user"></i>          

      </DialogTitle>

      <DialogContent
        sx={{
          padding: 2,
          width: '100%',
          backgroundColor: "#FFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
          <Typography color="textSecondary" sx={{    
  fontSize:21 , mb:1.5, fontWeight:600, pl:.55}}>
          Employee Details{" "}
        </Typography>
        <Box sx={{ borderRadius:'20px',border: '1.25px solid rgba(173, 216, 230, 0.7)', py:2, width:'auto', borderRadius:'15px', mb:1.5}}>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'flex-start', width: '100%', ml:1,mb:'20px'}}>
      <img
          src={userDetailsIcon}
          alt="addUserIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft:'1px',
            width: "auto",
            height: "26px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />  <Typography sx={{ ml: .5, alignSelf: 'flex-end',  color:'#48768C', fontSize:16, fontWeight:600,  }}>Employee Information</Typography>
</Box>
    
<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', pr: 3, ml:1, mt:1 }}>

  {/* Transparent overlay to intercept clicks */}
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1, // Make sure the overlay sits above the TextField
      pointerEvents: 'auto', // Enable pointer events on the overlay to intercept clicks
    }}
    onClick={(e) => e.stopPropagation()} // Prevent clicks from reaching underlying elements
  />
  {/* Actual TextField */}
  <TextField
    label="First Name"
    variant="outlined"
    size="small"
    fullWidth
    value={viewData.first_name}
    sx={{ color: '#000', pb: 2, mr: 1, '& input': { width: '100%', textAlign: 'left' } }}
  />
        <TextField
          label="Last Name"
          variant="outlined"
          size="small"  
          fullWidth
          value={viewData.last_name}
          sx={{ color: '#000', pb: 2, '& input': { width: '100%', textAlign: 'left' } }}
          />
                       
                    </Box>
                    <FormControl variant="outlined" sx={{ width:'95.75%', mb:1, ml:'7.5px',          backgroundColor: "#FFFFF",
 }}>
  <TextField
    label="Role"
    size="small"
    value={viewData.title}
        sx={{          backgroundColor: "#FFFFF"
        , color: '#48768C',
    
      PaperProps: {
        style: {
          backgroundColor: '#FFFFFF',
        },
      },
    }}
  >
  </TextField>
</FormControl>

          </Box>

          <Box sx={{ borderRadius:'20px',border: '1.25px solid rgba(173, 216, 230, 0.7)', py:2, width:'100%', borderRadius:'15px',}}>

<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'flex-start', width: '100%', ml:1,mb:'20px'}}>
      <img
          src={userAccountDetailsIcon}
          alt="userAccountDetailsIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft:'1px',
            width: "auto",
            height: "26px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />  <Typography sx={{ ml: .5, alignSelf: 'flex-end',  color:'#48768C', fontSize:16.5, fontWeight:600, }}>Account Information</Typography>
</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '97%',ml:1,mb:2, mt:1 }}>
        <TextField
          label="Username"
          variant="outlined"
          size="small"

          fullWidth
          value={viewData.username}
          sx={{ mb: 0, width: '100%',}}
          
          />
          {!accountData.single_location && (
            <>
                      <Typography sx={{fontSize: 15,fontWeight:600, color:'#576369', mt:1, ml:.5}} >Assigned Location: </Typography>

  <TextField
    value={viewData.assigned_location}
    size="small"
    fullWidth
  >
  </TextField>





  </>
)}




          </Box>
        </Box>

        </DialogContent>
        <DialogActions
  sx={{
    pt: 1,
    backgroundColor: "#FFFFF",
    width: '100%',
    mx: 'auto', // Set left and right margin to auto to center horizontally
    my: 'auto',
    display: 'flex', // Use flexbox
    justifyContent: 'center', // Center content horizontally
  }}
>
       
  </DialogActions>
    </Dialog>
    
  );
};

export default ViewUsersModal;
