import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet-async';
import {useAppContext} from '../context/AppContext';
import {Grid, Container, Box, Paper, Typography, Stack} from '@mui/material';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import LoginModal from '../components/modal/LoginModal';
import {useAuth} from '../context/AuthContext';
import NewUserSignUp from 'src/components/modal/NewUserSignUp/NewUserSignUp'; // Add this import
import {AccountProvider} from '../context/AccountContext'; // Import AppProvider from AccountContext
import {useAccountContext} from '../context/AccountContext'; // Import useAccountContext from AccountContext
import {useSingleUser} from '../context/SingleUserContext'; // Import useSingleUserContext from SingleUserContext
import {useAuth0} from '@auth0/auth0-react';
import weeklyIcon from '../public/assets/icons/widgets/week.png';
import monthlyIcon from '../public/assets/icons/widgets/month.png';
import pendingIcon from '../public/assets/icons/widgets/pending.png';
import MobileWarningPopup from '../components/modal/MobileWarningPopup';
import  fetchGlobalNotifications  from '../utils/notificationUtils'; 






// components
import RecentActivity from '../widgets/feed/RecentActivity';
import MonthlyMessages from 'src/widgets/feed/ScheduledMessages';
import PendingApproval from 'src/widgets/feed/PendingApproval';

// sections
import { DashboardWidgets} from '../widgets';


function DashboardAppPage() {
	const [userData, setUserData] = useState(null);
	const [chartData, setChartData] = useState([]);
	const [percentageChange, setPercentageChange] = useState(0);
	const [newCustomersAdded, setNewCustomersAdded] = useState(0);
	const {user, isAuthenticated} = useAuth0();
	const {setAccessToken, login, username, subUserData} = useAuth();
	const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
	const [shouldShowNewUserSignUp, setShouldShowNewUserSignUp] = useState(true);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	

	const [isUserCreated, setIsUserCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(true); // Introduce loading state
	const {showNewUserSignUp, setShowNewUserSignUp} = useAccountContext();
	const {reloadComponents} = useAppContext();
	const {fetchLatestSingleUserData} = useSingleUser(); // Import the fetchLatestSingleUserData function
	const [showCustomerFeed, setShowCustomerFeed] = useState(true);
	const [showMonthlyMessages, setShowMonthlyMessages] = useState(false);
	const [showPendingApproval, setShowPendingApproval] = useState(false);
	const [shouldShowLoginModal, setShouldShowLoginModal] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	



	const {accountData} = useAccountContext();

	const apiUrl = process.env.REACT_APP_API_URL;



	useEffect(() => {
		const handleResize = () => {
		  if (window.innerWidth <= 768) { // Adjust the width as needed for your definition of "mobile"
			setShowPopup(true);
		  } else {
			setShowPopup(false);
		  }
		};
	
		window.addEventListener('resize', handleResize);
		handleResize(); // Call it once on mount to check initial width
		return () => window.removeEventListener('resize', handleResize);
	  }, []);




	useEffect(() => {
		// Check if accountData is available and data has been fetched
		if (accountData && !isLoading) {
			// Priority one: Check if the account is single user and there is no username
			if (accountData.single_user && !username) {
				fetchLatestSingleUserData(); // Invoke the fetchLatestSingleUserData function here
			} else {
				// Priority two: Render login modal if not a single user AND there is NO username
				if (!accountData.single_user && !username) {
					setShouldShowLoginModal(true);
				}
			}
		}
	}, [accountData, username, fetchLatestSingleUserData, isLoading]);



	useEffect(() => {
		// Check if accountData is available and data has been fetched
		if (accountData && !isLoading) {
			// Priority one: Check if the account is single user and there is no username
			if (accountData.single_user ) {
				fetchLatestSingleUserData(); // Invoke the fetchLatestSingleUserData function here
			} 
		}
	}, [accountData, username, fetchLatestSingleUserData, isLoading]);





	useEffect(() => {
		// If accountData is fetched and set, update loading state to false
		if (accountData) {
			setIsLoading(false);
		}
	}, [accountData]);

	const handleReloadComponents = () => {
		reloadComponents(); // Trigger the reload
	};

	const handleLogin = (username, pin) => {
		setIsUserCreated(true); // Assuming that the user is successfully created upon login
		setIsLoginModalOpen(false);
		setIsUserCreated(true);
		setAccessToken(username);
		login(username);
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden'; // Disable scrollbar
		return () => {
			document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
		};
	}, []);

	return (
		<AccountProvider>
		  <>
			<Helmet>
			  <title>NanoNotify.io</title>
			</Helmet>
	  
			<Box sx={{ width: '100%', justifyContent:'space-between', marginTop:"-1rem", height: '90vh', overflowY:'hidden' }}>
				<Box sx={{marginBottom:1}}>
					
			  <Grid container spacing={.75} sx={{width:'100', }} > 
				<Grid item xs={12} sm={6} md={4}>
				  <DashboardWidgets
				    selected={showCustomerFeed}

					sx={{ color: '#2A3D4F', }}
					title="Your Recent Notifications"
					total={0}
					fetchData={true}
					icon={<img src={weeklyIcon} alt="weekly icon" style={{ }} />}
					onClick={() => {
					  setShowCustomerFeed(true);
					  setShowMonthlyMessages(false);
					  setShowPendingApproval(false);
					}}
				  />
				</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={4}>
							<DashboardWidgets
							  selected={showMonthlyMessages}

								title="Notifications Scheduled For Delivery"
								total={0}
								fetchData={true}
								color="info"
								icon={
									<img
										src={monthlyIcon}
										alt="weekly icon"
									/>
								} // Pass the image as a JSX element
								onClick={() => {
									setShowCustomerFeed(false);
									setShowMonthlyMessages(true);
									setShowPendingApproval(false);
								}}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={4}>
							<DashboardWidgets
							  selected={showPendingApproval}

								title="Notifications Pending Approval"
								total={newCustomersAdded}
								fetchData={true}
								color="warning"
								icon={
									<img
										src={pendingIcon}
										alt="weekly icon"
									/>
								} // Pass the image as a JSX element
								onClick={() => {
									setShowCustomerFeed(false);
									setShowMonthlyMessages(false);
									setShowPendingApproval(true);
								}}
								sx={{width:'100%'}}
							/>
						</Grid>
						</Grid>

						</Box>


  <Box sx={{  width:'100%', }}>
    {showCustomerFeed && <RecentActivity  />}
    {showMonthlyMessages && <MonthlyMessages />}
    {showPendingApproval && <PendingApproval />}
  </Box>
					{showPopup && <MobileWarningPopup />}

					</Box>

				{showNewUserSignUp ||
				(!accountData && shouldShowLoginModal) ||
				(!accountData?.completed_setup && shouldShowLoginModal) ? (
					<NewUserSignUp onClose={() => setShowNewUserSignUp(false)} />
				) : (
					// Render the login modal only if showNewUserSignUp is false and shouldShowLoginModal is true
					shouldShowLoginModal &&
					!showNewUserSignUp && (
						<LoginModal
							open={shouldShowLoginModal}
							onClose={() => setShouldShowLoginModal(false)}
							onLogin={handleLogin}
							userData={user}
							accountData={accountData}
						/>
					)
				)}
			</>
			
		</AccountProvider>
	);
}

const ProtectedRoute = withAuthenticationRequired(DashboardAppPage, {
	onRedirecting: () => <div>Redirecting you to the login page...</div>,
});

export default ProtectedRoute;
