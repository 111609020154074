// SharedStyledModal.js
import { styled } from '@mui/system';
import { Modal } from '@mui/material';

export const SharedStyledModal = styled(Modal)({
  '& .MuiPaper-root': {
    width: '80%',
    height: 'auto',
    maxWidth: '400px',
    backgroundColor: 'white',
    border: '1px solid #000',
    borderRadius: '12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    p: 3,
  },
  '& .MuiBackdrop-root': {
    opacity: 0.8,
    backdropFilter: 'blur(5px)',
  },
});
