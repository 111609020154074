import React, { useEffect, useState } from 'react';
import {
  Typography,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Modal,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  

} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import editCustomerIcon from "../../public/assets/icons/icons/editCustomer.png";
import DeleteCustomerConfirmation from './DeleteCustomerConfirmation';
import { is } from 'date-fns/locale';
import { useAppContext } from 'src/context/AppContext';
import {useCustomersContext} from '../../context/CustomersContext'; // Import the useCustomersContext hook	







const CustomerEditModal = ({
  open,
  onClose,
  customer,
  onUpdate,
  onDelete,
}) => {
  const [localCustomer, setLocalCustomer] = useState(null);
  const [isDeleteDialogOpen,setIsDeleteDialogOpen,] = useState(false);
  const { loading } = useCustomersContext();


  useEffect(() => {
    if (customer) {
      setLocalCustomer({ ...customer });
    } else {
      setLocalCustomer(null);
    }
  }, [customer]);

  useEffect(() => {
    if (!open) {
      setIsDeleteDialogOpen(false); // Reset delete dialog state when modal is closed
    }
  }, [open]);

  const handleUpdateCustomer = async () => {
   await onUpdate(localCustomer);
    onClose();
  };

  const getInitials = () => {
    if (localCustomer && localCustomer.first_name && localCustomer.last_name) {
      const firstNameInitial = localCustomer.first_name[0];
      const lastNameInitial = localCustomer.last_name[0];
      return `${firstNameInitial}${lastNameInitial}`;
    }
    return '';
  };




  

  return (
    <Dialog 
    open={open} onClose={onClose}
    fullWidth={true} // Make the dialog take up the full width
    PaperProps={{ sx: { borderRadius: "20px", } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={editCustomerIcon}
          alt="editCustomerIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft:'7px',
            width: "auto",
            height: "31px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />

      </DialogTitle>
	
      <DialogContent
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          padding: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >

<Typography  sx={{  color: "#48768C" , fontSize:'21px', mb:2, fontWeight:600}}>
          Edit Customer{" "}
        </Typography>
        <Box sx={{ mb:2,                   border: '1.25px solid rgba(173, 216, 230, 0.7)',
 borderRadius: "15px",backgroundColor: 'rgba(191, 218, 231, 0.1)', boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)", borderRadius: "6px",
 }}> 

      
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
<Box sx={{ flexGrow: 1, }} />
  <IconButton         onClick={() => { setIsDeleteDialogOpen(true);  }}
>
    <DeleteForeverOutlinedIcon
      sx={{
        border: '1px solid #B0BEC5',
        padding: '2px',
        backgroundColor: 'white',
        borderRadius: '6px',
        color: '#D59EA8',
        fontSize: '33px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.09)',
      }}
    />
  </IconButton>
</Box>
<Box sx={{ display: 'flex',flexDirection: 'row', alignItems: 'center', width: '100%', mb:1 }}>



</Box>
<Avatar
    sx={{
      ml: 2,
      backgroundColor: 'rgba(8, 19, 42, 0.7)',
      width: 55,
      height: 55,
      mb: 2,
      mr: 2,
      mt:-5,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Typography sx={{ fontSize: '22px' }}>{getInitials()}</Typography>
  </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', mb: 1,              px:2,
 }}>


   
          <TextField
            placeholder="First Name"
            variant="standard"  
            helperText="First Name"
            fullWidth
            value={localCustomer ? localCustomer.first_name || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                first_name: e.target.value || '',
              }))
            }
            sx={{
              ml:1,
              color: '#48768C',
              pb: 2,
              mr: 2,
              '& input': {
                width: '100%',
                textAlign: 'left',
                color: '#48768C',

              },
            }}
          />

          <TextField
            placeholder="Last Name"
            variant="standard"  
            helperText="Last Name"
            fullWidth
            value={localCustomer ? localCustomer.last_name || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                last_name: e.target.value || '',
              }))
            }
            sx={{
              color: '#48768C',
              pb: 2,
              '& input': {
                width: '100%',
                textAlign: 'left',
                color: '#48768C',

              },
            }}
          />
</Box>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2,              px:2,}}>

          <TextField
            placeholder="Phone Number"
            variant="standard"  
            helperText="Phone Number"            fullWidth
            value={localCustomer ? localCustomer.phone_number || '' : ''}
            onChange={(e) =>
              setLocalCustomer((prev) => ({
                ...prev,
                phone_number: e.target.value || '',
              }))
            }
            sx={{
              ml:1,

              color: '#48768C',
              '& input': {
                color: '#48768C',

                width: '100%',
                textAlign: 'left',
              },
            }}
          />
        </Box>
        </Box>


        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}>


          <Button
            disabled={loading || !localCustomer?.first_name || !localCustomer?.last_name || !localCustomer?.phone_number}
            variant="contained"
          sx={{
            backgroundColor: '#46A793',
            color: '#FFFFFF',
            mb:2,
            borderRadius: '8px',
            fontWeight: 600,
            letterSpacing: '0.02em',  
            padding: '12px 24px', // Larger padding
            fontSize: '18px',
            width: '55%',
            '&:hover': {
              backgroundColor: '#079E80',
            },
          }}

          onClick={handleUpdateCustomer}

        >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
        </Box>
        </DialogContent>
        <DeleteCustomerConfirmation
        open={isDeleteDialogOpen}
        onClose={() => {setIsDeleteDialogOpen(false);}}

    
        onConfirm={onDelete}
        itemName={localCustomer ? `${localCustomer.first_name} ${localCustomer.last_name}` : ""}
        />
    </Dialog>
  );
};

export default CustomerEditModal;
