import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Avatar,
  Box,
  TextField,
  CircularProgress,
  LinearProgress,
  Paper
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import bellIcon from "../../public/assets/icons/messageModal/bell.png";
import { useAccountContext } from "../../context/AccountContext"; // Import useAccountContext from AccountContext



  



const PreviewDialogTypography = ({ children, color }) => (
    <Typography
    color="textSecondary"
      sx={{
        color:'#48768C',fontSize: "15px",fontWeight:600
      }}
    >
      {children}
    </Typography>
  );



function PreviewNotification({ open, 
    onClose,
    handleClose,
    companyName,
    phoneNumber,
    ticketNumber,
    requiredFieldValues,
    additionalMessage,
    title,
messagePrefix,
contextMessage,
optionalMessagePrefix,
optionalFieldValues,
currentDayOfWeek,
currentHours,
formattedLocationPhone,
locationEmail,
companyWebsite,
userCurrentDate,
defaultMessage,
twoWayEnabled
}) {

    const {value} = requiredFieldValues;



    return (
    <Dialog open={open} onClose={onClose} 
    PaperProps={{ sx: { borderRadius: "19px",border: '1px solid rgba(173, 216, 230, 0.5)' } }}

    >
        <Box>
 <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 5,
          color: "#576369",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "8px",
          width: "32px",
          height: "32px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      
<Box
  sx={{
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color:'#576369',
    padding: '20px',
    borderRadius: '15px',
    
    width: '100%',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    
  }}
>

  



<Box sx={{display:'flex',flexDirection:'column', pl:.5, pr:.5, pb:1}}>

          <Typography variant="h6"    sx={{display:'flex',color: "#48768C" , fontWeight:600, justifyContent:'left', }}>
          Preview Notification{" "}
        </Typography>
        <Typography variant="body2"   sx={{display:'flex',color: "#48768C" ,mb:1, fontWeight:600, justifyContent:'left',mb:1      
}}>
          Your customer will receive the following notification{" "}
        </Typography>

        </Box>

<Paper elevation={5} sx={{borderRadius:'15px'}} >
        <Box
  sx={{
    position: 'relative',

    color:'#576369',
    padding: '10px',
    borderRadius: '15px',
    border: '1px solid #B0BEC5',
    width: '100%',
    backgroundColor: 'rgba(191, 218, 231, 0.08)',
    border: '1px solid rgba(173, 216, 230, 0.7)',    
  }}
>
  <PreviewDialogTypography> {companyName} Status Update</PreviewDialogTypography>
  <PreviewDialogTypography>{`Ticket Number: ${ticketNumber}`}</PreviewDialogTypography>
  
  <PreviewDialogTypography>
    {`Status: ${
       title 
    }`}    </PreviewDialogTypography>  

      {contextMessage && (
        <Box sx={{mt:1, mb:1,}} >
  <PreviewDialogTypography sx={{ color:'#576369', fontWeight:600}}>{contextMessage}</PreviewDialogTypography>
  </Box>
  )}
{Object.entries(requiredFieldValues).map(([title, value]) => (
  <Box key={title}>
    {requiredFieldValues && (
              <Box sx={{mt:1, mb:1,}} >

      <PreviewDialogTypography sx={{ mt: 1, mb: 1, color: '#576369', fontWeight: 600 }}>{`${ messagePrefix || title }: ${value}`} </PreviewDialogTypography>
      </Box>
    )}
  </Box>
))}
{Object.entries(optionalFieldValues).map(([title, optionalValue]) => (
  <PreviewDialogTypography key={title}>
    {optionalValue && (
      <PreviewDialogTypography sx={{ mt: 1, mb: 1, color: '#576369', fontWeight: 600 }}>{`${optionalMessagePrefix || title}: ${optionalValue}`} </PreviewDialogTypography>
    )}
  </PreviewDialogTypography>
))}
  {defaultMessage && (
                  <Box sx={{mt:1, mb:1,}} >

    <PreviewDialogTypography sx={{color:'#576369', fontWeight:600}}>
   Additional Message:{defaultMessage}</PreviewDialogTypography>
   </Box>
  )}
  
  <Box sx={{mt:1, mb:1,}} >

  {!twoWayEnabled && (
  <PreviewDialogTypography sx={{color:'#576369',fontWeight:600}} >
    This number is for automated alerts only and cannot receive replies. If
    you have any questions or need assistance, please call or email us
    directly.
  </PreviewDialogTypography>
  )}  



{twoWayEnabled && (
  <PreviewDialogTypography sx={{color:'#576369',fontWeight:600}} >
You can let us know by responding "Yes" or "No" to this message, or by contacting us directly.
  </PreviewDialogTypography>
  )}  
  
  
  
  </Box>
  <PreviewDialogTypography>Contact Us: {formattedLocationPhone}</PreviewDialogTypography>
  <PreviewDialogTypography>
    {locationEmail} | {companyWebsite}
  </PreviewDialogTypography>
  <Box sx={{mt:1, mb:1,}} >

  <PreviewDialogTypography sx={{color:'#576369',fontWeight:600}}>
    {/*}
  {isScheduled ? (

    <>
  {`Business Hours For: ${userCurrentDate}`}:&nbsp;

                  
        ${userCurrentDate}`
    </>
  ) : (
    // Display current day hours otherwise
    <>
  */}
      Business Hours for {currentDayOfWeek} {userCurrentDate}:&nbsp;
        {currentHours}
     
</PreviewDialogTypography>
</Box>
</Box>
</Paper>
</Box>


    </Box>
    </Dialog>
  );  
    }
    
    export default PreviewNotification;