import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from 'src/theme/overrides/Typography';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';



export const CustomSnackbar = ({ open, message, success, onClose }) => {

  // Determine the severity based on the success prop
  const severity = success ? 'success' : 'error';


  // If no message is provided, use a default message based on success
  const alertMessage = message || (success ? 'Operation successful' : 'Operation failed');

  const alertIcon = success ? <CheckCircleTwoToneIcon fontSize="20px" /> : <ErrorTwoToneIcon sx={{color:'#8C4350', fontSize:'24px'}}  />;




  const alertStyles = {
    background: success ? 'rgba(202, 238, 231, 0.8)' : 'rgba(221, 199, 203, 0.8)', // Example background colors with 80% opacity
    color: success ? 'green' : '#8C4350',
    border: success ? '.5px solid #27A88E' : '1px solid #C0989F', 
    borderRadius:  '10px', // Example border radius
    lineHeight: '-2%',
    fontWeight: 700,
    padding:1,
    letterSpacing: '0.2px', 
    fontFamily: 'Source Sans Pro, sans-serif',   
    fontSize: '14px',
    alignItems: 'center',

    
    
  };



  return (
    <Snackbar
      open={open}
      autoHideDuration={2000} // Adjust the duration based on your preference
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Center the Snackbar
      sx={{
        '& .MuiAlert-action button': { // Target the button element inside .MuiAlert-action
          display: 'none' // Hide the button (close icon)
        },
        alignItems: 'center' // Center the content  
      }}
    >
      
      <Alert icon={alertIcon} severity={severity} sx={alertStyles} onClose={onClose} >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
