import React from 'react';

const GenerateColor = ({ selectedNotifications, notificationType }) => {
  // Get the notification data for the specified type
  const notificationData = selectedNotifications[0]?.selected_data[notificationType];

  // Check if notificationData exists and has the Configs object
  if (notificationData && notificationData.Configs && notificationData.Configs.Color) {

    const color = notificationData.Configs.Color;

    // Generate additional colors based on the provided color
    let cardBackgroundColor, borderColor, fieldFontTextColor, iconBackgroundColor, titleColor, containerBackgroundColor, buttonColor, hoverColor;


      switch (color) {
        case '#2BBB9E':
            titleColor = '#2BBB9E'; 
            cardBackgroundColor = '#DFF5F0';
            containerBackgroundColor = '#FFFFFF';
            borderColor = '#2BBB9E';
            fieldFontTextColor = '#747C86';
            iconBackgroundColor = '#BFEBE2';
            hoverColor = '#CAEEE7';
  
            break;
            //parts arrived
          case '#7589A2':
            titleColor = '#7589A2'; 
            cardBackgroundColor = '#d4dae3';
            containerBackgroundColor = '#FFFFFF';
  
            borderColor = '#7589A2';
            fieldFontTextColor = '#747C86';
            iconBackgroundColor = '#7589A2';
            hoverColor = '#D5DBE3';
            buttonColor = '#7589a2';  
            break;
            //pending approval
          case '#a1848f':
            titleColor = '#a1848f'; 
            cardBackgroundColor = '#F1EDEE';
            containerBackgroundColor = '#FFFFFF';
  
            borderColor = '#8C4350';
            fieldFontTextColor = '#747C86';
            iconBackgroundColor = '#FFFFFF';
            hoverColor = '#E3DADD';
  
            break;
            //action needed
            case '#8C4350':
              titleColor = '#8C4350'; 
              cardBackgroundColor = '#F9F5F6';
              containerBackgroundColor = '#FFFFFF';
  
              borderColor = '#8C4350';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              hoverColor = '#E2D0D3';
            break;
           
            // Palette 1
            case '#7D7371':
              titleColor = '#7D7371'; 
              cardBackgroundColor = '#F0E7E6';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#7D7371';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#7D7371';
              break;
            // Palette 2
            case '#A9A877':
              titleColor = '#A9A877'; 
              cardBackgroundColor = '#F2F2E5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A9A877';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A9A877';
              break;
            // Palette 3
            case '#7F8487':
              titleColor = '#7F8487'; 
              cardBackgroundColor = '#E9EAEA';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#7F8487';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 4
            case '#B5AD8E':
              titleColor = '#B5AD8E'; 
              cardBackgroundColor = '#F3F2E5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#B5AD8E';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#B5AD8E';
              break;
            // Palette 5
            case '#827B76':
              titleColor = '#827B76'; 
              cardBackgroundColor = '#EAE7E5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#827B76';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 6
            case '#A3847D':
              titleColor = '#A3847D'; 
              cardBackgroundColor = '#F1E8E7';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A3847D';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A3847D';
              break;
            // Palette 7
            case '#858BA9':
              titleColor = '#858BA9'; 
              cardBackgroundColor = '#EAEAF1';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#858BA9';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 8
            case '#A89A8C':
              titleColor = '#A89A8C'; 
              cardBackgroundColor = '#F0E9E3';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A89A8C';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A89A8C';
              break;
            // Palette 9
            case '#808A7C':
              titleColor = '#808A7C'; 
              cardBackgroundColor = '#E7ECE5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#808A7C';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 10
            case '#9F7F75':
              titleColor = '#9F7F75'; 
              cardBackgroundColor = '#EEE5E3';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#9F7F75';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#9F7F75';
              break;
            // Palette 11
            case '#827D89':
              titleColor = '#827D89'; 
              cardBackgroundColor = '#E7E6EB';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#827D89';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 13
            case '#7D8585':
              titleColor = '#7D8585'; 
              cardBackgroundColor = '#E6EAEA';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#7D8585';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 14
            case '#A97D75':
              titleColor = '#A97D75'; 
              cardBackgroundColor = '#F2E7E5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A97D75';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A97D75';
              break;
            // Palette 15
            case '#828CA0':
              titleColor = '#828CA0'; 
              cardBackgroundColor = '#E6E9F0';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#828CA0';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 16
            case '#A38D8A':
              titleColor = '#A38D8A'; 
              cardBackgroundColor = '#EDE6E5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A38D8A';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A38D8A';
              break;
            // Palette 17
            case '#7F8285':
              titleColor = '#7F8285'; 
              cardBackgroundColor = '#E9E9EA';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#7F8285';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#FFFFFF';
              break;
            // Palette 18
            case '#A6A077':
              titleColor = '#A6A077'; 
              cardBackgroundColor = '#EDEDE5';
              containerBackgroundColor = '#FFFFFF';
              borderColor = '#A6A077';
              fieldFontTextColor = '#747C86';
              iconBackgroundColor = '#A6A077';
              break;
        default:
          // Default colors if color doesn't match any case
          cardBackgroundColor = '#FFFFFF';
          borderColor = '#000000';
          fieldFontTextColor = '#000000';
          iconBackgroundColor = '#FFFFFF';
      }
      return { cardBackgroundColor, borderColor, fieldFontTextColor, iconBackgroundColor, titleColor, containerBackgroundColor,buttonColor, hoverColor }; 
    }
  };


  export default GenerateColor;
