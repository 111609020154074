import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
// ----------------------------------------------------------------------

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.css';


const root = createRoot(document.getElementById('root'));


root.render(
<Auth0Provider
  domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL}
  clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
  authorizationParams={{
    audience: 'secure/endpoints',
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI 
  }}
>
  <App />
</Auth0Provider>


    );

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
