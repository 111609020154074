import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import EditHoursModal from './EditHoursModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../context/AuthContext';

function EditHoursModalWrapper() {

  const { isAuthenticated } = useAuth0();
  const { username,subUserData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation dialog
  const [modalKey, setModalKey] = useState(0); // Add state for modal key

  // Function to handle updating hours and showing the confirmation dialog
  const handleUpdateHours = (editedHours) => {
    setShowConfirmation(true); // Show confirmation dialog instead of closing the modal immediately
  };

  // Function to handle closing both the modal and confirmation dialog
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    setIsOpen(false);
    setModalKey((prevKey) => prevKey + 1); // Increment modal key to force re-render
    navigate(-1); // Navigate back to the previous page
  };

  // Effect to open the modal when the component mounts if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      setIsOpen(true);
    }
  }, [isAuthenticated]);

  // Render the EditHoursModal component and confirmation dialog conditionally
  return isAuthenticated && isOpen ? (
    <>
      <EditHoursModal key={modalKey} isOpen={isOpen} onClose={() => setIsOpen(false)} onUpdateHours={handleUpdateHours} />
      {showConfirmation && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)' }}>
          Your hours have been saved!
          <button onClick={handleConfirmationClose}>OK</button>
        </div>
      )}
    </>
  ) : null;
}

export default EditHoursModalWrapper;
