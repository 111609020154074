import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute'; // Import the ProtectedRoute component

import DashboardLayout from './layouts';
import LoginPage from './pages/LoginPage';
import DashboardAppPage from './pages/DashboardAppPage';
import adminPanel from './pages/AdminPanel';
import CustomersPage from './pages/CustomersPage';
import Messages from './pages/Messages';
import Page404 from './pages/Page404';
import Reports from './pages/Reports';
import AccountPage from './pages/AccountPage';
import Signup from './pages/Signup';
import Profile from './components/AccountPage/Sections/Profile';
import AccountManagement from './components/AccountPage/Sections/AccountManagement';
import UserNotifications from './components/AccountPage/Sections/UserNotifications/UserNotifications';
import Notifications from './components/AccountPage/Sections/InAppNotifications/Notifications';
import Billing from './components/AccountPage/Sections/Billing';
import ExportData from './components/AccountPage/Sections/ExportData';
import HelpCenter from './pages/HelpCenter'; // Import the HelpCenterPage component
import EditHoursModalWrapper from './components/modal/EditHoursModalWrapper';







export default function Router() {
  return (
    <Routes>
      <Route index element={<Navigate to="dashboard/app" replace />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="app" element={<ProtectedRoute component={DashboardAppPage} />} /> {/* Protect the "app" route */}
        <Route path="CustomersPage" element={<ProtectedRoute component={CustomersPage} />} />
        <Route path="Messages" element={<ProtectedRoute component={Messages} />} />
        <Route path="Reports" element={<ProtectedRoute component={Reports} />} />
        <Route path="help-center" element={<ProtectedRoute component={HelpCenter} />} />
        <Route path="adminPanel" element={<ProtectedRoute component={adminPanel} />}>
        </Route>
        <Route path="Account/*" element={<ProtectedRoute component={AccountPage} />}>
          <Route index element={<Navigate to="Profile" replace />} />
          <Route path="Profile" element={<ProtectedRoute component={Profile} />} /> {/* Protect the "Profile" route */}
          <Route path="AccountManagement" element={<AccountManagement />} />
          <Route path="UserNotifications" element={<UserNotifications />} />  
          <Route path="Notifications" element={<Notifications />} />
          <Route path="Billing" element={<Billing />} />
          <Route path="ExportData" element={<ExportData />} />

        </Route>

      </Route>
      <Route path="/edit-hours" element={<EditHoursModalWrapper />} />

      <Route path="*" element={<Page404 />} /> {/* The 404 route */}
    </Routes>
  );
}
