// ChangePinModal.js

import React, {useEffect, useState} from 'react';
import { Modal, Typography, TextField, Button, Box,IconButton,InputAdornment, Dialog, DialogTitle,DialogContent,DialogContentText,DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useAuth} from '../../../context/AuthContext';
import {useAuth0} from '@auth0/auth0-react';
import { Link, useHistory,useNavigate } from 'react-router-dom'; // Import useHistory hook









const ChangePinModal = ({ isOpen, onClose,  userName }) => {
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const {subUserData, resetContext} = useAuth();
  const {getAccessTokenSilently, user} = useAuth0();
  const [editData, setEditData] = useState();
  const [selectedUserForEdit, setSelectedUserForEdit] = useState();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // New state for success dialog
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false); // New state for saving







  const handleChangePinSave = async ({ newPin, confirmNewPin }) => {
    try {
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        setIsSaving(true); // Start saving

        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();
        

  
        // Update user PIN
        const response = await fetch(
          `${apiUrl}/api/users/${subUserData.user_id}/change-pin`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ newPin: newPin }), // Correctly format the body
          },
        );
  
        const responseData = await response.json();

        if (response.ok) {
          setShowSuccessDialog(true); // Show success dialog
        } else {
          console.error("Failed to change PIN:", responseData.error);
        }
      } else {
        console.error("Entered PINs do not match");
      }
    } catch (error) {
      console.error("Error changing PIN:", error);
    } finally {
      setIsSaving(false); // Stop saving
    }
  };
  



  const handleSwitchUser = () => {
    resetContext(); // Reset the context to clear user state
    setShowSuccessDialog(false);
  
    // Get the navigate function from useNavigate hook
  
    // Navigate to the dashboard app page
    navigate('/dashboard/app');
  };

  const handleClose = () => {
    setNewPin('');
    setConfirmNewPin('');
    onClose();
  };

  useEffect(() => {
    const isValidInput =
      newPin.length === 4 &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(newPin) &&
      /^\d+$/.test(confirmNewPin) &&
      newPin === confirmNewPin;

    setSaveButtonEnabled(isValidInput);
  }, [newPin, confirmNewPin]);

  const handleNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '').substr(0, 4);
    setNewPin(inputValue);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '').substr(0, 4);
    setConfirmNewPin(inputValue);
  };



  const toggleShowNewPin = () => {
    setShowNewPin((prev) => !prev);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    setNewPin('');
    setConfirmNewPin('');
    onClose(); // Close the modal after success dialog is closed
  };

  return (
    <>

    <Modal open={isOpen} onClose={handleClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
     padding:1,
     width:'17%',
               backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 3,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
        </IconButton>
        <Typography
          gutterBottom
          sx={{
            color: '#48768C',
            mb: 1,
            mt: 3,
            textAlign: 'center',
            padding: '15px',
            borderRadius: '8px',
            fontSize: 17,
            fontWeight: 600,
            letterSpacing: '-2%',
          }}
        >
          Change Your Pin
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
        <TextField
          placeholder="New 4 Digit PIN"
size="small"
          variant="outlined"
          type={showNewPin ? 'text' : 'password'}
          sx={{
            color: '#48768C',
            '& input': {
              width: '75%',
              textAlign: 'left',
              color: '#48768C',
            },
            mb: 2,
          }}
          value={newPin}
          onChange={handleNewPinChange}
          InputProps={{ style: { color: '#48768C',borderRadius:'10px' }, 
            endAdornment: (
              <InputAdornment position="end">
                <IconButton  onClick={toggleShowNewPin} edge="end">
                  {showNewPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          placeholder="Confirm PIN"
          variant="outlined"
          type={showConfirmNewPin ? 'text' : 'password'}
          size="small"

          value={confirmNewPin}
          onChange={handleConfirmNewPinChange}
          
          sx={{
            
            borderRadius: '10px',

            color: '#48768C',
            '& input': {
              borderRadius: 'px',
              width: '75%',
              textAlign: 'left',
              color: '#48768C',
            },
            mb: 2,
          }}
          InputProps={{ style: { color: '#48768C',borderRadius:'10px' }, 


            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowConfirmNewPin} edge="end">
                  {showConfirmNewPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          <Button
            variant="contained"
            sx={{
              color: '#F0F0F0',
              backgroundColor: '#46A793',
              mt: 1,
              mb: 3,
              fontSize: '1rem',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.)',
              transition: 'background-color 0.3s ease',
              width: '50%',
              '&:hover': {
                backgroundColor: '#079E80',
              },
              '&:active': {
                backgroundColor: '#2E7D32',
              },
            }}
            onClick={() => handleChangePinSave({ newPin, confirmNewPin })}  // Pass newPin and confirmNewPin here
            disabled={!isSaveButtonEnabled || isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Box>

    </Modal>
    <Dialog 
        PaperProps={{ sx: { borderRadius: "15px", } }}

    open={showSuccessDialog} onClose={handleSuccessDialogClose}>
    <DialogContent>
      <Typography sx={{fontSize:13.5,mt:1, 
fontWeight: 600,color: '#48768C'}} >{userName}'s pin has been changed successfully!</Typography>
    </DialogContent>
    <DialogActions
    sx={{ display:'flex',justifyContent:'center', mb:1 , mt:-1,  }}
    >
      <Button sx={{border: '1px solid #B0BEC5', color:'rgba(72, 118, 140, 0.8)',boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',fontWeight:600, height:'30px'}} onClick={handleSuccessDialogClose}>OK</Button>
    </DialogActions>
  </Dialog>

  <Dialog
        PaperProps={{ sx: { borderRadius: "15px", backgroundColor:'#FFFFFF' } }}
        open={showSuccessDialog}
        onClose={handleSuccessDialogClose}
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: 14.5,
              mt: 1,
              fontWeight: 600,
              color: '#48768C',
              lineSpacing:'-2%', letterSpacing:'-2%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Your pin has been changed successfully! 
          </Typography>

          <Typography
            sx={{
              fontSize: 13.5,
              mt: 1,
              fontWeight: 600,
              color: '#48768C'
            }}
          >
            You will now be redirected to the login page where you can sign in using your new pin.  
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: -1 }}
        >
          <Button
            sx={{
              border: '1px solid #B0BEC5',
              color: 'rgba(72, 118, 140, 0.8)',
              width: 30,
              borderRadius: 1.5,
              fontWeight: 400,
              fontSize: 15,
              textTransform: 'none',
              px: 1,
              py: 0,
            }}
            onClick={handleSwitchUser}
            autoFocus
          >
           <b> OK </b>
          </Button>
        </DialogActions>
      </Dialog>
</>
  );
};

export default ChangePinModal