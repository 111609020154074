import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography, LinearProgress, IconButton, Box, TablePagination } from '@mui/material'; // Import necessary MUI components
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import timerIcon from "../../../public/assets/icons/icons/timer.png";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAdd';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import MessageDetails from '../../modal/MessageDetails';
import { useAccountContext } from '../../../context/AccountContext'; // Import AppProvider from AccountContext
import SaveIcon from "../../../public/assets/icons/icons/save.png";
import StatusTags from '../../color-utils/statusTags';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';






const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ''; // Return empty string if phoneNumber is null or undefined
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber; // Return original phoneNumber if no match
};

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${month}-${day}-${year}`;
};

const formatTime = (timeStr) => {
  const time = new Date(timeStr);
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  return time.toLocaleTimeString(undefined, options);
};





const StatusIcon = ({ status }) => {
  let icon;
  let color;

  switch (status) {
    case null:
      icon = <HelpOutlineIcon
       />;
      color = '#7589A2'; // Blue color
      break;
    case 'Sent':
      icon = <CheckCircleIcon />;
      color = '#2BBB9E'; // Light green color
      break;
      case 'queued':
        icon = <CheckCircleIcon />;
        color = '#2BBB9E'; // Light green color
        break;
    case 'Failed':
      icon = <CancelPresentationIcon />;
      color = '#8C4350'; // Red color
      break;
      case 'Scheduled':
        icon = <AlarmOnIcon />;
        color = '#5B5B62'; // Red color
        break;
    default:
      icon = null;
  }

  return icon ? <span style={{ color }}>{icon}</span> : null;
};




 function MessagesFeedTable({ messagesFeedData, loading, selectedStatus, searchPhoneNumber, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleOpenMessageDetails, setHoveredRowIndex, handleOpenModal, setSelectedPhoneNumber, isMessageDetailsOpen, selectedRow,setSelectedRow, setIsMessagesDetailsOpen  }) {
  
  const { accountData } = useAccountContext(); // Use the accountData from the context

  
  const handleCloseMessageDetails = () => {
    setSelectedRow(null);
    setIsMessagesDetailsOpen(false);
  };
 
  return (
    <TableContainer
sx={{
  maxHeight: 'calc(100vh - 200px)', // Adjust the height as needed
  overflow: 'hidden',
  background:'#f1f8fb',  borderRadius:'12px',         border: '1.25px solid rgba(173, 216, 230, 0.5)',display:'flex',
}}
>
<Paper sx={{ width: '100%', overflowY: 'auto', maxHeight: 'calc(90vh - 125px)' , }}>


  <Table stickyHeader >

  <TableHead>
<TableRow>


      </TableRow>
    </TableHead>
    <TableHead>
      <TableRow>
      <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600,                   fontSize: 13.5,
borderBottom: 'none', textAlign: 'center', borderBottom: 'none', padding: '10px', }}>
Date Sent
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none',  padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
Time Sent
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none',  padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
Sent To
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none', padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
Ticket
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none', padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
Type
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none', padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
  Location
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center', borderBottom: 'none', padding: '10px',letterSpacing:'-2%', lineHeight:'-2%' }}>
Sent By
</TableCell>
<TableCell align="center" sx={{ backgroundColor: '#FFFFFF', color: '#48768C ', fontWeight: 600, fontSize: 13.5, borderBottom: 'none', textAlign: 'center',padding: '10px',letterSpacing:'-2%', lineHeight:'-2%'  }}>
Status
</TableCell>
</TableRow>
</TableHead>

<TableBody>
{loading ? (
  // Loading state
  <TableRow>
    <TableCell colSpan={10} sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>
      <Typography variant="body1" align="center" sx={{ width: '100%', padding: '20px', fontSize: '.9rem' }}>
        Loading your messages...
      </Typography>
      <LinearProgress color="secondary" sx={{ height: 2, '& .MuiLinearProgress-barColorSecondary': { backgroundColor: '#2BBB9E' } }} />
    </TableCell>
  </TableRow>
) : messagesFeedData && messagesFeedData.length === 0 ? (
  // Empty state
  <TableRow>
    <TableCell sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',									
}} colSpan={10} align="center">
      <Typography variant="body1" color="#48768C">
        Message History is Empty.
      </Typography>
    </TableCell>
  </TableRow>
) : (
  // Data rows
  messagesFeedData && messagesFeedData
  .filter((customer) => selectedStatus.length === 0 || selectedStatus.includes(customer.status))
  .filter((message) => {
    const formattedPhoneNumber = formatPhoneNumber(message.phone_number);
    // Check if formattedPhoneNumber is not null before calling includes method
    return formattedPhoneNumber && formattedPhoneNumber.includes(searchPhoneNumber);
  })
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  .map((customer, index) => (
  <TableRow
    key={index}
    onClick={() => handleOpenMessageDetails(customer)} // Open message details on row click
    sx={{
      '&:hover': {
        backgroundColor: 'rgba(173, 216, 230, 0.2)', // Background color on hover
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)', // Box shadow on hover
        cursor: 'pointer', // Change cursor to pointer on hover
      },
    }}
    onMouseEnter={() => setHoveredRowIndex(index)}
    onMouseLeave={() => setHoveredRowIndex(null)}
  >

    <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',color: '#48768C', fontSize: 14, padding: '10px', textAlign: 'center' }}>
      {formatDate(customer.created_at)}
    </TableCell>
    <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',  color: '#48768C', fontSize: 14, padding: '10px' }}>
      {formatTime(customer.created_at)}
    </TableCell>
   <TableCell
  align="center"
  sx={{
    backgroundColor: 'rgba(173, 216, 230, 0.2)',
    width: '14%',
    color: '#48768C',
    fontSize: 14,
    padding: '10px',
    textAlign: 'center'
  }}
>
  {customer.customer_name ? (
    // If customer name is available, display customer name
    customer.customer_name
  ) : (
    // If customer name is not available, format and display phone number
    formatPhoneNumber(customer.phone_number)
  )}
</TableCell>



      <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)', color: '#48768C', fontSize: 14, padding: '10px', textAlign: 'center' }}>
        {customer.ticket_number}
      </TableCell>
      <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',color: '#48768C', fontSize: 14,  textAlign: 'center',padding: '10px',  }}>
        <StatusTags status={customer.status} />
      </TableCell>
      {!accountData.single_location && (
      <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',  color: '#48768C', fontSize: 14, textAlign: 'center',padding: '10px', }}>
        {customer.location}
      </TableCell>
      )}
      {!accountData.single_user && (
      <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)',  color: '#48768C', fontSize: 14, textAlign: 'center',padding: '10px', }}>
        {customer.username}
      </TableCell>
      )}
      <TableCell align="center" sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)', color: '#48768C', fontSize:14, padding: '10px', textAlign: 'center' }}>
        <StatusIcon status={customer.message_status} />
      </TableCell>

    </TableRow>
  ))
)}
</TableBody>

</Table>
<TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={messagesFeedData ? messagesFeedData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows per page"
    
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          sx={{color:'#48768C',backgroundColor: 'rgba(173, 216, 230, 0.4)', }}
        />
        
    <MessageDetails 
    open={isMessageDetailsOpen} 
    selectedRow={selectedRow} onClose={handleCloseMessageDetails}
    handleOpenModal={handleOpenModal}
    setSelectedPhoneNumber={setSelectedPhoneNumber} />
    </Paper>

</TableContainer>
    );
}

export { MessagesFeedTable }; // Named export
