//Multi User Single Location Scheme

import React, { useState, useEffect } from 'react';
import { Modal, Typography, TextField, Button,Box,  Avatar,} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import addUser from 'src/public/assets/addUser.png';
import { useAccountContext } from 'src/context/AccountContext';
import NewUserNotification from '../../../../src/hooks/NewUserNotification'



// Modal for creating an initial user from new Auth0 user signup
const NewSingleUserFromAuth0 = ({ isOpen, onClose, onSave, auth0User, locationData }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [pinError, setPinError] = useState('');


  const { user, getAccessTokenSilently } = useAuth0();
  const { resetContext } = useAccountContext();


  


  useEffect(() => {
    // Check if all fields are filled and valid
    setIsFormValid(
      newFirstName.trim() !== '' &&
        newLastName.trim() !== '' &&
        newUsername.trim() !== '' &&
        newPin.trim() !== '' &&
        confirmNewPin.trim() !== '' &&
        newPin === confirmNewPin &&
        /^[a-zA-Z]+$/.test(newFirstName) &&
        /^[a-zA-Z]+$/.test(newLastName) &&
        /^\d{4,6}$/.test(newPin)
    );

    // Check if PINs match
    setPinError(confirmNewPin !== '' && newPin !== confirmNewPin ? 'Pins must match' : '');
  }, [newFirstName, newLastName, newUsername, newPin, confirmNewPin]);

  

  const theme = createTheme({
    palette: {
      primary: {
        main: '#48768C', // Set the main color to white
      },
    },
  });


  const handleNewSingleUserFromAuth0 = async () => {
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        // Create a new user with the provided username and PIN
        const response = await fetch(`${apiUrl}/api/users/single-location`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
          },
          body: JSON.stringify({
            firstName: newFirstName,
            lastName: newLastName,
            userName: newUsername,
            pin: newPin,
            auth0UserId: user.sub,
            is_admin: true, // Include the is_admin property set to true
            completed_setup: true, // Include the completed_setup property set to true  
            single_user: false,
            single_location: true,
            title: 'Account Administrator',
          }),
        });
  
        const responseData = await response.json();
  
        if (response.ok) {
          onSave(); // Trigger the onSave function passed as a prop
          onClose(true); // Close the modal with a success flag
          // You might want to refresh the user data or perform other actions on success
          await NewUserNotification(user.sub, getAccessTokenSilently); // Pass the user.sub as userId and use await
  
        } else {
          console.error('Failed to create a new user:', responseData.error);
        }
      } else {
        console.error('Entered PINs do not match');
      }
    } catch (error) {
      console.error('Error creating a new user:', error);
    }
  };
  

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}
    slotProps={{
      backdrop: {
        onClick: null, // Disable closing the modal by clicking on the backdrop
      },
    }}
    disableEscapeKeyDown // Disable closing the modal by pressing the escape key
  >
<Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'28%',
padding:3,
          
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '60px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >

<Typography
          sx={{
            mt:1,
            fontWeight: 600,
            fontSize: 21,
            textAlign: 'center',
            mb: 1,
          }}
        >
          Create Your Account Administrator
        </Typography>
   

        <img src={addUser} alt="Welcome" style={{ display: 'block', margin: 'auto', marginBottom: '20px', maxWidth: '55%', maxHeight:'55%' }} />


        <Typography
          sx={{
            fontSize: 14,
            textAlign: 'center',
            mb: 4,
            fontWeight: 600,
          }}
        >
          An account administrator can make important changes to your account, such as adding or removing users, and managing locations
        </Typography>
       
       
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', mt:1 }}>


        <ThemeProvider theme={theme}>

        <TextField
          label="First Name*"
          variant="outlined"
          size="small"

          fullWidth
          value={newFirstName}
          onChange={(e) => setNewFirstName(e.target.value.replace(/[^a-zA-Z]/g, ''))}
          InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, mr:1.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />
                </ThemeProvider>
                <ThemeProvider theme={theme}>


        <TextField
          label="Last Name*"
          variant="outlined"
          size="small"

          fullWidth
          value={newLastName}
          onChange={(e) => setNewLastName(e.target.value.replace(/[^A-Za-z]/ig, ''))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />      
        </ThemeProvider>
        </Box>               

   
                <ThemeProvider theme={theme}>

        <TextField
          label="Username*"
          variant="outlined"
          size="small"

          fullWidth
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          
        />                        </ThemeProvider>

<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', }}>


                <ThemeProvider theme={theme}>

        <TextField
          label="New PIN"
          size="small"

          variant="outlined"
          type="password"
          fullWidth
          value={newPin}
          onChange={(e) => setNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, mr: 2.5, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
        />                     </ThemeProvider>

                <ThemeProvider theme={theme}>

         <TextField
          label="Confirm New PIN"
          size="small"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmNewPin}
          onChange={(e) => setConfirmNewPin(e.target.value.replace(/\D/g, '').slice(0, 6))}
          InputProps={{ style: { color: '#48768C',borderRadius:'8px' } }}
          InputLabelProps={{ style: { color: '#48768C' } }}
          sx={{ mb: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' }, color: '#48768C' }}
          required
          error={!!pinError}
          helperText={pinError}
        
        />                   </ThemeProvider>
        </Box>
        <Box sx={{ marginTop: 1.5, display: 'flex',  width:'100%',justifyContent: 'center',mb:2}}>
          <Button
                      disabled={!isFormValid}

          sx={{
            backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
            borderRadius: 1.25,
            width: '90%',
            fontSize: '18.5px',
            fontWeight: 600,
    
            '&:hover': {
              backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
            },
            '&:active': {
              backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
            },
          }} onClick={handleNewSingleUserFromAuth0} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewSingleUserFromAuth0;
