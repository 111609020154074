import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../../context/AppContext';
import CloseIcon from '@mui/icons-material/Close';
import addLocationIcon from "../../public/assets/icons/icons/newLocation.png";
import { useAuth } from '../../context/AuthContext';
import { useAccountContext } from '../../context/AccountContext';
import { useUserSelectedNotifications } from '../../../src/context/UserNotificationsContext';



const ChangeLocationModal = ({
  isOpen,
  onClose,
  onSave,
  editData,
  setEditData,
  subUserData,
  initialLocation,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updateActiveLocation } = useAppContext();
  const [selectedLocation, setSelectedLocation] = useState('');
  const {isAuthenticated} = useAuth0();
  const {accountData} = useAccountContext();
  const {handleReloadComponents} = useAppContext();
  const {username} = useAuth();



  const fetchLocations = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      const locationsResponse = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Authorization': `Bearer ${accessToken}`
        },
      });
  
      if (!locationsResponse.ok) {
        console.error('Failed to fetch locations');
        return;
      }
  
      const data = await locationsResponse.json();
      setLocations(data.rows);
  
      setSelectedLocation(initialLocation || data.rows[0]?.location_id || '');

    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);

    }
  };
  

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchLocations();
    }
  }, [isOpen, subUserData]);

  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    setSelectedLocation(selectedLocationId);
    setEditData({ ...editData, assigned_location: selectedLocationId });
    updateActiveLocation(selectedLocationId);
  };



  return (
    <Dialog open={isOpen} onClose={onClose}

    fullWidth={true} // Make the dialog take up the full width
    maxWidth="md" // Set the maximum width to medium
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: '22px', // Add your custom styles here
        width: '100%', // Adjust the width as needed
        maxWidth: '475px', // Set a maximum width if needed
      },
    }}
  >

<IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.75,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          px:'30px',
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          color: "#374247",
        }}
      >
        <img
          src={addLocationIcon}
          alt="addLocationIcon"
          style={{
            marginTop:'3px',
            border: '1px solid rgba(173, 216, 230, 0.5)',
            marginLeft:'7px',
            width: "auto",
            height: "33px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />

      </DialogTitle>


      <DialogContent
              sx={{backgroundColor: '#FFFFFF',width:'100%'}}>
                      <Typography variant="h5"  sx={{color: "#48768C" , fontWeight:600, width:'100%', mt:'5px',marginLeft:'9px',pl:'5px', lineSpacing:'-2%', letterSpacing:'-2%'
}}>
          Change Your Active Location{" "}
        </Typography>
                
        <Box sx={{ backgroundColor: '#FFFFFF', marginTop: 3, width: '100%', justifyContent: 'left' }}>
  <Select
    size="small"
    sx={{
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: '17px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      color: '#48768C',
      fontWeight: 500,
      width: '95%',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgba(173, 216, 230, 0.4)',
      borderRadius: '11px',
      mt: 3,
      mb: 3,
      mx: 'auto',
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.02)",
      '&:hover': {
        border: '1px solid rgba(, 216, 230, 0.5)',
      },
    }}
    value={selectedLocation}
    onChange={handleLocationChange}
  >
       
       {locations.map((location) => (
      <MenuItem sx={{ color: '#48768C' }} key={location.location_id} value={location.location_id}>
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} sx={{ marginLeft: 1, mr:2 }} />

            <Typography variant="body1" sx={{ color: '#48768C' }}>Loading Locations</Typography>
          </Box>
        ) : (
          location.name
        )}
      </MenuItem>
    ))}
  </Select>
</Box>

        <DialogActions
      
      sx={{backgroundColor:'#FFFFFF',width:'99%',mr:2, my:'auto'}}>
      
              <Button 
              sx={{borderRadius: '10px',fontSize: '16px',mb:1, color:'#48768C', fontWeight:600,height:'51px',width:'25%', backgroundColor:'#F2F9FF',    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',letterSpacing: '0.2px',ml:'3px',mr:1,
              '&:hover': {
                backgroundColor: '#A5CADB', // Adjust the background color for contrast
                color: '#F0F0F0', // Keep text color consistent
              },
            }}
              onClick={onClose}>Cancel</Button>
<Button
  sx={{
    borderRadius: '10px',
    letterSpacing: '0.1px',
    width: '90%',
    height: '51px',
    fontSize: '18px',
    mb:1,
    fontWeight: 500,
    backgroundColor: '#48768C',

    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Add shadow

    color: 'rgba(233, 242, 255, 0.99)',
    '&:hover': {
      backgroundColor: '#3B6B7D', // Adjust the background color for contrast
      color: 'rgba(233, 242, 255, 0.95)', // Keep text color consistent
    },
  }}
  onClick={onSave}
  variant="contained"
>
  Switch Location
</Button>
      </DialogActions>
        </DialogContent>
    </Dialog>
  );
};

export default ChangeLocationModal;
