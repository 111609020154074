import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Modal, Box, Avatar, Dialog, DialogTitle,DialogContent,DialogActions } from '@mui/material';
import { SharedStyledModal } from './SharedStyledModal';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import editLocationIcon from "../../public/assets/icons/icons/editLocation.png";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';


const scaleFactor = 0.75;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42 * scaleFactor,
  height: 27.5 * scaleFactor,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${16 * scaleFactor}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: `${6 * scaleFactor}px solid #fff`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22 * scaleFactor,
    height: 22 * scaleFactor,
  },
  "& .MuiSwitch-track": {
    borderRadius: (26 * scaleFactor) / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));








const EditLocationModal = ({ isOpen, onClose, onSave, editLocationData, setEditLocationData }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasChanges, setHasChanges] = useState(false); // Track whether changes have been made

  

  useEffect(() => {
    const validateForm = () => {
      const isValidName = editLocationData.name.trim() !== '';
      const isValidEmail = validateEmail(editLocationData.email);
      const isValidPhone = validatePhoneNumber(editLocationData.phone);
      setIsFormValid(isValidName && isValidEmail && isValidPhone);
    };

    validateForm();
}, [editLocationData, hasChanges]);


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    return isValid;
  };

const validatePhoneNumber = (phone) => {
  // Regular expression for formatted phone number
  const formattedPhoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  // Regular expression for unformatted phone number
  const unformattedPhoneRegex = /^\d{10}$/;

  // Check if the phone number matches either the formatted or unformatted regex
  return formattedPhoneRegex.test(phone) || unformattedPhoneRegex.test(phone);
};


  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '(';
      if (match[1]) {
        formattedNumber += match[1];
      }
      if (match[2]) {
        formattedNumber += ') ' + match[2];
      }
      if (match[3]) {
        formattedNumber += '-' + match[3];
      }
      return formattedNumber;
    }
    return value;
  };

  const handleNameChange = (e) => {
    setEditLocationData({ ...editLocationData, name: e.target.value });
    setHasChanges(true); // Set hasChanges to true when a change is made
  };

  const handleEmailChange = (e) => {
    setEditLocationData({ ...editLocationData, email: e.target.value });
    setHasChanges(true);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setEditLocationData({ ...editLocationData, phone: formatPhoneNumber(value) });
    setHasChanges(true);
  };


 
  return (
    <Dialog open={isOpen} onClose={onClose}
    PaperProps={{ sx: { borderRadius: "20px", } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>
      <DialogTitle
        sx={{
          padding: 3,

          width:'550px',
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={editLocationIcon}
          alt="editLocationIcon"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />

      </DialogTitle>
	
      <DialogContent
        sx={{
          padding: 2,
          width: '100%',
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography  sx={{ color: "#48768C", mb: 2.5, fontWeight: 600, lineHeight:'-2%', letterSpacing:'-2%', pl:.75,fontSize:19 }}>
 Edit Location Information{" "}
        </Typography>

        
<Box
sx={{borderRadius: '10px', width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left', mb: 2, flexDirection: 'column', }}
>


<TextField
  label="Location Name"
  variant="outlined"
  size="small"

  fullWidth
  value={editLocationData.name}
  onChange={handleNameChange}
  sx={{ color: 'textSecondary', pb: 2, '& input': { width: '100%', textAlign: 'left', color: '#48768C', fontWeight: 500 } }}
/>
<TextField
  label="Location Email"
  variant="outlined"
  size="small"

  fullWidth
  value={editLocationData.email}
  onChange={handleEmailChange}
  sx={{ color: 'textSecondary', pb: 2, '& input': { width: '100%', textAlign: 'left', color: '#48768C', fontWeight: 500 } }}
/>
<TextField
  label="Location Phone"
  variant="outlined"
  size="small"

  fullWidth
  value={formatPhoneNumber(editLocationData.phone)}
  onChange={handlePhoneChange}
  sx={{ color: 'textSecondary', '& input': { width: '100%', textAlign: 'left', color: '#48768C', fontWeight: 500 } }}
/>
        </Box>


        <DialogActions
  sx={{
    pt: 2,
    backgroundColor: "#FFFFFF",
    width: '100%',
    mx: 'auto', // Set left and right margin to auto to center horizontally
    my: 'auto',
    display: 'flex', // Use flexbox
    justifyContent: 'center', // Center content horizontally
  }}
>
  <Button

    sx={{
      borderRadius: '10px',
      fontSize: '16px',
      mb: 1,
      color: '#48768C',
      fontWeight: 500,
      height: '51px',
      width: '25%',
      backgroundColor: '#F2F9FF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      letterSpacing: '0.2px',
      '&:hover': {
        backgroundColor: '#A5CADB',
        color: '#F0F0F0',
      },
    }}
    onClick={onClose}
  >
    Cancel
  </Button>
  <Button
    disabled={!isFormValid}
    sx={{
      borderRadius: '10px',
      letterSpacing: '0.1px',
      width: '80%', // Adjust width as needed
      height: '51px',
      fontSize: '18px',
      mb: 1,
      fontWeight: 500,
      backgroundColor: '#48768C',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      color: 'rgba(233, 242, 255, 0.99)',
      '&:hover': {
        backgroundColor: '#3B6B7D',
        color: 'rgba(233, 242, 255, 0.95)',
      },
    }}
    onClick={onSave}
    variant="contained"
  >
    Submit
  </Button>
</DialogActions>
</DialogContent>
    </Dialog>
  );
};

export default EditLocationModal;
