import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  Typography,
  Divider,
  IconButton,
  Box,
  Modal,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import lockIcon from "../../../public/assets/icons/icons/lock.png";
import { useAuth } from "../../../context/AuthContext";
import { is } from "date-fns/locale";
import ChangePinModal from "./ChangePinModal";
import { useAccountContext } from "src/context/AccountContext";






function AccountSecurity({ closeModal }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { username, subUserData, setSubUserData } = useAuth();
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);  
  const user_id = subUserData.user_id;  



  const handleCloseChangePinModal = () => { setIsChangePinModalOpen(false); };

  const checkEmailVerification = () => {
    setEmailVerified(user.email_verified);
  };

  useEffect(() => {
    checkEmailVerification();
  }, []);

  const handleVerifyEmail = async () => {
    if (!emailVerified) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${apiUrl}/auth/send-verification-email`,
          {
            userId: user.sub,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        // Optionally, show a success message or update UI to reflect email verification sent
      } catch (error) {
        console.error("Error sending verification email:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);

      const response = await axios.post(`${apiUrl}auth/reset-password`, {
        email: user.email,
      }); // Send user's email to backend
    } catch (error) {
      console.error("Error resetting password:", error);
    } finally {
      setLoading(false);
    }
  };

  // Mock-up state for verification. Replace with your actual logic.
  const [accountVerified, setAccountVerified] = useState(true);



  return (
    <Dialog open={true} onClose={closeModal}
    PaperProps={{ sx: { borderRadius: "20px",width:'500px' } }}
    >

<IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon
        sx={{fontSize: "28px",}}
        
        />
      </IconButton>




      <DialogTitle
        sx={{
          pl:'1.75 rem',
          alignItems: "center",
          height: '55px',
          pt: 2,
          pb: .45,
          color: "#374247",
        }}
      >
              <Box
      sx={{display:'flex', flexDisplay:'column',ml:.5,}}
      >
        <img
          src={lockIcon}
          alt="lock"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
            width: "auto",
            height: "30px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />


        </Box>
       </DialogTitle>   
       
<DialogContent
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          zIndex: 0,
        }}
      
      >

<Typography  sx={{ display:'flex',color: "#48768C" , fontSize:20, mb:2, fontWeight:600, alignContent:'left', ml:.5}}>
          Account Security {" "}
        </Typography>
<Box
sx={{    backgroundColor: 'rgba(191, 218, 231, 0.1)',
border: '1px solid rgba(173, 216, 230, 0.7)', borderRadius: "10px", padding: "1rem", }}
>  
{subUserData.is_admin && (
  <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
            
          }}
        >
          <Typography sx={{color:'#48768C', fontSize:'16px', fontWeight:600}}>Verify Email</Typography>
          <Button
            sx={{
              backgroundColor: "#2BBB9E",
              color: "#F0F0F0",
              padding: "12px 24px",
              "&:hover": { backgroundColor: "#249e86" },
              "&:disabled": {color: "#DDEBFD", backgroundColor: "#249e86"},
            }}
            onClick={handleVerifyEmail}
            disabled={!isAuthenticated || emailVerified || loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : emailVerified ? (
              <>
                <VerifiedUserIcon
                  style={{ marginRight: "5px", color: "#CCDFF8" }}
                />
                Verified
              </>
            ) : (
              "Verify Email"
            )}
          </Button>
        </Box>

  

        <Divider sx={{color:'1px solid rgba(173, 216, 230, 0.3)'}} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            marginBottom: !accountData.single_user ? "1rem" : ".25rem",
          }}
        >

            <Typography sx={{color:'#48768C', fontSize:'16px', fontWeight:600}}>Reset Account Password</Typography>
          <Button
            sx={{
              backgroundColor: "#647D9E",
              color: "#F0F0F0",
              padding: "12px 24px",
              "&:hover": { backgroundColor: "#566f8f" },
            }}
            onClick={handleResetPassword}
            disabled={!isAuthenticated || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Reset Password"}
          </Button>
        </Box>
        </>

        )}
{!accountData.single_user && (
        <>
        <Divider sx={{color:'1px solid rgba(173, 216, 230, 0.3)'}} />


        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: .5,
          }}
        >
            <Typography sx={{color:'#48768C', fontSize:'16px', fontWeight:600}}>Change Your Personal Pin  </Typography>
          <Button
            sx={{
              backgroundColor: "#8C4350",
              color: "#F0F0F0",
              padding: "12px 24px",
              "&:hover": { backgroundColor: "#783944" },
            }}
            disabled={!isAuthenticated || loading}
            onClick = {() => setIsChangePinModalOpen(true)} 
          >
            {loading ? <CircularProgress size={24} /> : "Change Pin"}
          </Button>

          <ChangePinModal
    isOpen={isChangePinModalOpen}
    onClose={handleCloseChangePinModal}
    username={user_id}
    />
        </Box>
        </>
      )}
        
        </Box>
        
        </DialogContent>
    </Dialog>


  );
}

export default AccountSecurity;
