import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
Box,
DialogTitle,
Dialog,
DialogContent,
Typography,
IconButton,
Paper,
} from '@mui/material';
import { useAccountContext } from '../../context/AccountContext'; // Import AppProvider from AccountContext
import DetailsIcon from '../../public/assets/icons/icons/details.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import FailIcon from '../../public/assets/icons/icons/failedMessage.png';
import StatusText from '../color-utils/StatusText';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';






const StatusIcon = ({ status }) => {
    let icon;
    let color;
  
    switch (status) {
      case null:
        icon = <HelpOutlineIcon sx={{width:'17px', height:'auto', display:'flex', ml:.5}}/>;
        color = '#7589A2'; // Blue color
        break;
      case 'Sent':
        icon = <CheckCircleIcon  sx={{width:'17px', height:'auto', display:'flex', ml:.5}} />;
        color = '#2BBB9E'; // Light green color
        break;
        case 'queued':
        icon = <CheckCircleIcon />;
        color = '#2BBB9E'; // Light green color
        break;
      case 'Failed':
        icon = <CancelPresentationIcon sx={{width:'17px', height:'auto', display:'flex', ml:.5}}/>;
        color = '#8C4350'; // Red color
        break;
      default:
        icon = null;
    }
  
    return icon ? <span style={{ color }}>{icon}</span> : null;
  };

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };




function MessageDetails({ open, onClose,selectedRow, handleOpenModal, setSelectedPhoneNumber }) {

    

    const { accountData } = useAccountContext(); // Use the accountData from the context

  return (
    <Dialog open={open} onClose={onClose} 
    PaperProps={{
        style: {

          backgroundColor: '#747C86',
          width:'auto',
          border: '1px solid rgba(173, 216, 230, 0.7)',
          minWidth: '400px',
          borderRadius: '18px',
          bottom: '5%', // Adjust this value as needed
          maxwidth:'600px',
          

        },
      }}
      sx={{

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",

        }}
      >
        <CloseIcon sx={{fontSize: "28px"}} />
      </IconButton>
      <DialogTitle
        sx={{

          width:'100%',
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#FFFFFF",
          padding:2,
          flexDirection: "column",

          color: "#374247",

        }}
      >
        <Box >
            <img
          src={DetailsIcon}
          alt="detailsIcon"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
            width: "auto",
            height: "29px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",

          }}
        />
</Box>
<Typography  sx={{ color: "#48768C" ,  mb:.5, fontWeight:600,marginRight:'auto', mt:1, lineHeight:'-2%', letterSpacing:'-2%', fontSize:'20px' }}>
          Notification Details {" "}
        </Typography>
      </DialogTitle>
      
      <DialogContent
        sx={{         
          mt:-1,
          fontWeight: 600,
          letterSpacing: '0.02em',
          backgroundColor: "#FFFFFF",
          color: '#48768C',
          padding:'.75rem'
        }}
      >




        <Box sx={{ display: 'flex', flexDirection: 'row',alignItems:'left', backgroundColor: 'rgba(191, 218, 231, 0.1)',
border: '1px solid rgba(173, 216, 230, 0.7)', borderRadius:'10px',  fontSize:15, justifyContent:'space-between',padding:'.25rem', pt:1  }}>
  

    {selectedRow && (

            <>
            
        <Box sx={{ borderRadius: '10px',  borderRadius: '6px',width:'auto', pl:.25,  }}>
          
        <Box>
            {!['APPROVED', 'DECLINED'].includes(selectedRow.status) && (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',  }}>
    <Typography>
      <strong>Notification: </strong>
    </Typography>
    <StatusText status={selectedRow.status} size="small" />
  </Box>
)}


          </Box>
          {['APPROVED', 'DECLINED'].includes(selectedRow.status) && (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', mt:.5,  }}>
    <Typography>
      <strong>Approval Status: </strong>
    </Typography>
    <StatusText status={selectedRow.status} size="small" />
  </Box>
)}

                    <Box sx={{ display: 'flex', flexDirection: 'row',alignItems:'center',width:325 }}>

                    <strong>Sent On: </strong> 
     
                    {selectedRow.created_at && ( 
          <Typography sx={{mr:.5,ml:.5,}}> 
          {formatDate(selectedRow.created_at)}
          </Typography>
        )}
          <Typography sx={{ }} >
            ({formatTime(selectedRow.created_at)}) 
          </Typography>
          <StatusIcon 
          sx={{justifyContent:'center'}}
          status={selectedRow.message_status} />


        </Box>
        
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',alignItems:'center',}}>
    
<strong>Sent To:</strong>
{selectedRow.customer_name ? (
    <Typography sx={{ ml: 0.5 }}>
        {selectedRow.customer_name}
    </Typography>
) : (
  <>
            <Box  sx={{   display: 'flex', my:-3  }}>

  <Typography sx={{ ml: 0.5, mt:0, alignContent:'center', mr:0 }}>
      {formatPhoneNumber(selectedRow.phone_number)}
  </Typography>
  <IconButton
          variant="contained"
          onClick={(event) => {
              event.stopPropagation();
              handleOpenModal();
              setSelectedPhoneNumber(formatPhoneNumber(selectedRow.phone_number));
          }}
      >
              <PersonAddAltTwoToneIcon
                  sx={{
                      color: '#86A4B2',
                      fontSize: '23px',
                      border: '1.5px solid rgba(173, 216, 230, 0.9)',
                      borderRadius: '7px',
                      backgroundColor: 'rgba(173, 216, 230, 0.1)',
                      padding: '2px',
                  }}
              />
      </IconButton>
      </Box>

</>
)}



               
</Box>
{!accountData.single_location && selectedRow.location && ( 
    
          <Typography sx={{}} >
        <strong>Sent From:</strong>     {selectedRow.location}
          </Typography>
        )}
        {selectedRow.ticket_number && (
          <Typography sx={{}} >
            <strong>Ticket #:</strong> {selectedRow.ticket_number}
          </Typography>
        )}
      




{selectedRow.required_field && (
          <Typography sx={{}} >
            <strong>{selectedRow.required_field}</strong>: {selectedRow.required_field_value}
          </Typography>
        )}
 {selectedRow.optional_field_value && (
          <Typography sx={{}} >
            <strong>{selectedRow.optional_field}</strong>: {selectedRow.optional_field_value}
          </Typography>
        )}
                    
                  
             

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px',alignItems:'center'}}>




               
                        
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px',alignItems:'center', width:'100%'}}>
        {selectedRow.message && (
          <Typography>
            <strong>Additional Message:</strong> "{selectedRow.message}"
          </Typography>
        )}
             
                </Box>
                <Box sx={{ mt:1, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', ml:-1 }}>
  {selectedRow.error_message && (
    <>
      <img
        src={FailIcon}
        alt="WelcomeIcon"
        style={{
          alignContent : 'center',
          alignItems: "center",
          display: "flex",
          width: "auto",
          height: "26px",
        }}
      />
          <Typography sx={{  color: '#704049', fontSize:'15px', fontWeight:600, alignItems:'center',mb:'-2px' }}>Notification Could Not Be Delivered</Typography>
    </>
  )}
</Box>
<Typography sx={{ color: '#704049', fontSize:'14px',whiteSpace:'preWrap',width:'auto', }}>{selectedRow.error_message}</Typography>



       
        </Box>
      </>

    )}

</Box>





      </DialogContent>
    </Dialog>
  );
}

export default MessageDetails;
