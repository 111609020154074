import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Modal, Select, MenuItem, Box, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import {useAppContext} from '../../context/AppContext';
import { useSingleUser } from '../../context/SingleUserContext';


const EditProfile = ({ isOpen, onClose, onSave,  }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
	const {fetchLatestSingleUserData} = useSingleUser(); // Import the fetchLatestSingleUserData function

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {reloadComponents} = useAppContext(); 
  const { username, subUserData, setSubUserData } = useAuth();
  const [editData, setEditData] = useState({
    first_name: '',
    last_name: '',
    title: '',
  });
  const [title, setTitle] = useState(''); // Initial role
  const userId = user.sub;

  useEffect(() => {
    if (isOpen) {
      fetchUserData(); // Fetch user data when the modal is opened
    }
  }, [isOpen]);

  const handleChangeTitle = (e) => {
    const selectedTitle = e.target.value;
    setTitle(selectedTitle);
    // Update editData state
    setEditData({
      ...editData,
      title: selectedTitle, // Update the title property in editData
    });
  };

  const fetchUserData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      const response = await fetch(`${apiUrl}/api/users/${userId}/${username}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const userData = await response.json();
      console.log('User data:', userData);  
      if (userData.rows && userData.rows.length > 0) {
        // Access the first element of the rows array and set the state
        setEditData({
          first_name: userData.rows[0].first_name || '',
          last_name: userData.rows[0].last_name || '',
          title: userData.rows[0].title || '',
        });
      } else {
        console.error('No user data found.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  
  
  
  
  
  const handleSave = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      // Construct the API endpoint URL for updating user profile
      const updateUserProfileUrl = `${apiUrl}/api/users/profile/${userId}/${subUserData.username}`;
  
      const response = await fetch(
        updateUserProfileUrl,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(editData),
        }
      );
  
      if (response.ok) {
        // After successfully updating the location, fetch the updated user data
        const updatedUserDataResponse = await fetch(
          `${apiUrl}/api/all-users/${user.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const updatedUserData = await updatedUserDataResponse.json();
  
        // Update the user data in the context directly
        setSubUserData(updatedUserData);
        onClose();
        fetchLatestSingleUserData();
      } else {
        console.error('Failed to update location');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  
  

  

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ sx: { borderRadius: "20px" } }}>
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <DialogTitle
          sx={{
            padding: 3,
            width: '550px',
            display: "flex",
            alignItems: "center",
            height: "55px",
            backgroundColor: "#FFFFFF",
            pt: 3,
            pb: 2.45,
            color: "#374247",
          }}
        >
          <img
            src={editUser}
            alt="editUser"
            style={{
              border: '1.25px solid rgba(173, 216, 230, 0.7)',
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </DialogTitle>

        <DialogContent
          sx={{
            padding: 2,
            width: '100%',
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
            zIndex: 0,
          }}
        >
          <Typography  sx={{    
 color: "#48768C" , fontSize:19 , mb:1.5, fontWeight:600, pl:.75}}>
          Edit Your Profile{" "}
        </Typography>
        <Box sx={{ borderRadius:'20px',border: '1px solid rgba(173, 216, 230, 0.7)', py:2, width:'auto', borderRadius:'15px', mb:1.5}}>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'flex-start', width: '100%', ml:1,mb:'20px'}}>
      <img
          src={userDetailsIcon}
          alt="addUserIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft:'1px',
            width: "auto",
            height: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />  <Typography sx={{ ml: .5, alignSelf: 'flex-end', color:'#48768C', fontSize:16, fontWeight:600,  }}>Your Personal Information</Typography>
</Box>
    
<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  width: '100%', pr: 3, ml:1, mt:1 }}>

<TextField
          label="First Name"
          variant="outlined"
          size="small"
          fullWidth
          value={editData.first_name}
          onChange={(e) => setEditData(prevEditData => ({ ...prevEditData, first_name: e.target.value }))}
          sx={{ color: '#000',pb: 2,mr:2, '& input': { width: '100%', textAlign: 'left',color: '#48768C' } }}
          />

        <TextField
          label="Last Name"
          variant="outlined"
          size="small"
          fullWidth
          value={editData.last_name}
          onChange={(e) => setEditData({ ...editData, last_name: e.target.value })}
          sx={{ color: '#48768C', pb: 2, '& input': { width: '100%', textAlign: 'left', color: '#48768C' } }}
          />
                       
                    </Box>
                    <FormControl variant="outlined" sx={{ width:'95.75%', mb:1, ml:'7.5px', backgroundColor: '#F0F0F0' }}>
  <InputLabel htmlFor="outlined-adornment-role">Role</InputLabel>
  <Select
    label="Role"
    size="small"
    value={editData.title}
        onChange={handleChangeTitle}
        sx={{backgroundColor: '#FFFFFF', color: '#48768C'}}
    MenuProps={{
      PaperProps: {
        style: {
          backgroundColor: '#FFFFFF',
        },
      },
    }}
  >
    <MenuItem value="Employee" sx={{backgroundColor: "#FFFFFF",color: '#48768C'}}>Employee</MenuItem>
    <MenuItem value="Manager" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Manager</MenuItem>
    <MenuItem value="Owner" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Owner</MenuItem>
    <MenuItem value="Regional Manager" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Regional Manager</MenuItem>
    <MenuItem value="Account Administrator" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Account Administrator</MenuItem>
    <MenuItem value="Director of Operations" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Director of Operations</MenuItem>
    <MenuItem value="Assistant Manager" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Assistant Manager</MenuItem>
    <MenuItem value="Shift Lead" sx={{backgroundColor: '#FFFFFF',color: '#48768C'}}>Shift Lead</MenuItem>
  </Select>
</FormControl>

          </Box>




        <DialogActions
  sx={{
    pt: 4,
    backgroundColor: "#FFFFFF",
    width: '100%',
    mx: 'auto', // Set left and right margin to auto to center horizontally
    my: 'auto',
    display: 'flex', // Use flexbox
    justifyContent: 'center', // Center content horizontally
  }}
>
  <Button
    sx={{
      borderRadius: '10px',
      fontSize: '16px',
      mb: 1,
      color: '#48768C',
      fontWeight: 500,
      height: '51px',
      width: '25%',
      backgroundColor: '#F2F9FF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      letterSpacing: '0.2px',
      '&:hover': {
        backgroundColor: '#A5CADB',
        color: '#F0F0F0',
      },
    }}
    onClick={onClose}
  >
    Cancel
  </Button>
  <Button
    sx={{
      borderRadius: '10px',
      letterSpacing: '0.1px',
      width: '80%', // Adjust width as needed
      height: '51px',
      fontSize: '18px',
      mb: 1,
      fontWeight: 500,
      backgroundColor: '#48768C',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      color: 'rgba(233, 242, 255, 0.99)',
      '&:hover': {
        backgroundColor: '#3B6B7D',
        color: 'rgba(233, 242, 255, 0.95)',
      },
    }}
    disabled={!editData.first_name || !editData.last_name}

    onClick={handleSave}
    variant="contained"
  >
    Submit
  </Button>
</DialogActions>

</DialogContent>
</>
    </Dialog>
  );
};

export default EditProfile;
