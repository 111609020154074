import React from 'react';
import { Typography, Dialog, Button, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 hook


const MobileWarningPopup = () => {
    const { logout } = useAuth0(); // Destructure the logout function from useAuth0 hook


    const handleLogout = () => {
        // Call the logout function when the button is clicked
        logout({ returnTo: window.location.origin });
      };


  return (
    <Dialog
      open={true} // Make sure the Dialog is set to open
      fullWidth
      PaperProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          width:'95%',
          borderRadius:'20px'
        },
      }}
    >
      <Typography  sx={{ textAlign: 'center', mb: 2, fontSize:13, fontWeight:600,color:'#48768C', mt:1  }}>
        NanoNotify is currently not supported in mobile browsers.
      </Typography>
      <Typography  sx={{ textAlign: 'center',mb: 2, fontSize:12, fontWeight:600,color:'#628197',    }}>
        Please log in to your account using a desktop browser. We hope to support mobile browsers in the future.
      </Typography>
      <Box
  sx={{
    justifyContent: 'center', // Center the button horizontally
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }}
>

        <Button 
        onClick={handleLogout}
        sx={{fontSize:11,display:'flex', border:'1px solid #48768C', borderRadius:'10px', padding:'5px', color:'#48768C', backgroundColor:'#FFFFFF', mb:1,        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#DBEAF0",
        },
        "&:active": {
          backgroundColor: "#DBEAF0", // Adjust active state color if needed
        },
      }}
           >Log Out</Button>
           </Box>
      
    </Dialog>
  );
};

export default MobileWarningPopup;
