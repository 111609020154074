import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Typography, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import ContactImage from 'src/public/assets/ContactImage.png';

const apiUrl = process.env.REACT_APP_API_URL;

const SingleUserSingleLocationModal = ({ isOpen, onClose, onAddLocation }) => {
  const [name, setName] = useState('Default Location');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const { user, getAccessTokenSilently } = useAuth0();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#48768C', // Set the main color to white
      },
    },
  });

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); // Initialize to false
  const [isEmailFieldDirty, setIsEmailFieldDirty] = useState(false);
  const [isPhoneFieldDirty, setIsPhoneFieldDirty] = useState(false);


  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    const isValidEmail = isEmail(inputValue);
    setIsEmailValid(isValidEmail);
    setEmail(inputValue);
    setIsEmailFieldDirty(true);
    setIsFormValid(isValidEmail && isPhoneValid);
  };

  const handlePhoneNumberChange = (e) => {
    let inputValue = e.target.value;
    
    // Remove all non-numeric characters from the input
    inputValue = inputValue.replace(/\D/g, '');
  
    // Limit the input to a maximum of 10 digits
    inputValue = inputValue.slice(0, 10);
  
    // Check if the input value has exactly 10 digits
    const isValidPhone = /^\d{10}$/.test(inputValue);
  
    // Format the numeric value as (XXX) XXX-XXXX
    let formattedValue = '';
    if (inputValue.length > 0) {
      formattedValue = `(${inputValue.slice(0, 3)}`;
    }
    if (inputValue.length > 3) {
      formattedValue += `) ${inputValue.slice(3, 6)}`;
    }
    if (inputValue.length > 6) {
      formattedValue += `-${inputValue.slice(6, 10)}`;
    }
  
    setPhone(formattedValue);
    setIsPhoneFieldDirty(true);
    setIsPhoneValid(isValidPhone);
    setIsFormValid(isEmailValid && isValidPhone);
  };
  

  const handleAddLocationClick = async () => {
    if (!isFormValid) {
      return;
    }
  
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
  
      const locationData = {
        name,
        email,
        phone,
        auth0UserId: user.sub,
        single_location: true,
      };
  
      const response = await fetch(`${apiUrl}/api/locations/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Add authorization header with access token
        },
        body: JSON.stringify(locationData),
      });
  
      if (!response.ok) {
        throw new Error('Error adding location');
      }
  
      const data = await response.json();
      onClose();
      onAddLocation(data);
    } catch (error) {
      console.error('Error adding location:', error);
    }
  };
  

  useEffect(() => {
    setIsFormValid(isEmailValid && isPhoneValid);
  }, [email, phone, isEmailValid, isPhoneValid]);
  

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: {
          onClick: null, // Disable closing the modal by clicking on the backdrop
        },
      }}
      disableEscapeKeyDown // Disable closing the modal by pressing the escape key
    >
      <Box
        sx={{
          outline: 'none', // Remove the default focus outline
alignContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
width:'32%',
         padding:1, 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
          borderRadius: '30px',
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          color: '#48768C',

        }}
      >
        
        <Typography
       sx={{
        mt:4,
        fontSize: 18,
        textAlign: 'center',
        mb: 1,
        fontWeight: 600,
      }}
    >
          Let's get some more information about your location.
        </Typography>
        <Box sx={{width:'100%', display:'flex',flexDirection:'column',textAlign:'center',         alignItems: 'center',
}} >  
        <img src={ContactImage} alt="Welcome"  style={{ display: 'block', margin: 'auto',  maxWidth: '60%', maxHeight:'55%',  }} />
        <Typography
          sx={{
            width: '90%',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            mb: 3,
            fontSize:14,
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          This information is automatically included in your notifications, in case your customers need to reach back out
        </Typography>

        <ThemeProvider theme={theme}>
        <TextField
        size='small'
            label="Location Email"
            variant="outlined"
            InputProps={{ style: { color: '#48768C',              justifyContent: 'center',borderRadius:'8px',
          } }}
            InputLabelProps={{ style: { color: '#48768C',              justifyContent: 'center',
          } }}
            value={email}
            onChange={handleEmailChange}
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              width:'90%',
              mb: 2.5,
              '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' },
              color: '#48768C',
              '&:focus': {
                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' },
              },
            }}
            error={!isEmailValid && isEmailFieldDirty}
            helperText={!isEmailValid && isEmailFieldDirty && 'Valid email is required'}
          />
        </ThemeProvider>

        <ThemeProvider theme={theme}>
        <TextField
                size='small'

            label="Location Phone Number"
            variant="outlined"
            InputProps={{ style: { color: '#48768C', borderRadius:'8px' } }}
            InputLabelProps={{ style: { color: '#48768C' } }}
            value={phone}
            onChange={handlePhoneNumberChange}
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              width:'90%',
              mb: 2,
              '& .MuiOutlinedInput-notchedOutline': { borderColor: '#48768C' },
              color: '#48768C',
            }}
            error={!isPhoneValid && isPhoneFieldDirty}
            helperText={!isPhoneValid && isPhoneFieldDirty && 'Valid phone number is required'}
          />
        </ThemeProvider>
        </Box>
        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%', mt:2 }}>

        <Button
          variant="contained"
          onClick={handleAddLocationClick}
          disabled={!isFormValid}
          sx={{
            justifyContent: 'center',
            backgroundColor: 'rgba(72, 118, 140, 0.8)', // Adjusted color with transparency
            padding: 1,
            borderRadius: '15px',
            mb:5,
            width: '400px',
            fontSize: 17,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'rgba(72, 118, 140, 0.7)', // Adjusted hover color with transparency
            },
            '&:active': {
              backgroundColor: 'rgba(72, 118, 140, 0.5)', // Adjusted active color with transparency
            },
          }}>
          Continue
        </Button>
        </Box>  
      </Box>
    </Modal>
  );
};

export default SingleUserSingleLocationModal;
