import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [accountData, setAccountData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [showNewUserSignUp, setShowNewUserSignUp] = useState(false);

  const [notificationSettings, setNotificationSettings] = useState({
    messageSentAlerts: true,
    inactivityTimeout: true,
    promotionsOrOffers: true,
    dailySummaryReport: true,
    weeklyActivityReport: true,
    newCustomerAdded: true,
    approachingMonthlyLimit: true,
    dailyCostSummary: true,
    weeklyCostSummary: true,
    upcomingPaymentReminders: true,
  });


useEffect(() => {
  const fetchAccountData = async (auth0UserId) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/accounts/${auth0UserId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setAccountData(data.accountData);
        setDataFetched(true);
        localStorage.setItem('accountData', JSON.stringify(data.accountData));

        // Handle empty response
        if (isEmptyObject(data)) {
          setShowNewUserSignUp(true);
        }
      } else {
        console.error('Error fetching account data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  if (!dataFetched && user && user.sub) {
    fetchAccountData(user.sub);
  }
}, [apiUrl, user, dataFetched]);


  useEffect(() => {
    const initialAccountDataString = localStorage.getItem('accountData');
  
    try {
      const initialAccountData = initialAccountDataString ? JSON.parse(initialAccountDataString) : null;
      setAccountData(initialAccountData);
    } catch (error) {
      if (initialAccountDataString !== undefined) { // Check if it's not undefined
        console.error('Error parsing initialAccountData:', error);
      }
    }
  }, []);

  const setAuthData = (newAuth0UserId) => {
    setAccountData(null); // Reset account data
    setDataFetched(false); // Reset data fetched flag
  };

  const resetContext = () => {
    setAccountData(null);
    setDataFetched(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      if (user && user.sub) {  // Ensure user and user.sub are defined
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(`${apiUrl}/api/in-app-notifications/get_notifications/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch notifications data');
          }
  
          const data = await response.json();
          setNotificationSettings(data);
        } catch (error) {
          console.error('Error fetching notification settings:', error);
        }
      }
    };
  
    fetchData();
  }, [apiUrl, user, getAccessTokenSilently]);  // Add user and getAccessTokenSilently as dependencies


  const saveSettings = async (key, value) => {
    
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${apiUrl}/api/in-app-notifications/save_notifications/${user.sub}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ [key]: value }),
    });

    if (!response.ok) {

      throw new Error('Failed to save notification settings');
    }
  };


  const contextValue = {
    accountData,
    setAuthData,
    resetContext,
    showNewUserSignUp,
    setShowNewUserSignUp,
    setNotificationSettings,
    saveSettings,
    notificationSettings,
  };

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};